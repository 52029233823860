import React, { useEffect, useCallback, useState } from 'react';
import { Grid, withStyles, Table, TableHead, TableCell, TableBody, TableRow, Typography, TableSortLabel, IconButton, Switch } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { ValidatorForm } from 'react-material-ui-form-validator';

import DownloadCSV from '../../components/DownloadCSV/DownloadCSV.jsx';
import GenerateLicense from '../../components/GenerateLicense/GenerateLicense.jsx';
import CheckboxComponent from '../../components/ChecboxComponent/CheckboxComponent.jsx';
import { appConstants } from '../../constants/appConstants.jsx';
import { sortTable } from '../../helpers/appHelpers';
import Loader from '../../components/Loaders/Loader.jsx';
import NoResultFound from '../../components/NoResultFound/NoResultFound.jsx';
import Filter from '../../components/Filter/Filter.jsx';
import Styles from '../../layouts/Styles.jsx';
import ToolTipComponent from '../../components/Tooltip/Tooltip.jsx';
import AlertDialog from '../../components/AlertDialog/AlertDialog.jsx';

import { getAllPackage, deletePackage, updatePackage, updatePackageList } from '../../actions/packageActions.js';

const Package = (props) => {
    const { history, classes, theme } = props;
    const dispatch = useDispatch();
    const [orderBy, setOrderBy] = useState('name');
    const [order, setOrder] = useState('asc');
    const [filters, setFilters] = useState({ search: "", filter: "" });
    const [filterPackages, setFilterPackages] = useState([]);
    const [packages, setPackages] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [isShowDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedLicensePackage, setSelectedLicensePackage] = useState(null);

    const getPackages = useCallback(() => {
        dispatch(getAllPackage()).then((response) => {
            setLoading(false);
            if (response) {
                setPackages([...response]);
                setFilterPackages([...response]);
            }
        })

    }, [dispatch]);

    useEffect(() => {
        getPackages();
    }, [getPackages]);

    const onChangeOrder = useCallback((property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [order, orderBy]);

    const onChangeFilter = useCallback((name, value) => {
        filters[name] = value;
        setFilters({ ...filters });
        const search = name === "search" ? value : filters.search;
        const filter = name === "filter" ? value : filters.filter;
        const packageList = packages.filter((packageInfo) => {
            let searchFilter = true;
            let filterLicense = true;
            if (filters.search.length) {
                searchFilter = (packageInfo.name && packageInfo.name.toLowerCase().includes(search.toLowerCase())) || (packageInfo.license_type && packageInfo.license_type.toLowerCase().includes(search.toLowerCase())) || (packageInfo.license_mode && packageInfo.license_mode.toLowerCase().includes(search.toLowerCase()));
            }
            if (filters.filter.length) {
                filterLicense = packageInfo.is_active === (filter === "active");
            }
            return searchFilter && filterLicense;
        });
        setFilterPackages([...packageList]);
    }, [filters, packages]);

    const resetFilter = () => {
        setFilters({ search: "", filter: "" });
        setFilterPackages([...packages]);
    };

    const onSelectPackage = (packageId, value) => {
        const index = packages.findIndex((packageInfo) => packageInfo.id === packageId);
        if (index !== -1) {
            packages[index].isChecked = value;
            const enableSelectAll = packages.filter((packageInfo) => packageInfo.isChecked);
            setSelectAll(enableSelectAll.length === packages.length);
            setPackages([...packages]);
        }

    };

    const onSelectAll = (value) => {
        setSelectAll(value);
        for (const packageInfo of filterPackages) {
            const index = packages.findIndex((data) => data.id === packageInfo.id);
            if (index !== -1) {
                packages[index].isChecked = value;
            }
        }
        setPackages([...packages]);
    };


    const openGenerateLicenseModel = (event, packageId) => {
        setAnchorEl(event.target);
        setSelectedPackage(packageId);
    };

    const filterPackageSelection = () => {
        return filterPackages.filter((packageInfo) => packageInfo.isChecked).length !== 0;
    };

    const onDelete = (packageInfoId) => {
        const index = packages.findIndex((packageInfo) => packageInfo.id === packageInfoId);
        packages.splice(index, 1);
        setPackages([...packages]);
        dispatch(updatePackageList([...packages]));
        const filterIndex = filterPackages.findIndex((packageInfo) => packageInfo.id === packageInfoId);
        filterPackages.splice(filterIndex, 1);
        setFilterPackages([...filterPackages]);
        deletePackages([packageInfoId]);
        setSelectedLicensePackage(null)
        setShowDeleteDialog(false);
    };

    const deletePackages = (packageList) => {
        const requestParams = { 'packages': packageList };
        dispatch(deletePackage(requestParams));
    };

    const onDeleteAll = () => {
        const packageList = packages.filter((data) => data.isChecked).map((data) => data.id);
        for (const id of packageList) {
            const index = packages.findIndex((packageInfo) => packageInfo.id === id);
            packages.splice(index, 1);
            const filterIndex = filterPackages.findIndex((packageInfo) => packageInfo.id === id);
            filterPackages.splice(filterIndex, 1);
        }
        setSelectAll(false);
        setPackages([...packages]);
        setFilterPackages([...filterPackages]);
        deletePackages(packageList);
        setShowDeleteDialog(false);
    };

    const onChangestatus = (packageId, status) => {
        const index = packages.findIndex((packageInfo) => packageInfo.id === packageId);
        if (index !== -1) {
            packages[index].is_active = status;
            setPackages([...packages]);
            const requestParams = {
                'is_active': status
            };
            dispatch(updatePackage(packageId, requestParams));
        }
    }


    return (
        <Grid className={classes.container}>
            <ValidatorForm className={classes.container}>
                <Filter
                    history={history}
                    title="Add License Package"
                    name="License Package"
                    showAdd
                    url="createpackage"
                    showFilter
                    filterOptions={appConstants.packageFilters}
                    showSearch
                    filters={filters}
                    onChangeFilter={(name, value) => onChangeFilter(name, value)}
                    resetFilter={() => resetFilter()} />
                <Grid className={classNames(classes.marginTop20, classes.listContainer, classes.mainContainer)}>
                    {
                        filterPackageSelection() &&
                        <Grid container justifyContent="space-between">
                            <Grid item />
                            <Grid item>
                                <ToolTipComponent title="Delete All" placement="bottom" arrow>
                                    <IconButton className={classNames(classes.nopadding, classes.marginRight5)} onClick={() => setShowDeleteDialog(true)}>
                                        <svg version="1.1"
                                            id="Layer_1"
                                            width="18"
                                            height="18"
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 32 32">
                                            <g id="Delete">
                                                <path fill={theme.palette.primary.main}
                                                    stroke={theme.palette.primary.main}
                                                    strokeWidth="0.92"
                                                    strokeMiterlimit="10"
                                                    d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
		L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
		L22.3,25.8z" />
                                                <path fill={theme.palette.primary.main}
                                                    stroke={theme.palette.primary.main}
                                                    strokeWidth="0.92"
                                                    strokeMiterlimit="10"
                                                    d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
		c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
		c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                            </g>
                                        </svg>
                                    </IconButton>
                                </ToolTipComponent>
                            </Grid>
                        </Grid>
                    }
                    {filterPackages.length > 0 &&
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {
                                        appConstants.packageHeaders.map((header, index) =>
                                            <TableCell key={index}>
                                                {
                                                    header.name !== 'Actions' ?
                                                        <TableSortLabel
                                                            active={orderBy === header.value}
                                                            direction={orderBy === header.value ? order : 'asc'}
                                                            onClick={() => onChangeOrder(header.value)}
                                                        >
                                                            {
                                                                header.value === "name" ? <Grid className={classes.flex}>
                                                                    <CheckboxComponent
                                                                        viewBox="0 0 14 21"
                                                                        checked={Boolean(selectAll)}
                                                                        onClick={(event) => event.stopPropagation()}
                                                                        onChange={(event) => onSelectAll(event.target.checked)}
                                                                    />
                                                                    <Typography variant="body1" className={classes.tableHeader}>{header.name}</Typography>
                                                                </Grid> :
                                                                    <Typography variant="body1" className={classes.tableHeader}>
                                                                        {header.name}
                                                                    </Typography>
                                                            }
                                                        </TableSortLabel>
                                                        :
                                                        <Typography variant="body1" className={classes.tableHeader}>
                                                            {header.name}
                                                        </Typography>
                                                }
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortTable(filterPackages, order, orderBy).map((packageInfo, index) =>
                                    <TableRow key={index}>
                                        {
                                            appConstants.packageHeaders.map((header, index) =>
                                                <TableCell key={index} className={classes.tableCell}>
                                                    {
                                                        header.value === "name" && <Grid className={classes.flex}>
                                                            <CheckboxComponent
                                                                viewBox="0 0 14 21"
                                                                checked={Boolean(packageInfo.isChecked)}
                                                                onClick={(event) => event.stopPropagation()}
                                                                onChange={(event) => onSelectPackage(packageInfo.id, event.target.checked)}
                                                            />
                                                            <Typography onClick={() => history.push(`/editpackage/${packageInfo.id}`)}>{packageInfo[header.value] ? packageInfo[header.value] : "NA"}</Typography>
                                                        </Grid>
                                                    }
                                                    {
                                                        header.value !== "actions" && header.value !== "name" && header.value !== "status" &&
                                                        <Typography>{packageInfo[header.value] ? packageInfo[header.value] : "NA"}</Typography>
                                                    }
                                                    {
                                                        header.value === "status" &&

                                                        <Switch
                                                            checked={packageInfo.is_active}
                                                            onChange={(event) => onChangestatus(packageInfo.id, event.target.checked)}
                                                        />
                                                    }
                                                    {
                                                        header.value === "actions" &&
                                                        <Grid className={classes.flex}>
                                                            <ToolTipComponent title="Edit" placement="bottom" arrow>
                                                                <IconButton className={classNames(classes.nopadding, classes.marginRight5)} onClick={() => history.push(`/editpackage/${packageInfo.id}`)}>
                                                                    <svg
                                                                        version="1.1"
                                                                        id="Layer_1"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="21.349"
                                                                        height="23.349"
                                                                        x="0px"
                                                                        y="0px"
                                                                        viewBox="0 0 32 32"
                                                                    >
                                                                        <g id="edit" transform="translate(0 0)">
                                                                            <path
                                                                                fill={theme.palette.primary.main}
                                                                                stroke={theme.palette.primary.main}
                                                                                strokeWidth="0.3"
                                                                                strokeMiterlimit="10"
                                                                                d="M27.2,8L24,4.8c-0.8-0.8-2.2-0.8-3,0L6.5,19.4c-0.1,0.1-0.1,0.1-0.1,0.2l-2.1,7.5c-0.1,0.3,0.1,0.6,0.4,0.7
                        c0.1,0,0.2,0,0.3,0l7.5-2.1c0.1,0,0.2-0.1,0.2-0.1l14.6-14.4C28,10.2,28,8.9,27.2,8L27.2,8z M18.6,8.7l2,2L10.2,21l-0.7-1.5
                        c-0.1-0.2-0.3-0.3-0.5-0.3H8.2L18.6,8.7z M5.5,26.5L6.2,24L8,25.8L5.5,26.5z M11.7,24.7l-2.6,0.7l-2.6-2.6l0.7-2.6h1.4l0.9,1.8
                        c0.1,0.1,0.1,0.2,0.2,0.2l1.8,0.9L11.7,24.7L11.7,24.7z M12.8,23.8V23c0-0.2-0.1-0.4-0.3-0.5L11,21.7l10.3-10.3l2,2L12.8,23.8z
                        M26.4,10.3l-2.4,2.3l-4.7-4.7l2.3-2.4c0.4-0.4,1.1-0.4,1.5,0l3.2,3.2C26.8,9.2,26.8,9.9,26.4,10.3L26.4,10.3z"
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </IconButton>
                                                            </ToolTipComponent>
                                                            <ToolTipComponent title="Delete" placement="bottom" arrow>
                                                                <IconButton className={classNames(classes.nopadding, classes.marginRight10)} onClick={() => { setSelectedLicensePackage(packageInfo); setShowDeleteDialog(true); }}>
                                                                    <svg version="1.1"
                                                                        id="Layer_1"
                                                                        width="18"
                                                                        height="18"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        x="0px"
                                                                        y="0px"
                                                                        viewBox="0 0 32 32">
                                                                        <g id="Delete">
                                                                            <path fill={theme.palette.primary.main}
                                                                                stroke={theme.palette.primary.main}
                                                                                strokeWidth="0.92"
                                                                                strokeMiterlimit="10"
                                                                                d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
		L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
		L22.3,25.8z" />
                                                                            <path fill={theme.palette.primary.main}
                                                                                stroke={theme.palette.primary.main}
                                                                                strokeWidth="0.92"
                                                                                strokeMiterlimit="10"
                                                                                d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
		c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
		c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                                        </g>
                                                                    </svg>
                                                                </IconButton>
                                                            </ToolTipComponent>
                                                            {
                                                                packageInfo.is_active &&
                                                                <ToolTipComponent title="Generate License" placement="bottom" arrow>
                                                                    <IconButton className={classNames(classes.nopadding, classes.marginRight10)} onClick={(event) => openGenerateLicenseModel(event, packageInfo.id)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.823" height="16.249" viewBox="0 0 15.823 16.249">
                                                                            <path fill={theme.palette.primary.main} d="M22.26,9.905,20.766,8.737a5.88,5.88,0,0,0,.035-.613,5.7,5.7,0,0,0-.035-.613l1.495-1.169a.732.732,0,0,0,.177-.933L20.884,2.722a.72.72,0,0,0-.9-.323l-1.762.708a6.042,6.042,0,0,0-1.058-.614L16.9.623A.719.719,0,0,0,16.18,0H13.065a.717.717,0,0,0-.716.617L12.08,2.494a6.229,6.229,0,0,0-1.056.614L9.257,2.4a.734.734,0,0,0-.892.318L6.81,5.406a.728.728,0,0,0,.176.938L8.48,7.512a5.357,5.357,0,0,0,0,1.226L6.985,9.907a.732.732,0,0,0-.176.933l1.553,2.687a.719.719,0,0,0,.9.323l1.762-.708a6.1,6.1,0,0,0,1.058.614l.268,1.869a.718.718,0,0,0,.718.624H16.18a.718.718,0,0,0,.717-.617l.269-1.875a6.258,6.258,0,0,0,1.056-.615l1.767.71a.728.728,0,0,0,.265.05.715.715,0,0,0,.626-.367l1.56-2.7A.731.731,0,0,0,22.26,9.905Zm-7.637.928a2.708,2.708,0,1,1,2.708-2.708A2.711,2.711,0,0,1,14.622,10.833Z" transform="translate(-6.71)" />
                                                                        </svg>
                                                                    </IconButton>
                                                                </ToolTipComponent>
                                                            }

                                                            <DownloadCSV packageId={packageInfo.id} />

                                                        </Grid>
                                                    }

                                                </TableCell>
                                            )
                                        }
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>}
                    {!isLoading && filterPackages.length === 0 && <NoResultFound />}
                </Grid>
            </ValidatorForm>
            {isLoading && <Loader />}
            {
                anchorEl &&
                <GenerateLicense
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    packageId={selectedPackage}
                />
            }
            {
                isShowDeleteDialog &&
                <AlertDialog title="Delete License Package"
                    message={`Are you sure you want to delete the selected license package ${selectedLicensePackage ? selectedLicensePackage.name : ''}?`}
                    okButtonText="OK"
                    cancelButtonText="Cancel"
                    show={Boolean(isShowDeleteDialog)}
                    onClickOK={() => Boolean(selectedLicensePackage) ? onDelete(selectedLicensePackage.id) : onDeleteAll()}
                    onClickCancel={() => { setShowDeleteDialog(false); setSelectedLicensePackage(null) }} />
            }
        </Grid>
    )
}



Package.propTypes = {
    classes: PropTypes.object
};

export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(Package);