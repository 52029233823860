import palette from './palette';

const typography = {
	h1: {
		fontFamily: palette.titleFont,
		fontSize: 26,
		fontWeight: 500,
		lineHeight: 1
	},
	h2: {
		fontFamily: palette.titleFont,
		fontSize: 24,
		fontWeight: 500,
		lineHeight: 1
	},
	h3: {
		fontFamily: palette.titleFont,
		fontSize: 20,
		fontWeight: 500,
		lineHeight: 1.04
	},
	h4: {
		fontFamily: palette.titleFont,
		fontSize: 18,
		fontWeight: 500,
		lineHeight: 1.17
	},
	h5: {
		fontFamily: palette.titleFont,
		fontSize: 16,
		fontWeight: 500,
		lineHeight: 1.33
	},
	h6: {
		fontFamily: palette.titleFont,
		fontSize: 15,
		fontWeight: 500,
		lineHeight: 1.6
	},
	subtitle1: {
		fontFamily: palette.textFont,
		fontSize: 14,
		lineHeight: 1.4,
		color: palette.grey.dark
	},
	subtitle2: {
		fontFamily: palette.titleFont,
		fontSize: 13,
		lineHeight: 1.6
	},
	body1: {
		fontFamily: palette.textFont,
		fontSize: 13,
		fontWeight: 400,
		lineHeight: 1.7
	},
	body2: {
		fontFamily: palette.textFont,
		fontSize: 12,
		fontWeight: 400,
		lineHeight: 1.5
	},
	button: {
		fontFamily: palette.titleFont,
		fontSize: 14
	},
	caption: {
		fontFamily: palette.titleFont,
		fontSize: 14
	},
	overline: {
		fontSize: 14,
		fontWeight: 500,
		textTransform: 'uppercase'
	}
};

export default typography;