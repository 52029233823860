import { compose, createStore, applyMiddleware } from 'redux';
import { logger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import appReducer from '../reducers';
import { accountActions } from '../constants/actionTypes/accountActionTypes';

const rootReducer = (state, action) => {

	if (action.type === accountActions.LOGOUT) {
		state = undefined;
	}


	return appReducer(state, action);
};

const enhancer = compose(
	applyMiddleware(
		thunkMiddleware,
		logger
	)
);

export default function configureStore(initialState) {
	return createStore(
		rootReducer,
		initialState,
		enhancer
	);
}