
const alertBoxStyle = (theme) => ({
	root: {
		flexGrow: 1
	},
	success: {
		backgroundColor: '#ffffff',
		borderLeft: '5px solid #B5D99C',
		color: '#222'
	},
	error: {
		backgroundColor: '#ffffff',
		borderLeft: '5px solid #F44336',
		color: '#F44336'
	},
	info: {
		backgroundColor: '#ffffff',
		borderLeft: '5px solid #F7C59F',
		color: '#222'
	},
	warning: {
		backgroundColor: '#ffffff',
		borderLeft: '5px solid #F7C59F',
		color: '#222'
	},
	icon: {
		fontSize: 14
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1)
	},
	message: {
		display: 'flex',
		alignItems: 'center'
	}
});

export default alertBoxStyle;