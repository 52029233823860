const SettingStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    title: {
        marginTop: 10,
        marginBottom: 10,
        fontSize: 24
    },
    settingsTabContainer: {
        marginTop: '-1px',
        borderBottom: `1px solid ${theme.palette.grey.extraLight}`
    },
    marginBottom15: {
        marginBottom: 15
    },
    marginBottom12: {
        marginBottom: 12
    },
    marginLeft5: {
        marginLeft: 5
    },
    profileContainer: {
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        padding: '25px 25px 40px 25px'
    },
    profileImage: {
        height: 200,
        width: 200,
        backgroundPosition: 'center',
        backgroundColor: theme.palette.background.container,
        border: '1px solid #DFEAEE',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        '& .image': {
            position: 'absolute',
            bottom: "-20px",
            left: 0,
            right: 0,
            color: theme.palette.secondary.main,
            textAlign: 'center',
            cursor: 'pointer',
            transition: 'all ease 0.3s',
            display: "none",
            backgroundColor: theme.palette.background.paper,
            padding: 5,
            borderTop: `1px solid ${theme.palette.border.default}`
        },
        '&:hover .image': {
            bottom: 0,
            display: "block"
        },
        '& .remove-profile': {
            padding: 0,
            position: "absolute",
            bottom: 5,
            right: 10,
            zIndex: 1,
            width: 15,
            display: "none"
        },
        '&:hover .remove-profile': {
            display: 'block'
        }

    },
    profileUploadTxt: {
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 0,
        color: theme.palette.grey.dark,
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.dark
        }
    },
    submitBtn: {
        marginRight: 15
    },
    settingTabContainer: {
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        padding: '25px',
        position: 'relative',
        height: 'calc(100vh - 150px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        alignContent: "baseline"
    },
    tableHeadCell: {
        fontSize: 13,
        color: '#222',
        textTransform: 'capitalize'
    },
    tableCell: {
        fontSize: 12,
        color: '#222'
    },
    tabTitle: {
        color: theme.palette.grey.darkTxt
    },
    organizationDetailContainer: {
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        padding: 30,
        height: 'calc(100vh - 160px)',
        overflowY: 'auto',
        alignContent: "baseline"
    },
    organizationListcontainer: {
        '& .MuiPaper-root': {
            height: '30px !important'
        },
        '& .MuiIconButton-root': {
            padding: '8px'
        }
    },
    enrichmentDetailContainer: {
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        padding: 30,
        height: 'calc(100vh - 160px)',
        overflowY: 'auto',
        alignContent: "baseline"
    },
    moduleContainer: {
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        padding: '0px 25px 65px 25px',
        overflowY: 'auto',
        height: 'calc(100vh - 160px)',
        alignContent: "baseline"
    },
    moduleContainer2: {
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        padding: '0px 25px 65px 25px',
        overflowY: 'auto',
        height: 'calc(100vh - 130px)',
        alignContent: "baseline"
    },
    moduleSection: {
        paddingTop: 25
    },
    moduleTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#222',
        display: 'inline-flex',
        marginRight: 15
    },
    formGroup: {
        padding: '10px 20px'
    },
    formLabelSection: {
        width: '19%',
        paddingBottom: 10,
        '& .MuiSwitch-sizeSmall': {
            marginRight: 4
        }
    },
    formLabel: {
        '& .MuiTypography-body1': {
            fontSize: 13,
            color: '#222'
        }
    },
    textBox: {
        width: '80px !important',
        '& .MuiInput-underline:before': {
            height: '25px !important '
        },
        '& input': {
            height: '25px !important '
        }
    },
    divider: {
        width: '100%',
        marginTop: 5,
        backgroundColor: theme.palette.border.default
    },
    subModuleSection: {
        backgroundColor: theme.palette.background.container,
        border: `1px solid ${theme.palette.background.container}`,
        padding: '15px 10px 15px 25px',
        '&:hover': {
            border: '1px solid rgba(26,54,126,0.125)'
        }
    },
    subDivider: {
        width: '100%',
        marginTop: 5,
        backgroundColor: theme.palette.border.dark
    },
    referenceContainer: {
        padding: 25,
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        height: 'calc( 100vh - 160px )',
        overflowY: "auto",
        alignContent: "baseline"
    },
    referenceContainer2: {
        padding: 25,
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        height: 'calc( 100vh - 150px )',
        overflowY: "auto",
        alignContent: "baseline",
        '& .MuiChip-root': {
            maxWidth: '100%'
        }
    },
    storageContainer: {
        padding: 25,
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        height: 'calc( 100vh - 160px )',
        overflowY: "auto",
        alignContent: "baseline"
    },
    storageContainer2: {
        padding: 25,
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        height: 'calc( 100vh - 130px )',
        overflowY: "auto",
        alignContent: "baseline"

    },
    referenceTxtHeader: {
        fontWeight: 600
    },
    referenceHeader: {
        borderBottom: `1px solid ${theme.palette.border.normal}`,
        paddingBottom: '8px'
    },
    referenceBodyRow: {
        borderBottom: `1px solid ${theme.palette.border.normal}`,
        padding: '6px 12px !important',
        position: 'relative',
        height: 38,
        '& .MuiChip-root': {
            maxWidth: '100%'
        }
    },
    domainBodyRow: {
        borderBottom: `1px solid ${theme.palette.border.normal}`,
        padding: '6px 12px !important',
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
    },
    UserBodyRow: {
        borderBottom: `1px solid ${theme.palette.border.normal}`,
        padding: '2px 0px !important',
        position: 'relative',
        height: 42
    },
    referenceIcon: {
        padding: 0
    },
    referenceSettingIcon: {
        marginRight: 15
    },
    referenceMenu: {
        fontSize: 13
    },
    referenceInput: {
        '& input': {
            fontSize: 13
        },
        '& .MuiSelect-icon': {
            display: 'none'
        },
        '& .MuiSelect-select.MuiSelect-select': {
            fontSize: 13
        }
    },
    referenceAddSection: {
        marginTop: 5
    },
    referenceSection: {
        paddingRight: 10,
        paddingLeft: 10
    },
    userContainer: {
        padding: 25,
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        position: 'relative',
        height: 'calc(100vh - 160px)',
        overflow: 'auto',
        alignContent: "baseline"
    },
    userAddContainer: {
        backgroundColor: theme.palette.background.container,
        padding: 15
    },
    userTitle: {
        fontWeight: 500
    },
    userRow: {
        padding: '32px 16px 16px 16px !important'
    },
    userListContainer: {
        marginTop: 30
    },
    avatarSection: {
        backgroundColor: 'transparent',
        border: '1px solid #ddd',
        margin: '5px 10px',
        height: 22,
        width: 22
    },
    emailSection: {
        padding: '6px 0px'
    },
    roleSection: {
        padding: '4px 0px'
    },
    userDeleteIcon: {
        padding: 7
    },
    switchSection: {
        padding: '4px 0px'
    },
    themeContainer: {
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        padding: '0px 25px 65px 25px',
        overflowY: 'auto',
        height: 'calc(100vh - 160px)',
        position: 'relative',
        alignContent: "baseline"
    },
    themeContainer2: {
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        padding: '0px 25px 65px 25px',
        overflowY: 'auto',
        height: 'calc(100vh - 130px)',
        position: 'relative',
        alignContent: "baseline"
    },
    themeSection: {
        paddingTop: 25
    },
    themeTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#222',
        display: 'inline-flex',
        marginRight: 15
    },
    themeBanner: {
        width: '230px',
        height: '129px',
        border: '1px solid #EDF2F4',
        marginTop: 20,
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        backgroundSize: "contain"
    },
    squareLogo: {
        width: '130px',
        height: '130px',
        backgroundSize: '60%'
    },
    themeupload: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    selectImg: {
        width: '230px',
        height: '129px',
        objectFit: "scale-down"
    },
    marginTop20: {
        marginTop: '20px'
    },
    themeSelectColor: {
        width: "38px",
        height: "38px",
        backgroundColor: '#F38080',
        borderRadius: '30px',
        marginRight: '15px'
    },
    marginTop5: {
        marginTop: "5px"
    },
    marginBottom20: {
        marginBottom: "20px"
    },
    previewContainer: {
        padding: "20px",
        background: '#FFFFFF',
        boxShadow: '0px 2px 30px #CFE0E799',
        border: '1px dashed #DBE2E5'
    },
    previewBodyContainer: {
        background: "#F6F8F9",
        padding: "20px"
    },
    progressBar: {
        height: '14px',
        background: '#54DF9A',
        flexGrow: '1'
    },
    referenceChip: {
        '& .MuiChip-deleteIcon': {
            width: 8,
            height: 8
        }
    },
    referencePopover: {
        padding: 15
    },
    settingsSelect: {
        '& .MuiSelect-select': {
            width: "200px"
        }
    },
    marginTop15: {
        marginTop: '15px'
    },
    accordianStyles: {
        background: '#f8f8f8',
        minHeight: 42,
        paddingLeft: 10,
        paddingTop: 6,
        paddingBottom: 6,
        cursor: 'pointer'
    },
    storageAcoordion: {
        marginBottom: 16,
        '&:last-child': {
            marginBottom: 0
        },
        '&::before': {
            top: 0,
            height: 0
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '8px 0 !important'
        },
        '& .MuiIconButton-root': {
            padding: '8px'
        },
        '& .MuiAccordionSummary-content': {
            margin: '6px 0',
            padding: '0 10px'
        },
        '& .MuiAccordionSummary-root': {
            minHeight: '42px',
            background: '#f8f8f8',
            paddingRight: '10px'
        },
        '& .MuiAccordionDetails-root': {
            padding: '8px 0px 16px'
        }
    },
    search: {
        height: '32px !important',
        display: 'flex',
        alignItems: 'center',
        '& .MuiIconButton-root': {
            padding: '9px'
        }
    },
    userTable: {
        paddingBottom: 2
    },
    storageSysConfig: {
        '& .MuiTypography-root': {
            paddingLeft: 8
        }
    },
    vishiblehidden: {
        'visibility': 'hidden'
    },
    dqSearchBoxRefList: {
        transitionDuration: '500ms',
        transition: 'width .55s ease',
        animation: 'slide-left195 0.4s ease',
        width: '195px',
        height: 30
    },
    domainActions: {
        borderBottom: `1px solid ${theme.palette.border.normal}`,
        padding: '6px 12px !important',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
    },
    domainImgContainer: {
        width: 35,
        height: 35,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        border: `1px solid ${theme.palette.border.default}`
    },
    noresult: {
        marginTop: 50
    },
    alignIconCenter: {
        position: 'relative',
        top: -2,
        left: 2
    },
    padding8: {
        padding: 8
    },
    enrichmentTableCell: {
        fontSize: 13,
        color: theme.palette.grey.darkTxt,
        maxWidth: 200,
        display: "block",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    paramChip: {
        '& .MuiChip-label': {
            fontSize: 13,
            color: theme.palette.grey.darkTxt
        },
        marginBottom: 2.5,
        marginTop: 2.5
    },
    authorizationGrid: {
        height: 100,
        marginLeft: 15,
        marginRight: 15
    },
    heading: {
        fontWeight: 'bold',
        minWidth: '50%'
    },
    secondaryHeading: {
        fontWeight: 400
    },
    notificationSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    tokenSection: {
        position: 'relative',
        transition: 'all ease 0.3s',
        '& .button': {
            display: "none"
        },
        '&:hover .button': {
            display: "block",
            position: "absolute",
            right: 18,
            top: 55,
            minWidth: 35,
            height: 40
        }
    },
    paddingBottom0: {
        paddingBottom: '0px !important'
    },
    paddingLeft12: {
        paddingLeft: '12px !important'
    },
    width100: {
        width: '100%'
    },
    displayBlock: {
        display: 'block !important'
    },
    accordianSection: {
        padding: 5
        // border: `1px solid ${theme.palette.border.default}`
    },
    exportButton: {
        width: '100%',
        textAlign: 'right',
        '& .MuiIconButton-root': {
            padding: 0,
            marginBottom: 15
        }
    },
    popUp: {
        display: 'flex',
        flexDirection: 'column'
    },
    buttonLoader: {
        paddingRight: '50px'
    },
    roleHeader: {
        borderBottom: `1px solid ${theme.palette.border.normal}`,
        padding: '8px'
    },
    roleNameTxt: {
        padding: '8px 0px'
    },
    formAccordian: {
        width: '20%',
        marginRight: '35px !important',
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& .MuiAccordionSummary-root': {
            paddingLeft: 20
        },
        '& .MuiAccordionDetails-root': {
            paddingLeft: '30px !important'
        }
    },
   
});

export default SettingStyles;