const filterStyles = (theme) => ({
    filterHideShowContainer: {
        position: 'absolute',
        right: 85,
        background: '#fff',
        zIndex: 1,
        marginRight: '10px',
        transitionDuration: '500ms',
        transition: 'width .55s ease',
        animation: 'slide-left536 0.4s ease',

        // border: '1px solid #d8d5d5',
        boxShadow: '0px 0px 5px 3px #80808047',
        '& .datasourceFilterSection': {
            height: 60,
            padding: '0 10px'
        }
    }
});

export default filterStyles;