const LoaderStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    loader: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#ffffff',
        zIndex: 9999
    },
    buttonLoader: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'inherit !important',
        zIndex: 9999
    },
    loaderContainer: {
        display: 'flex'
    }
});

export default LoaderStyles;