import React from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';


const Breadcrumb = (props) => {
    const { classes, history, breadcrumb } = props;
    return (
        <Grid className={classes.marginTop15}>
            {
                breadcrumb.length > 1 && breadcrumb.map((crumb, index) =>
                    <Typography variant="body2" key={`crumb_${index}`} component="span" className={classes.link} onClick={() => crumb.path !== "" && history.push(crumb.path)}>
                        {crumb.name}
                        {(index !== breadcrumb.length - 1) ? ' | ' : null}
                    </Typography>
                )
            }
        </Grid>
    )
};

Breadcrumb.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    breadcrumb: PropTypes.array
};

export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(Breadcrumb);






