import React from 'react';
import PropTypes from 'prop-types';
import { TextValidator } from 'react-material-ui-form-validator';

const TextBox = (props) => {
	const { onChange, ...rest } = props;
	return (
		<TextValidator autoComplete={"off"} onChange={props.onChange} {...rest} />
	);
};

TextBox.propTypes = {
	onChange: PropTypes.func
};

export default TextBox;