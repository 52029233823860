const ClientStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    profileContainer: {
        backgroundColor: '#ffffff',
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        padding: '25px 25px 40px 25px'
    },
    formContainer: {
        marginTop: 20
    }
    
});

export default ClientStyles;