const ProfileStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    profileContainer: {
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        padding: '25px 25px 40px 25px'
    },
    scrollStyle: {
        maxHeight: '81vh',
        overflowX: 'auto'
    },
    profileImage: {
        height: 200,
        width: 200,
        backgroundPosition: 'center',
        backgroundColor: theme.palette.background.container,
        border: '1px solid #DFEAEE',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        '& .image': {
            position: 'absolute',
            bottom: "-20px",
            left: 0,
            right: 0,
            color: theme.palette.secondary.main,
            textAlign: 'center',
            cursor: 'pointer',
            transition: 'all ease 0.3s',
            display: "none",
            backgroundColor: theme.palette.background.paper,
            padding: 5,
            borderTop: `1px solid ${theme.palette.border.default}`
        },
        '&:hover .image': {
            bottom: 0,
            display: "block"
        },
        '& .remove-profile': {
            padding: 0,
            position: "absolute",
            bottom: 5,
            right: 10,
            zIndex: 1,
            width: 15,
            display: "none"
        },
        '&:hover .remove-profile': {
            display: 'block'
        }

    },
    profileUploadTxt: {
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 0,
        color: theme.palette.grey.dark,
        textAlign: 'center',
        '&:hover': {
            color: theme.palette.primary.dark
        }
    },
    submitBtn: {
        marginRight: 15
    },
    settingTabContainer: {
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        padding: '25px',
        position: 'relative',
        height: 'calc(100vh - 130px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        alignContent: "baseline"
    },
    tableHeadCell: {
        fontSize: 13,
        color: '#222',
        textTransform: 'capitalize'
    },
    tableCell: {
        fontSize: 12,
        color: '#222'
    },
    tabTitle: {
        color: theme.palette.grey.darkTxt
    },
    
    formLabel: {
        '& .MuiTypography-body1': {
            fontSize: 13,
            color: '#222'
        }
    },
    textBox: {
        width: '80px !important',
        '& .MuiInput-underline:before': {
            height: '25px !important '
        },
        '& input': {
            height: '25px !important '
        }
    },
    divider: {
        width: '100%',
        marginTop: 5,
        backgroundColor: theme.palette.border.default
    },
    subDivider: {
        width: '100%',
        marginTop: 5,
        backgroundColor: theme.palette.border.dark
    },
    
    userContainer: {
        padding: 25,
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        position: 'relative',
        height: 'calc(100vh - 160px)',
        overflow: 'auto',
        alignContent: "baseline"
    },
    userAddContainer: {
        backgroundColor: theme.palette.background.container,
        padding: 15
    },
    userTitle: {
        fontWeight: 500
    },
    userRow: {
        padding: '25px 16px 20px 25px !important'
    },
    userListContainer: {
        marginTop: 30
    },
    avatarSection: {
        backgroundColor: 'transparent',
        border: '1px solid #ddd',
        margin: '5px 10px',
        height: 22,
        width: 22
    },
   
    progressBar: {
        height: '14px',
        background: '#54DF9A',
        flexGrow: '1'
    },
    settingsSelect: {
        '& .MuiSelect-select': {
            width: "200px"
        }
    },
    marginTop15: {
        marginTop: '15px'
    },
    
});

export default ProfileStyles;