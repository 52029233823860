import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Styles from './Styles.jsx';


const PublicLayout = (props) => {
	const { classes } = props;
	return (
		<div className={classes.root} classes={classes} >
			{props.children}
		</div >
	);
};

PublicLayout.propTypes = {
	classes: PropTypes.object,
	children: PropTypes.node
};

const mapStateToProps = (state) => {
	return {
		...state
	};
};

export default connect(mapStateToProps)(withStyles(Styles)(PublicLayout));