export const licenseActions = {
    GET_LICENSE_REQUEST: 'GET_LICENSE_REQUEST',
    GET_LICENSE_SUCCESS: 'GET_LICENSE_SUCCESS',
    GET_LICENSE_FAILURE: 'GET_LICENSE_FAILURE',

    GET_CONNECTION_TYPES_REQUEST: 'GET_CONNECTION_TYPES_REQUEST',
    GET_CONNECTION_TYPES_SUCCESS: 'GET_CONNECTION_TYPES_SUCCESS',
    GET_CONNECTION_TYPES_FAILURE: 'GET_CONNECTION_TYPES_FAILURE',

    CREATE_LICENSE_REQUEST: 'CREATE_LICENSE_REQUEST',
    CREATE_LICENSE_SUCCESS: 'CREATE_LICENSE_SUCCESS',
    CREATE_LICENSE_FAILURE: 'CREATE_LICENSE_FAILURE',

    GET_DEACTIVE_LICENSE_EMAIL_REQUEST: 'GET_DEACTIVE_LICENSE_EMAIL_REQUEST',
    GET_DEACTIVE_LICENSE_EMAIL_SUCCESS: 'GET_DEACTIVE_LICENSE_EMAIL_SUCCESS',
    GET_DEACTIVE_LICENSE_EMAIL_FAILURE: 'GET_DEACTIVE_LICENSE_EMAIL_FAILURE',

    GET_DEACTIVE_LICENSE_REQUEST: 'GET_DEACTIVE_LICENSE_REQUEST',
    GET_DEACTIVE_LICENSE_SUCCESS: 'GET_DEACTIVE_LICENSE_SUCCESS',
    GET_DEACTIVE_LICENSE_FAILURE: 'GET_DEACTIVE_LICENSE_FAILURE',

    GENERATE_LICENSE_REQUEST: 'GENERATE_LICENSE_REQUEST',
    GENERATE_LICENSE_SUCCESS: 'GENERATE_LICENSE_SUCCESS',
    GENERATE_LICENSE_FAILURE: 'GENERATE_LICENSE_FAILURE',

    GET_LICENSE_CONFIG_REQUEST: 'GET_LICENSE_CONFIG_REQUEST',
    GET_LICENSE_CONFIG_SUCCESS: 'GET_LICENSE_CONFIG_SUCCESS',
    GET_LICENSE_CONFIG_FAILURE: 'GET_LICENSE_CONFIG_FAILURE',

    UPDATE_LICENSE_CONFIG_REQUEST: 'UPDATE_LICENSE_CONFIG_REQUEST',
    UPDATE_LICENSE_CONFIG_SUCCESS: 'UPDATE_LICENSE_CONFIG_SUCCESS',
    UPDATE_LICENSE_CONFIG_FAILURE: 'UPDATE_LICENSE_CONFIG_FAILURE',

    GET_AUDIT_LOG_REQUEST: 'GET_AUDIT_LOG_REQUEST',
    GET_AUDIT_LOG_SUCCESS: 'GET_AUDIT_LOG_SUCCESS',
    GET_AUDIT_LOG_FAILURE: 'GET_AUDIT_LOG_FAILURE'
};