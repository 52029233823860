let endPoint = 'http://newlicense.dqlabs.cloud/api';
const environment = process.env.REACT_APP_ENVIRONMENT || 'development';
switch (environment) {
    case 'QA':
        endPoint = 'http://newlicense.dqlabs.cloud:8002/api';
        break;
    case 'SPOKANE':
    case 'PROD':
        endPoint = 'http://dqlabs-dock.qaserverix.co:8002/api';
        break;
    default:
        break;
}

export const appConstants = {
    API_BASE_URL: endPoint,
    END_POINTS: {
        authenticate: {
            login: 'login',
            getUser: 'authenticate/getuser',
            forgotPassword: 'authenticate/forgotpassword',
            verifyResetToken: 'authenticate/verifyresetpasswordtoken',
            resetPassword: 'authenticate/resetpassword',
        },
        license: {
            getAllLicense: 'licenses/getlicense',
            connectionType: 'licenses/connectiontype',
            createLicense: 'licenses/createlicense',
            deactiveLicense: 'licenses/deactivelicense',
            deactiveEmail: 'licenses/deactiveemail',
            generateLicense: 'licenses/generatelicense',
            getLicenseConfig: 'licenses/getlicenseconfig',
            updateLicense: 'licenses/updatelicense',
            getAuduitLog: 'licenses/getauditlog'
        },
        package: {
            createPackage: 'package/createpackage',
            updatePackage: 'package/updatepackage',
            getAllPackage: 'package/getallpackage',
            getPackageById: 'package/getpackage',
            deletePackage: 'package/deletepackage',
            getPackageLicense: 'package/getpackagelicense',
            getActivePackage: 'setting/getactivepackage'
        },
        setting: {
            getProfile: 'setting/getprofile',
            updateProfile: 'setting/updateprofile',
            uploadUserProfile: 'setting/uploaduserprofile',
            createClient: 'setting/createclient',
            updateClient: 'setting/updateclient',
            getClient: 'setting/getclient',
            getAllClient: 'setting/getallclient',
            getActiveClient: 'setting/getactiveclient',
            deleteClient: 'setting/deleteclient',
            getClientLicense: 'setting/getclientlicense',
            getTheme: 'setting/gettheme',
            updateTheme: 'setting/updatetheme',
            uploadThemeFile: 'setting/uploadthemefile',
        }
    },
    licenseTypes: ["Single Tenant - Single Organization", "Single Tenant - Multiple Organizations", "Multi Tenant - Multiple Organizations", "Organization"],
    licenseModes: ["SandBox", "Production"],
    timePeriod: [7, 15, 30, 45, 90, 100, 250, 365],
    packageFilters: [{ name: "Active License Pacakges", value: 'active' }, { name: "Deactive License Pacakges", value: 'deactive' }],
    licenseFilters: [{ name: "Active License", value: 'active' }, { name: "Deactive License", value: 'deactive' }],
    licenseHeaders: [{ name: "Package Name", value: "package_name" }, { name: "License Key", value: "license_key" }, { name: "License Type", value: "license_type" }, { name: "Client Name", value: "client_name" }, { name: "Sub Client Name", value: "sub_client_name" }, { name: "Status", value: "status" }, { name: "Activated On", value: "license_activate_date" }, { name: "MAC ID", value: "mac_address" }, { name: "IP address", value: "ip_address" }, { name: "Expires On", value: "license_expires" }, { name: "Actions", value: "actions" }],
    packageHeaders: [{ name: "Package Name", value: "name" }, { name: "License Type", value: "license_type" }, { name: "License Mode", value: "license_mode" }, { name: "No Of License", value: "no_of_licenses" }, { name: "No Of Active License", value: "no_of_active_licenses" }, { name: "Status", value: "status" }, { name: "Actions", value: "actions" }],
    settingHeaders: ["Profile", "Clients"],
    clientHeaders: ["General", "Theme"],
    settingClientHeaders: ["Profile"],
    clientTableHeaders: ["Client Name", "Email ID", "Phone Number", "Number of assigned licenses", "Status", "Actions"],
    themeFonts: [
        {
            isDefault: false,
            fontName: 'Montserrat',
            url: 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200&display=swap'
        }, {
            isDefault: false,
            fontName: 'AvenirNextRegular',
            url: ''
        }, {
            isDefault: true,
            fontName: 'Roboto',
            url: ''
        }, {
            isDefault: false,
            fontName: 'Poppins',
            url: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap'
        }, {
            isDefault: false,
            fontName: 'Raleway',
            url: 'https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap'
        }, {
            isDefault: false,
            fontName: 'Open Sans',
            url: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap'
        }, {
            isDefault: false,
            fontName: 'Lato',
            url: 'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap'
        }, {
            isDefault: false,
            fontName: 'Slabo',
            url: 'https://fonts.googleapis.com/css2?family=Slabo+13px&display=swap'
        }, {
            isDefault: false,
            fontName: 'Oswald',
            url: 'https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600&display=swap'
        }, {
            isDefault: false,
            fontName: 'Source Sans Pro',
            url: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap'
        }, {
            isDefault: false,
            fontName: 'PT Sans',
            url: 'https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap'
        }, {
            isDefault: false,
            fontName: 'Lora',
            url: 'https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600&display=swap'
        }, {
            isDefault: false,
            fontName: 'Noto Sans',
            url: 'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap'
        }, {
            isDefault: false,
            fontName: 'Nunito Sans',
            url: 'https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap'
        }, {
            isDefault: false,
            fontName: 'Work Sans',
            url: 'https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap'
        },
        {
            isDefault: true,
            fontName: 'Nunito',
            url: 'https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800&display=swap'
        }
    ]
}
