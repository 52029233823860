const TextBoxStyles = (theme) => ({
	root: {
		height: '100%!important'
	},
	searchContainer: {
		// borderRadius: 2,
		height: 30,
		display: "flex",
		border: `1px solid ${theme.palette.grey.light}`,
		'& .MuiInputBase-input': {
			padding: 8,
			height: 14,
			fontSize: 13
		},
		'&:hover': {
			border: `1px solid ${theme.palette.grey.dark}`
		},
		'& .Mui-focused:before': {
			border: `1px solid ${theme.palette.primary.dark} !important`
		},
		'&.activeSearchBox': {
			border: `1px solid ${theme.palette.primary.dark} !important`
		}
	}
});

export default TextBoxStyles;