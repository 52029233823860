import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import theme from './theme';
import Routes from './routes/config';
import Alert from './components/Alert/Alert.jsx';
import { clearAlert } from './actions/alertActions';
import { getConnectionTypes } from './actions/licenseActions';
// import { getUser } from './actions/accountActions';
import { history } from './config/history';
import './App.css';

class App extends Component {

	constructor(props) {
		super(props);
		history.listen((location) => {
			return location;
		});
		this.props.getConnectionTypes();

		// if (!props.account.isLoggedIn && getCookie('token') !== "") {
		// 	this.getUser();
		// }
	}

	render() {
		return (
			<MuiThemeProvider theme={theme}>
				<CssBaseline />
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<Routes />
					<Alert message={this.props.alert.message} type={this.props.alert.type} onAlertClick={() => this.props.clearAlert()} />
				</MuiPickersUtilsProvider>
			</MuiThemeProvider>
		);
	}
}

App.propTypes = {
	alert: PropTypes.object,
	clearAlert: PropTypes.func,
	account: PropTypes.object,
	theme: PropTypes.object,
	getConnectionTypes: PropTypes.func
};

const mapStateToProps = ({ account, alert }) => {
	return {
		account,
		alert
	};
};

const mapDisptachToProps = (dispatch) => {
	return bindActionCreators({ clearAlert, getConnectionTypes }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDisptachToProps)(App));