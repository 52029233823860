export const settingActions = {

    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

    UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

    GET_ALL_CLIENT_REQUEST: 'GET_ALL_CLIENT_REQUEST',
    GET_ALL_CLIENT_SUCCESS: 'GET_ALL_CLIENT_SUCCESS',
    GET_ALL_CLIENT_FAILURE: 'GET_ALL_CLIENT_FAILURE',

    GET_CLIENT_REQUEST: 'GET_CLIENT_REQUEST',
    GET_CLIENT_SUCCESS: 'GET_CLIENT_SUCCESS',
    GET_CLIENT_FAILURE: 'GET_CLIENT_FAILURE',

    CREATE_CLIENT_REQUEST: 'CREATE_CLIENT_REQUEST',
    CREATE_CLIENT_SUCCESS: 'CREATE_CLIENT_SUCCESS',
    CREATE_CLIENT_FAILURE: 'CREATE_CLIENT_FAILURE',

    UPDATE_CLIENT_REQUEST: 'UPDATE_CLIENT_REQUEST',
    UPDATE_CLIENT_SUCCESS: 'UPDATE_CLIENT_SUCCESS',
    UPDATE_CLIENT_FAILURE: 'UPDATE_CLIENT_FAILURE',

    UPLOAD_USER_PROFILE_REQUEST: 'UPLOAD_USER_PROFILE_REQUEST',
    UPLOAD_USER_PROFILE_SUCCESS: 'UPLOAD_USER_PROFILE_SUCCESS',
    UPLOAD_USER_PROFILE_FAILURE: 'UPLOAD_USER_PROFILE_FAILURE',

    GET_ACTIVE_CLIENT_REQUEST: 'GET_ACTIVE_CLIENT_REQUEST',
    GET_ACTIVE_CLIENT_SUCCESS: 'GET_ACTIVE_CLIENT_SUCCESS',
    GET_ACTIVE_CLIENT_FAILURE: 'GET_ACTIVE_CLIENT_FAILURE',

    DELETE_CLIENT_REQUEST: 'DELETE_CLIENT_REQUEST',
    DELETE_CLIENT_SUCCESS: 'DELETE_CLIENT_SUCCESS',
    DELETE_CLIENT_FAILURE: 'DELETE_CLIENT_FAILURE',

    GET_CLIENT_LICENSE_REQUEST: 'GET_CLIENT_LICENSE_REQUEST',
    GET_CLIENT_LICENSE_SUCCESS: 'GET_CLIENT_LICENSE_SUCCESS',
    GET_CLIENT_LICENSE_FAILURE: 'GET_CLIENT_LICENSE_FAILURE',

    GET_THEME_REQUEST: 'GET_THEME_REQUEST',
    GET_THEME_SUCCESS: 'GET_THEME_SUCCESS',
    GET_THEME_FAILURE: 'GET_THEME_FAILURE',

    UPDATE_THEME_REQUEST: 'UPDATE_THEME_REQUEST',
    UPDATE_THEME_SUCCESS: 'UPDATE_THEME_SUCCESS',
    UPDATE_THEME_FAILURE: 'UPDATE_THEME_FAILURE',

    UPLOAD_THEME_FILE_REQUEST: 'UPLOAD_THEME_FILE_REQUEST',
    UPLOAD_THEME_FILE_SUCCESS: 'UPLOAD_THEME_FILE_SUCCESS',
    UPLOAD_THEME_FILE_FAILURE: 'UPLOAD_THEME_FILE_FAILURE',

};