export const accountActions = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    GET_USER_REQUEST: 'GET_USER_REQUEST',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_FAILURE',

    GET_VERIFY_TOKEN_REQUEST: 'GET_VERIFY_TOKEN_REQUEST',
    GET_VERIFY_TOKEN_SUCCESS: 'GET_VERIFY_TOKEN_SUCCESS',
    GET_VERIFY_TOKEN_FAILURE: 'GET_VERIFY_TOKEN_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    GET_FORGOT_PASSWORD_REQUEST: 'GET_FORGOT_PASSWORD_REQUEST',
    GET_FORGOT_PASSWORD_SUCCESS: 'GET_FORGOT_PASSWORD_SUCCESS',
    GET_FORGOT_PASSWORD_FAILURE: 'GET_FORGOT_PASSWORD_FAILURE',

    LOGOUT: 'LOGOUT',

    NAVIGATE: 'NAVIGATE'
};