import { settingActions } from '../constants/actionTypes/settingActionTypes';

const initialState = {
    clinets: [],
    clientNames: [],
    defaultTheme: {
        logo: "",
        banner: "",
        "login_copyright_text": "\u00a92020 DQLabs, Inc. All rights reserved.",
        "footer_copyright_text": "\u00a92020 DQLabs, Inc. All rights reserved.",
        "primary_color": "#F38080",
        "secondary_color": "#3abeff",
        "tertiary_color": "#F38080",
        darkTxt: "#1D2C2F",
        default: "#646666",
        exteremeLight: "#f2f6f7",
        extraLight: "#e1e5e6",
        light: "#c8cbcc",
        main: "#afb2b3",
        "heading_font": "Poppins",
        "text_font": "Lato"
    }
};


export const settingReducer = (state = initialState, action) => {
    if (!action || (action && !action.type)) {
        return state;
    }
    switch (action.type) {
        case settingActions.GET_ACTIVE_CLIENT_REQUEST:
        case settingActions.GET_ACTIVE_CLIENT_FAILURE:
            return {
                ...state,
                clinets: []
            };
        case settingActions.GET_ACTIVE_CLIENT_SUCCESS:
            return {
                ...state,
                clinets: action.responseData ? action.responseData : []
            };
        case settingActions.GET_ALL_CLIENT_REQUEST:
        case settingActions.GET_ALL_CLIENT_FAILURE:
            return {
                ...state,
                clientNames: []
            };
        case settingActions.GET_ALL_CLIENT_SUCCESS:
            return {
                ...state,
                clientNames: action.responseData ? action.responseData : []
            };
        default:
            return state;
    }
};