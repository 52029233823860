import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import PublicLayout from '../layouts/PublicLayout.jsx';
import { getCookie } from '../helpers/appHelpers';

const PublicRoute = ({ component: Component, ...rest }) => {
	const loggeInStatus = getCookie('token');
	return (
		<Route {...rest}
			render={
				(props) => (
					loggeInStatus === "" ?
						<PublicLayout>
							<Component {...props} />
						</PublicLayout>
						:
						<Redirect to={{ pathname: "/license", state: { from: props.location } }} />
				)
			} />
	);
};

PublicRoute.propTypes = {
	component: PropTypes.elementType,
	location: PropTypes.object
};

export default PublicRoute;