import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import { alertActions } from '../constants/actionTypes/alertActionTypes';
import { settingActions } from '../constants/actionTypes/settingActionTypes';
import * as service from '../services/apiService';

export const getProfile = () => {
    const endpoint = `${appConstants.END_POINTS.setting.getProfile}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_PROFILE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_PROFILE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_PROFILE_FAILURE, error));
        });
    };
};


export const updateProfile = (userId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateProfile}/${userId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_PROFILE_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.UPDATE_PROFILE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_PROFILE_FAILURE, error));
        });
    };
};

export const getAllClient = () => {
    return (dispatch) => {
        dispatch(request(settingActions.GET_ALL_CLIENT_REQUEST));
        return service.getRequest(appConstants.END_POINTS.setting.getAllClient).then((response) => {
            const clients = response.data.map((data) => data.name);
            dispatch(success(settingActions.GET_ALL_CLIENT_SUCCESS, clients));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_ALL_CLIENT_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getClient = (clientId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getClient}/${clientId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_CLIENT_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_CLIENT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_CLIENT_FAILURE, error));
        });
    };
};


export const createClient = (requestParams) => {
    return (dispatch) => {
        dispatch(request(settingActions.CREATE_CLIENT_REQUEST));
        return service.postRequest(appConstants.END_POINTS.setting.createClient, requestParams).then((response) => {
            dispatch(success(settingActions.CREATE_CLIENT_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Create Client Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.CREATE_CLIENT_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};



export const updateClient = (clientId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateClient}/${clientId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_CLIENT_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.UPDATE_CLIENT_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Client Updated Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_CLIENT_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};


export const deleteClient = (clientId) => {
    const endpoint = `${appConstants.END_POINTS.setting.deleteClient}/${clientId}`;
    return (dispatch) => {
        dispatch(request(settingActions.DELETE_CLIENT_REQUEST));
        return service.deleteRequest(endpoint).then((response) => {
            dispatch(success(settingActions.DELETE_CLIENT_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Client Deleted Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.DELETE_CLIENT_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};


export const updateUserProfile = (requestParams) => {
    return (dispatch) => {
        dispatch(request(settingActions.UPLOAD_USER_PROFILE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.setting.uploadUserProfile, requestParams, true).then((response) => {
            dispatch(success(settingActions.UPLOAD_USER_PROFILE_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "User Profile Updated Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPLOAD_USER_PROFILE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getActiveClients = () => {
    return (dispatch) => {
        dispatch(request(settingActions.GET_ACTIVE_CLIENT_REQUEST));
        return service.getRequest(appConstants.END_POINTS.setting.getActiveClient).then((response) => {
            dispatch(success(settingActions.GET_ACTIVE_CLIENT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_ACTIVE_CLIENT_FAILURE, error));
        });
    };
};


export const getClientLicense = (clientId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getClientLicense}/${clientId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_CLIENT_LICENSE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_CLIENT_LICENSE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_CLIENT_LICENSE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};


export const updateClientList = (clients) => {
    return {
        type: settingActions.GET_ALL_CLIENT_SUCCESS,
        responseData: clients
    };
};

export const getTheme = (clientId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getTheme}/${clientId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_THEME_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_THEME_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_THEME_FAILURE, error));
        });
    };
};

export const updateTheme = (requestParams, clientId) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateTheme}/${clientId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_THEME_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.UPDATE_THEME_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Theme Updated Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_THEME_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const uploadThemeFile = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.uploadThemeFile}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPLOAD_THEME_FILE_REQUEST));
        return service.postRequest(endpoint, requestParams, true).then((response) => {
            dispatch(success(settingActions.UPLOAD_THEME_FILE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPLOAD_THEME_FILE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, "Failed To Upload Logo"));
        });
    };
};
