import { accountActions } from '../constants/actionTypes/accountActionTypes';

const initialState = {
	isLoading: false,
	isLoggedIn: false,
	user: {},
	navigationPath: ""
};

export const accountReducer = (state = initialState, action) => {
	if (!action || (action && !action.type)) {
		return state;
	}
	switch (action.type) {
		case accountActions.LOGIN_REQUEST:
		case accountActions.USER_ACTIVATE_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case accountActions.LOGIN_SUCCESS:
			return {
				...state,
				user: action.responseData ? action.responseData : {},
				isLoggedIn: true,
				isLoading: false
			};
		case accountActions.LOGIN_FAILURE:
			return {
				...state,
				isLoggedIn: false,
				isLoading: false
			};
		case accountActions.LOGOUT:
			return {
				...state,
				user: {}
			};
		case accountActions.GET_FORGOT_PASSWORD_REQUEST:
		case accountActions.RESET_PASSWORD_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case accountActions.GET_FORGOT_PASSWORD_SUCCESS:
		case accountActions.GET_FORGOT_PASSWORD_FAILURE:
		case accountActions.RESET_PASSWORD_SUCCESS:
		case accountActions.RESET_PASSWORD_FAILURE:
		case accountActions.GET_VERIFY_TOKEN_REQUEST:
		case accountActions.GET_VERIFY_TOKEN_SUCCESS:
		case accountActions.GET_VERIFY_TOKEN_FAILURE:
			return {
				...state,
				isLoading: false
			};
		case accountActions.NAVIGATE:
			return {
				...state,
				navigationPath: action.responseData
			};
		default:
			return state;
	}
};