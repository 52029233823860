import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import { alertActions } from '../constants/actionTypes/alertActionTypes';
import { accountActions } from '../constants/actionTypes/accountActionTypes';
import * as service from '../services/apiService';
import { setCookie, removeCookie } from '../helpers/appHelpers';
import { history } from '../config/history';
import { getConnectionTypes } from '../actions/licenseActions';


export const authenticateLogin = (requestParams) => {
	return (dispatch) => {
		dispatch(request(accountActions.LOGIN_REQUEST));
		return service.postRequest(appConstants.END_POINTS.authenticate.login, requestParams).then((response) => {
			setCookie('token', response.token);
			localStorage.setItem('user', JSON.stringify(response.user));
			dispatch(getConnectionTypes());
			dispatch(success(accountActions.LOGIN_SUCCESS, response.user));
			sessionStorage.setItem('navigationPath', 'license');
			history.push({
				pathname: '/license'
			});
			return response.user;
		}, (error) => {
			dispatch(failure(accountActions.LOGIN_FAILURE, error));
			dispatch(failure(alertActions.ALERT_ERROR, error));
		});
	};
};

export const verifyResetPasswordToken = (token) => {
	const endpoint = `${appConstants.END_POINTS.authenticate.verifyResetToken}/${token}`;
	return (dispatch) => {
		dispatch(request(accountActions.GET_VERIFY_TOKEN_REQUEST));
		return service.getRequest(endpoint).then((response) => {
			dispatch(success(accountActions.GET_VERIFY_TOKEN_SUCCESS, response));
			if (response && !response.status) {
				dispatch(failure(alertActions.ALERT_ERROR, response.message));
				history.push({
					pathname: '/'
				});
			}
			return response;
		}, (error) => {
			dispatch(failure(accountActions.GET_VERIFY_TOKEN_FAILURE, error));
			dispatch(failure(alertActions.ALERT_ERROR, error));
		});
	};
};

export const resetPassword = (requestParams) => {
	return (dispatch) => {
		dispatch(request(accountActions.RESET_PASSWORD_REQUEST));
		return service.postRequest(appConstants.END_POINTS.authenticate.resetPassword, requestParams).then((response) => {
			dispatch(success(accountActions.RESET_PASSWORD_SUCCESS, response));
			if (response && response.status) {
				dispatch(success(alertActions.ALERT_SUCCESS, response.message));
				history.push({
					pathname: '/'
				});
			}
			else {
				dispatch(failure(alertActions.ALERT_ERROR, response.message));
			}
			return response;
		}, (error) => {
			dispatch(failure(accountActions.RESET_PASSWORD_FAILURE, error));
			dispatch(failure(alertActions.ALERT_ERROR, error));
		});
	};
};


export const forgotPassword = (requestParams) => {
	return (dispatch) => {
		dispatch(request(accountActions.GET_FORGOT_PASSWORD_REQUEST));
		return service.postRequest(appConstants.END_POINTS.authenticate.forgotPassword, requestParams).then((response) => {
			dispatch(success(accountActions.GET_FORGOT_PASSWORD_SUCCESS, response));
			if (response && response.status) {
				dispatch(success(alertActions.ALERT_SUCCESS, response.message));
				history.push({
					pathname: '/'
				});
			}
			else {
				dispatch(failure(alertActions.ALERT_ERROR, response.message));
			}
			return response;
		}, (error) => {
			dispatch(failure(accountActions.GET_FORGOT_PASSWORD_FAILURE, error));
			dispatch(failure(alertActions.ALERT_ERROR, error));
		});
	};
};


export const logout = () => {
	return (dispatch) => {
		removeCookie();
		localStorage.clear();
		history.push('/');
		dispatch(success(accountActions.LOGOUT));
	};
};

export const navigatePage = (navigationPath) => {
    return {
        type: accountActions.NAVIGATE,
        responseData: navigationPath
    };
};