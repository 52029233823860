import React from "react";
import PropTypes from "prop-types";
import NoResultImg from '../../assets/images/NoDataset.png';
import { Grid, Typography, withStyles } from '@material-ui/core';
import NoResultFoundStyles from './NoResultFoundStyles.jsx';

/**
 *NoResultFound component
 *
 * @class NoResultFound
 * @extends {React.Component}
 */
const NoResultFound = (props) => {
    const { classes, height, text } = props;
    const noResultFoundText = (text) ? text : "No Result Found";
    return (
        <Grid container alignItems="center" justifyContent="center" className={classes.noResultFound} style={{ height: height }}>
            <Grid>
                <img src={NoResultImg} alt="No Result Found" />
                <Typography variant="h4" align="center">
                    {noResultFoundText}
                </Typography>
            </Grid>
        </Grid>
    );
};

NoResultFound.defaultProps = {
    classeName: "noResultFound",
    height: '100%',
    text: "No Result Found",
    classes: {}
};

NoResultFound.propTypes = {
    height: PropTypes.string,
    classes: PropTypes.object,
    text: PropTypes.string
};

export default withStyles(NoResultFoundStyles)(NoResultFound);