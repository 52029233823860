import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Button, Typography, Divider, MenuItem } from '@material-ui/core';
import classnames from 'classnames';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Dropzone from 'react-dropzone';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
// import SettingComponentStyles from './SettingComponentStyles.jsx';
import Logo from '../../assets/images/headerLogo.svg';
import Banner from '../../assets/images/1_Login.png';

import { getTheme, updateTheme, uploadThemeFile } from '../../actions/settingActions';
import { useDispatch, useSelector } from 'react-redux';
import ColorPicker from '../ColorPicker/ColorPicker.jsx';
import { appConstants } from '../../constants/appConstants';
import Loader from '../Loaders/Loader.jsx';
import SettingStyles from '../../containers/Settings/SettingStyles.jsx';
// import { returnImage, getUserProperty, createElement, sortTable } from '../../helpers/appHelpers';
// import AlertDialog from '../../components/AlertDialog/AlertDialog.jsx';

const Theme = (props) => {
    const { classes, clientId, type } = props;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [anchoEl, setAnchorEl] = useState(null);
    const [colorType, setColorType] = useState('');
    const [logoLoading, setLogoLoading] = useState(false);
    const [bannerLoading, setBannerLoading] = useState(false);
    const defaultTheme = useSelector(({ setting }) => setting.defaultTheme);
    const [theme, setTheme] = useState({ ...defaultTheme });
    const [isLoading, setLoading] = useState(false);
    const [isPageLoading, setPageLoading] = useState(false);

    const getThemeData = useCallback(() => {
        setPageLoading(true);
        dispatch(getTheme(clientId)).then((response) => {
            setPageLoading(false);
            if (response && Object.keys(response).length) {
                setTheme({ ...response });
            }
        });
    }, [dispatch, clientId]);

    useEffect(() => {
        getThemeData();
    }, [getThemeData, clientId]);

    const openPicker = (type, event) => {
        setOpen(true);
        setColorType(type);
        setAnchorEl(event.target);
    };

    const closePicker = () => {
        setOpen(false);
    };

    const colorChange = (event) => {
        if (event.hex) {
            onChange(colorType, event.hex);
        }
    };

    const createElement = (href) => {
        const linkElement = document.createElement('link');
        linkElement.href = href;
        linkElement.type = 'text/css';
        linkElement.rel = 'stylesheet';
        return linkElement;
    };

    const onChange = (property, value) => {
        theme[property] = value;
        if (property === 'heading_font') {
            const titleFontFamily = appConstants.themeFonts.find((font) => font.fontName === theme.heading_font);
            const titleElement = document.querySelector(`link[href="${titleFontFamily.url}"]`);
            if (titleFontFamily && !titleFontFamily.isDefault && !titleElement) {
                const linkElement = createElement(titleFontFamily.url);
                document.querySelector('head').appendChild(linkElement);
            }
        } else if (property === 'text_font') {
            const bodyFontFamily = appConstants.themeFonts.find((font) => font.fontName === theme.text_font);
            const bodyLinkElement = document.querySelector(`link[href="${bodyFontFamily.url}"]`);
            if (bodyFontFamily && !bodyFontFamily.isDefault && !bodyLinkElement) {
                const linkElement = createElement(bodyFontFamily.url);
                document.querySelector('head').appendChild(linkElement);
            }
        }
        setTheme({ ...theme });
    };


    const resetThemeDialogBox = () => {
        setLoading(true);
        setTheme({ ...defaultTheme });
        dispatch(updateTheme(defaultTheme, clientId)).then(() => {
            setLoading(false);
        });
    };
    const updatedTheme = (themeData) => {
        setLoading(true);
        dispatch(updateTheme(themeData, clientId)).then(() => {
            setLoading(false);
        });
    };

    const saveTheme = () => {
        updatedTheme({ ...theme });
    };


    const uploadFile = (property, files) => {
        const file = new FormData();
        file.append('file', files[0]);
        file.append('type', property);
        if (property === "logo") {
            setLogoLoading(true);
        } else {
            setBannerLoading(true);
        }
        dispatch(uploadThemeFile(file)).then((response) => {
            theme[property] = response;
            setTheme({ ...theme });
            if (property === "logo") {
                setLogoLoading(false);
            } else {
                setBannerLoading(false);
            }
        });
    };

    const DropDownIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12px" height="12px" viewBox="0 0 451.847 451.847">
            <g>
                <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" fill="#969899" />
            </g>
        </svg>
    );

    return (
        <Grid className={type === "settingsPanel" ? classes.themeContainer : classes.themeContainer2}>
            <ValidatorForm form="theme" onSubmit={() => saveTheme()}>
                <Grid container className={classes.themeSection}>
                    <Grid item sm={6}>
                        <Grid container>
                            <Grid item sm={8}>
                                <Typography className={classes.themeTitle}>
                                    Select Logo
                                </Typography>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid className={classnames(classes.themeBanner, classes.squareLogo)} style={{ backgroundImage: `url(${theme.logo === "" ? Logo : theme.logo})` }}>
                                {logoLoading && <Loader />}
                                <Dropzone accept="image/*" onDrop={(acceptedFiles, rejectedFiles) => uploadFile('logo', acceptedFiles)}>
                                    {
                                        ({ getRootProps, getInputProps }) => (
                                            <div className={classes.themeupload} {...getRootProps()}>
                                                <input {...getInputProps()} />
                                            </div>
                                        )
                                    }
                                </Dropzone>
                                {/* <img src={Logo} alt="logo" className={classes.selectImg} /> */}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={6}>
                        <Grid container>
                            <Grid item sm={8}>
                                <Typography className={classes.themeTitle}>
                                    Select Banner
                                </Typography>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid className={classes.themeBanner} style={{ backgroundImage: `url(${theme.banner === "" ? Banner : theme.banner})` }}>
                                {bannerLoading && <Loader />}
                                <Dropzone accept="image/*" onDrop={(acceptedFiles, rejectedFiles) => uploadFile('banner', acceptedFiles)}>
                                    {
                                        ({ getRootProps, getInputProps }) => (
                                            <div className={classes.themeupload} {...getRootProps()}>
                                                <input {...getInputProps()} />
                                            </div>
                                        )
                                    }
                                </Dropzone>
                                {/* <img src={Banner} alt="logo" className={classes.selectImg} /> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.themeSection}>
                    <Grid item sm={12}>
                        <Typography className={classes.themeTitle}>
                            CopyRight Text
                        </Typography>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item sm={12}>
                        <Grid container>
                            <Grid item sm={6}>
                                <Grid container className={classes.marginTop20}>
                                    <Grid item sm={8}>
                                        <TextBox
                                            label="Login Copyright Text:"
                                            name="login_copyright_text"
                                            value={theme.login_copyright_text ? theme.login_copyright_text : ''}
                                            onChange={(event) => onChange(event.target.name, event.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={6}>
                                <Grid container className={classes.marginTop20}>
                                    <Grid item sm={8}>
                                        <TextBox
                                            label="Footer Copyright Text:"
                                            name="footer_copyright_text"
                                            value={theme.footer_copyright_text ? theme.footer_copyright_text : ''}
                                            onChange={(event) => onChange(event.target.name, event.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.themeSection}>
                    <Grid item sm={12}>
                        <Typography className={classes.themeTitle}>
                            Color Palette
                        </Typography>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item sm={12} className={classes.marginTop20}>
                        <Grid container>
                            <Grid item sm={9}>

                                <Grid container className={classes.marginBottom15}>
                                    <Grid sm={4}>
                                        <Typography className={classnames(classes.marginBottom15, classes.themeTitle)}>
                                            Primary Color
                                        </Typography>
                                        <Grid container alignItems="center" className={classes.marginBottom20}>
                                            <Grid onClick={(event) => openPicker("primary_color", event)} className={classes.themeSelectColor} style={{ backgroundColor: theme.primary_color }} />
                                            <Grid>
                                                <TextBox
                                                    name="primary_colorcode"
                                                    fullWidth
                                                    value={theme.primary_color ? theme.primary_color : ''}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid sm={4}>
                                        <Typography className={classnames(classes.marginBottom15, classes.themeTitle)}>
                                            Secondary Color
                                        </Typography>
                                        <Grid container alignItems="center" className={classes.marginBottom20}>
                                            <Grid onClick={(event) => openPicker("secondary_color", event)} className={classes.themeSelectColor} style={{ backgroundColor: theme.secondary_color }} />
                                            <Grid>
                                                <TextBox
                                                    name="secondary_colorcode"
                                                    fullWidth
                                                    value={theme.secondary_color ? theme.secondary_color : ''}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid sm={4}>
                                        <Typography className={classnames(classes.marginBottom15, classes.themeTitle)}>
                                            Tertiary Color
                                        </Typography>
                                        <Grid container alignItems="center" className={classes.marginBottom20}>
                                            <Grid onClick={(event) => openPicker("tertiary_color", event)} className={classes.themeSelectColor} style={{ backgroundColor: theme.tertiary_color }} />
                                            <Grid>
                                                <TextBox
                                                    name="tertiary_colorcode"
                                                    fullWidth
                                                    value={theme.tertiary_color ? theme.tertiary_color : ''}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.marginBottom15}>
                                    <Grid item sm={12}>
                                        <Typography className={classnames(classes.marginBottom15, classes.themeTitle)}>
                                            Shades
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Grid container alignItems="center" className={classes.marginBottom20}>
                                            <Grid onClick={(event) => openPicker("darkTxt", event)} className={classes.themeSelectColor} style={{ backgroundColor: theme.darkTxt }} />
                                            <Grid>
                                                <TextBox
                                                    name="primary_colorcode"
                                                    fullWidth
                                                    value={theme.darkTxt ? theme.darkTxt : ''}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Grid container alignItems="center" className={classes.marginBottom20}>
                                            <Grid onClick={(event) => openPicker("default", event)} className={classes.themeSelectColor} style={{ backgroundColor: theme.default }} />
                                            <Grid>
                                                <TextBox name="primary_colorcode"
                                                    fullWidth
                                                    value={theme.default ? theme.default : ''}
                                                    disabled />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Grid container alignItems="center" className={classes.marginBottom20}>
                                            <Grid onClick={(event) => openPicker("exteremeLight", event)} className={classes.themeSelectColor} style={{ backgroundColor: theme.exteremeLight }} />
                                            <Grid>
                                                <TextBox name="primary_colorcode"
                                                    fullWidth
                                                    value={theme.exteremeLight ? theme.exteremeLight : ''}
                                                    disabled />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Grid container alignItems="center" className={classes.marginBottom20}>
                                            <Grid onClick={(event) => openPicker("extraLight", event)} className={classes.themeSelectColor} style={{ backgroundColor: theme.extraLight }} />
                                            <Grid>
                                                <TextBox name="primary_colorcode"
                                                    fullWidth
                                                    value={theme.extraLight ? theme.extraLight : ''}
                                                    disabled />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Grid container alignItems="center" className={classes.marginBottom20}>
                                            <Grid onClick={(event) => openPicker("light", event)} className={classes.themeSelectColor} style={{ backgroundColor: theme.light }} />
                                            <Grid>
                                                <TextBox name="primary_colorcode"
                                                    fullWidth
                                                    value={theme.light ? theme.light : ''}
                                                    disabled />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Grid container alignItems="center" className={classes.marginBottom20}>
                                            <Grid onClick={(event) => openPicker("main", event)} className={classes.themeSelectColor} style={{ backgroundColor: theme.main }} />
                                            <Grid>
                                                <TextBox name="primary_colorcode"
                                                    fullWidth
                                                    value={theme.main ? theme.main : ''}
                                                    disabled />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.marginBottom15}>
                                    <Grid item sm={12}>
                                        <Typography className={classnames(classes.marginBottom15, classes.themeTitle)}>
                                            Choose Font-family
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Grid container className={classes.marginBottom20}>
                                            <Grid item sm={8}>
                                                <TextBox
                                                    label="Heading Font"
                                                    name="heading_font"
                                                    fullWidth
                                                    select
                                                    value={theme.heading_font ? theme.heading_font : ''}
                                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                                    SelectProps={
                                                        {
                                                            MenuProps: {
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "center"
                                                                },
                                                                transformOrigin: {
                                                                    vertical: "top",
                                                                    horizontal: "center"
                                                                },
                                                                getContentAnchorEl: null
                                                            },
                                                            IconComponent: () => DropDownIcon()
                                                        }
                                                    }
                                                >
                                                    {
                                                        appConstants.themeFonts.map((option, index) => (
                                                            <MenuItem key={`menuProperty_Options_${index}`} value={option.fontName} className={classes.menuItem}>
                                                                {option.fontName}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </TextBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Grid container className={classes.marginBottom20}>
                                            <Grid item sm={8}>
                                                <TextBox
                                                    label="Body Font"
                                                    name="text_font"
                                                    fullWidth
                                                    select
                                                    value={theme.text_font ? theme.text_font : ''}
                                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                                    SelectProps={
                                                        {
                                                            MenuProps: {
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "center"
                                                                },
                                                                transformOrigin: {
                                                                    vertical: "top",
                                                                    horizontal: "center"
                                                                },
                                                                getContentAnchorEl: null
                                                            },
                                                            IconComponent: () => DropDownIcon()
                                                        }
                                                    }
                                                >
                                                    {
                                                        appConstants.themeFonts.map((option, index) => (
                                                            <MenuItem key={`menuProperty_Options_${index}`} value={option.fontName} className={classes.menuItem}>
                                                                {option.fontName}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </TextBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.marginBottom15}>
                                    <Grid item xs={12}>
                                        <Button variant="contained" color="primary" type="submit" className={classnames(classes.actionButtons, classes.submitBtn)}>
                                            Apply
                                            {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                        </Button>
                                        <Button onClick={() => resetThemeDialogBox()} className={classnames(classes.cancelButton, classes.actionButtons)}>
                                            Reset to default theme
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                <ColorPicker open={open}
                    anchorElement={anchoEl}
                    onClose={() => closePicker()}
                    color={() => theme[colorType]}
                    changeColor={colorChange} />

            </ValidatorForm>
            {isPageLoading && <Loader />}
        </Grid>
    );
};

Theme.propTypes = {
    classes: PropTypes.object,
    clientId: PropTypes.object,
    isOrganization: PropTypes.bool,
    theme: PropTypes.object,
    type: PropTypes.string
};

export default withStyles((theme) => ({
    ...SettingStyles(theme),
    ...Styles(theme)
}))(Theme);