import React from 'react';
import { Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute.jsx';
import PrivateRoute from './PrivateRoute.jsx';
import ForgotPassword from '../containers/Account/ForgotPassword.jsx';
import ResetPassword from '../containers/Account/ResetPassword.jsx';


import Login from '../containers/Account/Login.jsx';
import License from '../containers/License/License.jsx';
import Package from '../containers/Package/Package.jsx';
import AddPackage from '../containers/AddPackage/AddPackage.jsx';
import Settings from '../containers/Settings/Settings.jsx';


import AddLicense from '../containers/AddLicense/AddLicense.jsx';
import EditLicense from '../containers/EditLicense/EditLicense.jsx';

const routes = () => {
	return (
		<Switch>
			<PublicRoute exact path="/" component={Login} />
			<PublicRoute exact path="/forgotpassword" component={ForgotPassword} />
			<PublicRoute exact path="/resetpassword/:token" component={ResetPassword} />
			<PrivateRoute exact path="/license" component={License} />
			<PrivateRoute exact path="/createlicense" component={AddLicense} />
			<PrivateRoute exact path="/package" component={Package} />
			<PrivateRoute exact path="/createpackage" component={AddPackage} />
			<PrivateRoute exact path="/editpackage/:package" component={AddPackage} />
			<PrivateRoute exact path="/editlicense/:license" component={EditLicense} />
			<PrivateRoute exact path="/settings" component={Settings} />

		</Switch>
	);
};

export default routes;