import { licenseActions } from '../constants/actionTypes/licenseActionTypes';

const initialState = {
	isLoading: false,
	inputConnection: [],
	outputConnection: [],
	licenses: [],
	connections: []
};

export const licenseReducer = (state = initialState, action) => {
	if (!action || (action && !action.type)) {
		return state;
	}
	switch (action.type) {
		case licenseActions.GET_LICENSE_REQUEST:
		case licenseActions.GET_LICENSE_FAILURE:
			return {
				...state,
				licenses: []
			};
		case licenseActions.GET_LICENSE_SUCCESS:
			return {
				...state,
				licenses: action.responseData ? action.responseData : []
			};
		case licenseActions.GET_CONNECTION_TYPES_REQUEST:
		case licenseActions.GET_CONNECTION_TYPES_FAILURE:
			return {
				...state,
				inputConnection: [],
				outputConnection: []
			};
		case licenseActions.GET_CONNECTION_TYPES_SUCCESS:
			const inputSource = action.responseData ? action.responseData.filter((connection) => connection.allow_input_storage ) : [];
			const outputSource = action.responseData ? action.responseData.filter((connection) => connection.allow_output_storage) : [];
			return {
				...state,
				inputConnection: [...inputSource],
				outputConnection: [...outputSource],
				connections: [...action.responseData]
			};
		case licenseActions.CREATE_LICENSE_REQUEST:
		case licenseActions.CREATE_LICENSE_SUCCESS:
		case licenseActions.CREATE_LICENSE_FAILURE:
			return {
				...state
			};
		case licenseActions.GET_DEACTIVE_LICENSE_REQUEST:
		case licenseActions.GET_DEACTIVE_LICENSE_SUCCESS:
		case licenseActions.GET_DEACTIVE_LICENSE_FAILURE:
		case licenseActions.GET_DEACTIVE_LICENSE_EMAIL_REQUEST:
		case licenseActions.GET_DEACTIVE_LICENSE_EMAIL_SUCCESS:
		case licenseActions.GET_DEACTIVE_LICENSE_EMAIL_FAILURE:
			return {
				...state
			};
		default:
			return state;
	}
};