import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, CircularProgress, withStyles } from '@material-ui/core';
import LoaderStyles from './LoaderStyles.jsx';

const Loader = (props) => {
    const { classes, classList, size, type } = props;

    const getSize = () => {
        let loaderSize = 40;
        if (size === 'large') {
            loaderSize = 40;
        }
        else if (size === 'medium') {
            loaderSize = 36;
        }
        if (size === 'small') {
            loaderSize = 24;
        }
        if (size === 'extraSmall') {
            loaderSize = 16;
        }
        return loaderSize;
    };

    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classNames(type === 'button' ? classes.buttonLoader : classes.loader, classList)}>
            <Grid item className={classes.loaderContainer}>
                <CircularProgress color="primary" disableShrink size={getSize(size)} />
            </Grid>
        </Grid>
    );
};

Loader.defaultProps = {
    size: 'large',
    type: 'default'
};

Loader.propTypes = {
    classes: PropTypes.object,
    classList: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string
};

export default withStyles((theme) => ({
    ...LoaderStyles(theme)
}))(Loader);