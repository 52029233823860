import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Snackbar, SnackbarContent, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// Import styles
import AlertStyles from './AlertStyles.jsx';

const Alert = (props) => {
	const { classes, autoHideDuration, type, message, onAlertClick, actions, ...other } = props;
	return (
		<Snackbar
			open={Boolean(message && message.length > 0)}
			anchorOrigin={
				{
					vertical: 'bottom',
					horizontal: 'left'
				}
			}
			autoHideDuration={3000}
			onClose={onAlertClick}>
			<SnackbarContent
				className={classes[type]}
				message={
					<Typography className={classes.message}>
						{message}
					</Typography>
				}
				action={
					actions ? actions : [
						<IconButton
							key="close"
							aria-label="Close"
							color="inherit"
							className={classes.close}
							onClick={onAlertClick}
						>
							<Close className={classes.icon} />
						</IconButton>
					]
				}
				{...other}
			/>
		</Snackbar>
	);
};

Alert.propTypes = {
	classes: PropTypes.object,
	autoHideDuration: PropTypes.number,
	type: PropTypes.string,
	message: PropTypes.string,
	actions: PropTypes.node,
	onAlertClick: PropTypes.func
};

export default withStyles(AlertStyles)(Alert);