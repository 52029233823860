const CommonStyles = (theme) => ({
    //Menu
    Menuicon: {
        paddingBottom: '18px',
        '& a': {
            cursor: "pointer"
        },
        '& a.active': {
            '& svg': {
                fill: theme.palette.primary.main
            },
            '& path': {
                fill: theme.palette.primary.main
            }
        }
    },
    MenuiconHelp: {
        position: 'absolute',
        bottom: '0px',
        left: '17px',
        paddingBottom: '10px',
        '& a': {
            cursor: "pointer"
        },
        '& a.active': {
            '& svg': {
                fill: theme.palette.primary.main
            },
            '& path': {
                fill: theme.palette.primary.main
            }
        }
    },
    MenuiconLast: {
        cursor: "pointer",
        '& a.active': {
            '& svg': {
                fill: theme.palette.primary.main
            },
            '& path': {
                fill: theme.palette.primary.main
            }
        }
    },
    HeaderMenuicon: {
        paddingBottom: '28px'
    },
    MenuiconImg: {
        width: '24px',
        '&:hover':
        {
            fill: theme.palette.primary.main
        },
        '&:hover path':
        {
            fill: theme.palette.primary.main
        },
        '&:hover ellipse':
        {
            color: theme.palette.primary.main
        },
        '&:hover rect': {
            fill: theme.palette.primary.main
        }
    },
    MenuiconUserImg: {
        width: '24px',
        '&:hover': {
            '& #fillPath, ellipse#Oval, path#Shape': {
                fill: theme.palette.primary.main
            }

        }
    },
    MenuIconActive: {
        '& #fillPath, ellipse#Oval, path#Shape': {
            fill: theme.palette.primary.main
        }
    },
    LeftMenu: {
        borderRight: `1px solid ${theme.palette.grey.extraLight}`
    },
    NotificationIcon: {
        color: '#afb2b3',
        '&:hover': {
            fill: theme.palette.primary.main
        }
    },
    NotificationBadge: {
        '& .MuiBadge-badge': {
            fontSize: '8px !important'
        }
    },
    logo: {
        width: 25,
        height: 25,
        cursor: 'pointer'
    }
});

export default CommonStyles;