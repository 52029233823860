const ClientListStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    clientSetting: {
        padding: 25,
        '& .MuiTableCell-root' : {
            padding: 10
        }
    },
    totalLicence: {
        textAlign: "right",
        padding: 10
    }
    
});

export default ClientListStyles;