import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Styles from '../../layouts/Styles.jsx';
import { withStyles, Popover, Box, Grid, Paper, List, ListItem, ListItemAvatar, Avatar, Typography } from '@material-ui/core';
import { getAuditLogs } from '../../actions/licenseActions';
import Loader from '../Loaders/Loader.jsx';
import { getRandomColor, getInitials } from '../../helpers/appHelpers';
import moment from 'moment-timezone';
import classnames from 'classnames';
import NoResultFound from '../../components/NoResultFound/NoResultFound.jsx';




const LicenceLog = (props) => {
    const { open, anchorEl, handleClose, classes, licenseId } = props;
    const [logs, setLogs] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const dispatch = useDispatch();

    const getLicense = useCallback(() => {
        dispatch(getAuditLogs(licenseId)).then((response) => {
            if (response) {
                setLogs([...response]);
            }
            setLoading(false);
        });
    }, [licenseId, dispatch]);

    useEffect(() => {
        getLicense();
    }, [getLicense]);

    const getAuditText = (log) => {
        let text = ""
        switch (log.log_type) {
            case 'properties':
                text = `has ${log.changed_value} the ${log.property}`;
                break;
            case 'license_activate':
                text = `has activated the license in ${log.property}`;
                break;
            case 'is_active':
                text = `changed the status in ${log.changed_value}`;
                break;
            case 'create_license':
                text = 'has created the license';
                break;
            case 'activate_license':
                text = `license activated in ${log.changed_value}`;
                break;
            case 'no_of_connectors':
                text = `has updated the connector count as ${log.changed_value}`;
                break;
            case 'inbound_connectors':
                text = `has updated the in bound connectors`;
                break;
            case 'outbound_connectors':
                text = `has updated the out bound connectors`;
                break;
            default:
                text = log.property;
                break;
        }
        return text;
    }

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={
                {
                    vertical: 30,
                    horizontal: 'left'
                }
            }
            transformOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }>
            <Box className={classes.clientSetting}>
                {logs.length ?
                    <Grid className={classes.licLogContainer}>
                        <List>
                            {
                                logs.map((log, index) =>
                                    <ListItem key={index} className={classes.list}>
                                        {
                                            (log.user_id) &&
                                            <ListItemAvatar>
                                                <Avatar
                                                    style={
                                                        {
                                                            backgroundImage: `url(${log.avatar})`,
                                                            backgroundColor: getRandomColor(log.first_name !== "" ? `${log.first_name} ${log.last_name}` : log.email)
                                                        }
                                                    }>
                                                    {
                                                        !log.avatar &&
                                                        <span>
                                                            {getInitials(log.first_name !== "" ? `${log.first_name} ${log.last_name}` : log.email)}
                                                        </span>
                                                    }
                                                </Avatar>
                                            </ListItemAvatar>
                                        }
                                        <Grid className={classes.logList}>
                                            <Paper className={classes.auditLogPaper}>
                                                {
                                                    (log.user_id) &&
                                                    <Typography component="span" className={classes.licLogUsername}>
                                                        {log.first_name !== "" ? `${log.first_name} ${log.last_name}` : log.email}
                                                    </Typography>
                                                }
                                                <Typography component="span" style={{ marginLeft: 10 }} className={classes.logTxt}>
                                                    {getAuditText(log)}
                                                </Typography>
                                            </Paper>
                                            <Typography className={classes.logTimeTxt}>
                                                {moment(log.modified_date).format('MM/DD/YYYY HH:mm')}
                                            </Typography>
                                        </Grid>
                                    </ListItem>
                                )
                            }
                        </List>
                    </Grid>
                    :
                    <Grid className={classnames(classes.licLogContainer, classes.noLogs)}>
                        <NoResultFound />
                    </Grid>
                }
                {isLoading && <Loader />}
            </Box>
        </Popover>
    )
}

export default withStyles((theme) => ({
    ...Styles(theme)
}))(LicenceLog);