import * as apihelper from '../helpers/apiHelpers';

export const getRequest = (url) => {
	return apihelper.getRequest(url).then((response) => {
		return response;
	});
};

export const postRequest = (url, requestParams, isFormData = false) => {
	return apihelper.postRequest(url, requestParams, isFormData).then((response) => {
		return response;
	});
};

export const putRequest = (url, requestParams, isFormData = false) => {
	return apihelper.putRequest(url, requestParams, isFormData).then((response) => {
		return response;
	});
};

export const deleteRequest = (url) => {
	return apihelper.deleteRequest(url).then((response) => {
		return response;
	});
};