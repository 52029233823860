import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import { licenseActions } from '../constants/actionTypes/licenseActionTypes';
import * as service from '../services/apiService';

export const getAllLicense = () => {
    const endpoint = `${appConstants.END_POINTS.license.getAllLicense}`;
    return (dispatch) => {
        dispatch(request(licenseActions.GET_LICENSE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(licenseActions.GET_LICENSE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(licenseActions.GET_LICENSE_FAILURE, error));
        });
    };
};

export const deactiveLicense = (id, status) => {
    const endpoint = `${appConstants.END_POINTS.license.deactiveLicense}/${id}/${status}`;
    return (dispatch) => {
        dispatch(request(licenseActions.GET_DEACTIVE_LICENSE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(licenseActions.GET_DEACTIVE_LICENSE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(licenseActions.GET_DEACTIVE_LICENSE_FAILURE, error));
        });
    };
};

export const deactiveEmailProcess = (id, status) => {
    const endpoint = `${appConstants.END_POINTS.license.deactiveEmail}/${id}/${status}`;
    return (dispatch) => {
        dispatch(request(licenseActions.GET_DEACTIVE_LICENSE_EMAIL_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(licenseActions.GET_DEACTIVE_LICENSE_EMAIL_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(licenseActions.GET_DEACTIVE_LICENSE_EMAIL_FAILURE, error));
        });
    };
};

export const createLicense = (requestParams) => {
    return (dispatch) => {
        dispatch(request(licenseActions.CREATE_LICENSE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.license.createLicense, requestParams).then((response) => {
            dispatch(success(licenseActions.CREATE_LICENSE_SUCCESS, response.data));
            // dispatch(success(alertActions.ALERT_SUCCESS, "Organization Created Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(licenseActions.CREATE_LICENSE_FAILURE, error));
            // dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getConnectionTypes = () => {
    return (dispatch) => {
        dispatch(request(licenseActions.GET_CONNECTION_TYPES_REQUEST));
        return service.getRequest(appConstants.END_POINTS.license.connectionType).then((response) => {
            dispatch(success(licenseActions.GET_CONNECTION_TYPES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(licenseActions.GET_CONNECTION_TYPES_FAILURE, error));
        });
    };
};



export const generateLicense = (requestParams) => {
    return (dispatch) => {
        dispatch(request(licenseActions.GENERATE_LICENSE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.license.generateLicense, requestParams).then((response) => {
            dispatch(success(licenseActions.GENERATE_LICENSE_SUCCESS, response.data));
            // dispatch(success(alertActions.ALERT_SUCCESS, "Organization Created Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(licenseActions.GENERATE_LICENSE_FAILURE, error));
            // dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getLicenseConfig = (licenseId) => {
    const endpoint = `${appConstants.END_POINTS.license.getLicenseConfig}/${licenseId}`
    return (dispatch) => {
        dispatch(request(licenseActions.GET_LICENSE_CONFIG_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(licenseActions.GET_LICENSE_CONFIG_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(licenseActions.GET_LICENSE_CONFIG_FAILURE, error));
        });
    };
};


export const updateLicense = (licenseId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.license.updateLicense}/${licenseId}`
    return (dispatch) => {
        dispatch(request(licenseActions.UPDATE_LICENSE_CONFIG_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(licenseActions.UPDATE_LICENSE_CONFIG_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(licenseActions.UPDATE_LICENSE_CONFIG_FAILURE, error));
        });
    };
};


export const getAuditLogs = (licenseId) => {
    const endpoint = `${appConstants.END_POINTS.license.getAuduitLog}/${licenseId}`
    return (dispatch) => {
        dispatch(request(licenseActions.GET_AUDIT_LOG_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(licenseActions.GET_AUDIT_LOG_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(licenseActions.GET_AUDIT_LOG_FAILURE, error));
        });
    };
};