import { packageActions } from '../constants/actionTypes/packageActionTypes';

const initialState = {
    modes: [],
    packages: []
};


export const packageReducer = (state = initialState, action) => {
    if (!action || (action && !action.type)) {
        return state;
    }
    switch (action.type) {
        case packageActions.CREATE_PACKAGE_REQUEST:
        case packageActions.CREATE_PACKAGE_FAILURE:
        case packageActions.CREATE_PACKAGE_SUCCESS:
            return {
                ...state
            };
        case packageActions.GET_ALL_PACKAGE_REQUEST:
        case packageActions.GET_ALL_PACKAGE_FAILURE:
            return {
                ...state
            };
        case packageActions.GET_ALL_PACKAGE_SUCCESS:
            return {
                ...state,
                packages: action.responseData ? action.responseData : []
            };
        case packageActions.GET_LICENSE_MODE_REQUEST:
        case packageActions.GET_LICENSE_MODE_FAILURE:
            return {
                ...state,
                modes: []
            };
        case packageActions.GET_LICENSE_MODE_SUCCESS:
            return {
                ...state,
                modes: action.responseData ? action.responseData : []
            };
        default:
            return state;
    }
};