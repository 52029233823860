import React from 'react';
import {
    Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import AlertDialogStyles from './AlertDialogStyles.jsx';
import Styles from '../../layouts/Styles.jsx';

const AlertDialog = (props) => {
    const { classes, title, message, show, onClickOK, onClickCancel, okButtonText, cancelButtonText } = props;
    return (
        <Dialog disableBackdropClick
            open={show}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.message}>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {
                    Boolean(cancelButtonText) &&
                    <Button color="primary"
                        variant="contained"
                        className={classes.cancelButton}
                        onClick={() => onClickCancel && onClickCancel()}
                        style={{ textTransform: 'uppercase', height: 32 }}>
                        {cancelButtonText ? cancelButtonText : 'Cancel'}
                    </Button>
                }
                {
                    Boolean(okButtonText) &&
                    <Button onClick={() => onClickOK && onClickOK()}
                        autoFocus
                        color="primary"
                        variant="contained"
                        style={{ textTransform: 'uppercase', height: 32 }}>
                        {okButtonText ? okButtonText : 'Okay'}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
};


AlertDialog.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    okButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    message: PropTypes.string,
    show: PropTypes.bool,
    onClickOK: PropTypes.func,
    onClickCancel: PropTypes.func
};

export default withStyles((theme) => ({
    ...AlertDialogStyles(theme),
    ...Styles(theme)
}))(AlertDialog);