import React, { useState } from 'react';
import { IconButton, Grid, withStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx'
import Loader from '../Loaders/Loader.jsx';
import fileDownload from 'js-file-download';
import { getPackageLicense } from '../../actions/packageActions';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const DownloadCSV = (props) => {
    const { classes, packageId, theme, liceseDetails } = props;
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const downloadCsv = (licenses) => {
        let csvData = [["License Package Name", "License Key", "License Type", "License Mode", "License Duration", "Level 1 Client", "Level 2 Client"]];
        for (const license of licenses) {
            const csvInfo = [license.package_name, license.license_key, license.license_type, license.license_mode, license.time_period, license.client_name, license.sub_client_name];
            csvData.push(csvInfo);
        }
        csvData = csvData.map(e => e.join(",")).join("\n")
        fileDownload(csvData, 'license.csv');
    }

    const loadCSVData = () => {
        setLoading(true);
        dispatch(getPackageLicense(packageId)).then((response) => {
            if (response) {
                downloadCsv([...response]);
            }
            setLoading(false);
        });

    };

    return (
        <Grid className={classes.relative}>
            {
                isLoading ?
                    <Loader size="small" type="button" /> :
                    <ToolTipComponent title="Download" placement="bottom" arrow>
                        <IconButton className={classes.nopadding} onClick={() => liceseDetails ? downloadCsv(liceseDetails) : loadCSVData()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 18.75 20">
                                <g id="download_4_" data-name="download (4)" transform="translate(-16)">
                                    <g id="Group_3242" data-name="Group 3242" transform="translate(20.375)">
                                        <g id="Group_3241" data-name="Group 3241">
                                            <path id="Path_2229" data-name="Path 2229" d="M137.942,9.116a.623.623,0,0,0-.569-.366h-2.5V.625A.625.625,0,0,0,134.248,0h-2.5a.625.625,0,0,0-.625.625V8.75h-2.5a.625.625,0,0,0-.47,1.036l4.375,5a.624.624,0,0,0,.94,0l4.375-5A.623.623,0,0,0,137.942,9.116Z" transform="translate(-127.998)" fill={theme.palette.primary.main} />
                                        </g>
                                    </g>
                                    <g id="Group_3244" data-name="Group 3244" transform="translate(16 13.75)">
                                        <g id="Group_3243" data-name="Group 3243" transform="translate(0)">
                                            <path id="Path_2230" data-name="Path 2230" d="M32.25,352v3.75H18.5V352H16v5a1.25,1.25,0,0,0,1.25,1.25H33.5A1.249,1.249,0,0,0,34.75,357v-5Z" transform="translate(-16 -352)" fill={theme.palette.primary.main} />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </IconButton>
                    </ToolTipComponent>
            }
        </Grid>
    );
};

DownloadCSV.propTypes = {
    theme: PropTypes.object,
    packageId: PropTypes.number,
    liceseDetails: PropTypes.array
};

export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(DownloadCSV);