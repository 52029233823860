
export const request = (type, params) => {
	return {
		type,
		params
	};
};

export const success = (type, responseData) => {
	return {
		type,
		responseData
	};
};

export const failure = (type, error) => {
	return {
		type,
		error
	};
};