import React from 'react';
import PropTypes from 'prop-types';
import './CheckboxStyles.css';

/**
 * Checkbox Component
 * @param {*} props
 */
const CheckboxComponent = (props) => {
    const {
        classes,
        className,
        disabled,
        onClick,
        checked,
        name,
        checkboxLabel,
        onChange,
        viewBox,
        snowFlakeConnectorCursor,
        showCheckBox
    } = props;
    const tickDirection = (viewBox) ? viewBox : "0 0 21 21";

    return (
        <label className= {disabled ? "disabled checkbox path " : "checkbox path"} htmlFor="checkboxCustom" >
            {(showCheckBox || typeof showCheckBox === 'undefined') && <input onClick={onClick} name={name} onChange={onChange} type="checkbox" classes={classes} className={className} disabled={disabled} checked={checked} />}
            <svg viewBox={tickDirection} >
                {(showCheckBox || typeof showCheckBox === 'undefined') && <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186" />}
            </svg>
            <span className={(snowFlakeConnectorCursor === "text") ? "snowFlakeConnectorCheckboxlabel" : "checkboxlabel"} id="checkboxCustom">
                {checkboxLabel}
            </span>
        </label>
    );
};

// default props
CheckboxComponent.defaultProps = {
    classes: {},
    className: "",
    checkboxLabel: "",
    disabled: false,
    checked: false,
    name: ""
};


// prop types
CheckboxComponent.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    name: PropTypes.string,
    checkboxLabel: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    snowFlakeConnectorCursor: PropTypes.string,
    viewBox: PropTypes.string,
    showCheckBox: PropTypes.bool
};

/** Export Component */
export default CheckboxComponent;