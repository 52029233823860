import React, { useEffect, useCallback, useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { getConnectionTypes } from '../../actions/licenseActions';
import CreatePackage from '../../components/CreatePackage/CreatePackage.jsx';
import Filter from '../../components/Filter/Filter.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Styles from '../../layouts/Styles.jsx';

const AddPackage = (props) => {

    const { match, history, classes } = props;
    const inputConnection = useSelector(({ license }) => license.inputConnection);
    const outputConnection = useSelector(({ license }) => license.outputConnection);
    const [breadcrumb] = useState([{ name: "Package", path: "/package" }, { name: match.params && match.params.package ? "Edit Package" : "Create Package", path: "" }]);
    const dispatch = useDispatch();

    const getConnection = useCallback(() => {
        dispatch(getConnectionTypes());
    }, [dispatch]);

    useEffect(() => {
        if (!outputConnection.length && !inputConnection.length) {
            getConnection();
        }
    }, [getConnection, inputConnection.length, outputConnection.length]);

    return (

        <Grid>
            <Breadcrumb history={history} breadcrumb={breadcrumb} />
            <Filter name={match.params && match.params.package ? "Edit Package" : "Create Package"} />
            <Grid className={classes.pageContainer}>
                <CreatePackage history={history} packageId={match.params && match.params.package ? match.params.package : null} />
            </Grid>
        </Grid>
    )
}



AddPackage.propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object
};

export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(AddPackage);