/* eslint-disable max-lines */
const Styles = (theme) => ({
    root: {
        height: '100%!important'
    },
    horzspace: {
        flexGrow: 1
    },
    marginTopm8: {
        marginTop: -8
    },
    marginTop10: {
        marginTop: 10
    },
    marginTop15: {
        marginTop: 15
    },
    marginTop20: {
        marginTop: '20px !important'
    },
    marginTop30: {
        marginTop: 30
    },
    marginBottom5: {
        marginBottom: 5
    },
    marginBottom30: {
        marginBottom: 30
    },
    marginBottom10: {
        marginBottom: 10
    },
    marginLeft15: {
        marginLeft: 15
    },
    marginLeft10: {
        marginLeft: 10
    },
    marginRight25: {
        marginRight: 25
    },
    marginRight5: {
        marginRight: 5
    },
    padding5: {
        padding: 5
    },
    paddingLR10: {
        paddingLeft: 10,
        paddingRight: 10
    },
    height32: {
        height: 32
    },
    inlineFlex: {
        display: 'inline-flex'
    },
    flex: {
        display: 'flex'
    },
    relative: {
        position: 'relative'
    },
    height100p: {
        height: '100%'
    },
    inputOutline: {
        padding: '0px',
        width: '100%',
        '& .MuiInput-underline:before': {
            border: `1px solid ${theme.palette.grey.light}`,
            height: '100%'
        },
        '& .MuiInput-underline:after': {
            display: 'none'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent'
        },
        '& .Mui-focused:before': {
            border: `1px solid ${theme.palette.primary.dark} !important`
        },
        '& .Mui-focused': {
            borderColor: 'transparent'
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `transparent !important`
        },
        '& input': {
            height: 40,
            padding: '0px 10px',
            background: theme.palette.common.white
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            border: `1px solid ${theme.palette.grey.dark}`
        },
        '& .Mui-error:before': {
            border: '1px solid #F44336 !important'
        }
    },
    block: {
        display: 'block'
    },
    nopadding: {
        padding: '0px !important'
    },
    rightComponent: {
        padding: '0px 25px',
        width: 'calc(100% - 50px)',
        // height: 'calc(100vh - 26px)',
        overflow: 'hidden'
    },
    inlineButton: {
        fontFamily: theme.palette.textFont,
        fontSize: 13,
        color: theme.palette.grey.darkTxt
    },
    container: {
        height: '100%',
        width: '100%'
    },
    datasourceSection: {
        paddingTop: 20,
        height: '100%'
    },
    breadcrumb: {
        color: theme.palette.grey.dark,
        fontSize: 13
    },
    card: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0px 0px 6px #e1e5e6',
        // borderRadius: 2,
        padding: 15,
        position: 'relative',
        flexGrow: 1,
        alignItems: "center",
        height: '100%'
    },
    inlinetxt: {
        "& div": {
            "&:before": {
                borderBottom: `1px solid transparent !important`
            }
        },
        "& div:hover": {
            "&:before": {
                borderBottom: `1px solid ${theme.palette.grey.dark} !important`
            }
        }
    },
    headerTitleTxt: {
        '& input': {
            fontSize: 20,
            color: theme.palette.grey.darkTxt,
            fontFamily: theme.palette.titleFont,
            fontWeight: 500,
            width: '230px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        fontSize: 20,
        color: theme.palette.grey.darkTxt,
        fontFamily: theme.palette.titleFont,
        fontWeight: 500,
        marginRight: 20
    },
    paddingLeft15: {
        paddingLeft: 15
    },
    disabledtxt: {
        color: `${theme.palette.grey.light} !important`
    },
    typeIcon: {
        width: 18,
        background: '#EDF2F4',
        marginRight: 10,
        color: '#5EB5EF',
        fontSize: 12,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 500
    },
    typeText: {
        fontSize: 13,
        minWidth: 80,
        textTransform: 'uppercase'
    },
    cancelButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.grey.light,
        borderRadius: 0,
        padding: '6px 18px',
        "&.MuiButton-root:hover": {
            backgroundColor: theme.palette.grey.light,
            opacity: 0.6
        }
    },
    backButton: {
        fontFamily: theme.palette.titleFont,
        fontSize: 15,
        fontWeight: 600,
        color: theme.palette.grey.darkGrey,
        textAlign: 'left',
        letterSpacing: 0,
        '& .MuiButton-label': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '& img': {
                marginRight: 5
            }
        },
        '&:hover': {
            '& .MuiButton-label': {
                color: theme.palette.primary.dark
            },
            '& .MuiButton-label:hover': {
                '& svg#backArrow': {
                    fill: `${theme.palette.primary.dark} !important`
                }
            }
        }
    },
    bodyContent: {
        height: '100%'
    },
    logout: {
        minWidth: 120,
        padding: '5px 10px'
    },

    logoutTxt: {
        color: theme.palette.grey.default
    },
    link: {
        color: theme.palette.grey.default,
        position: 'relative',
        marginRight: 5,
        '&:hover': {
            color: theme.palette.primary.dark,
            cursor: 'pointer',
            '&:hover:last-child': {
                color: `${theme.palette.grey.dark} !important`,
                cursor: 'default !important'
            }
        },
        '&:last-child': {
            color: `${theme.palette.grey.dark} !important`
        }
    },
    passwordAdornment: {
        color: theme.palette.grey.light,
        '&:hover': {
            color: 'rgba(0, 0, 0, 0.47)'
        }
    },
    marginBottom15: {
        marginBottom: 15
    },
    noPaddingLeft: {
        paddingLeft: '0px !important'
    },
    chipList: {
        position: 'relative',
        '& .chipremove': {
            display: 'none',
            position: 'absolute',
            top: 3,
            right: 7,
            borderRadius: 25,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.border.default}`
        },
        '& .inlineChip': {
            top: '-6px',
            left: 18
        },
        '&:hover .chipremove': {
            display: 'block'
        },
        '& .MuiTypography-body1': {
            fontSize: 13
        }
    },
    chipIcon: {
        fontSize: 12
    },
    buttonGroup: {
        '& .MuiButton-root': {
            border: `1px solid ${theme.palette.grey.light}`,
            backgroundColor: theme.palette.common.white,
            color: theme.palette.grey.main,
            fontSize: '13px !important',
            padding: 7,
            '&:hover': {
                boxShadow: 'none'
            },
            '&.active': {
                borderColor: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,

                '& svg>g>path, svg>g>circle, svg>g>ellipse': {
                    fill: theme.palette.common.white
                },

                '& svg>g>g': {
                    stroke: theme.palette.common.white
                }
            },
            '&:first-child': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
            },
            '&:last-child': {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
            }
        }
    },
    groupButton: {
        fontSize: '13px !important',
        '&:hover': {
            boxShadow: 'none'
        },
        '&.active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
        }
    },
    bgNone: {
        '&:hover': {
            backgroundColor: 'rgb(0 0 0 / 0%) !important',
            '& p': {
                color: theme.palette.primary.dark
            }
        },
        '& .MuiTouchRipple-root': {
            display: 'none !important'
        }
    },
    greyText: {
        "& div": {
            "&:before": {
                borderBottom: `1px solid ${theme.palette.grey.default} !important`
            }
        },
        "& div:hover": {
            "&:before": {
                borderBottom: `1px solid ${theme.palette.grey.default} !important`
            }
        },
        "& input": {
            fontSize: 13
        },
        '& .MuiSelect-selectMenu': {
            fontSize: 13
        }
    },
    moduleContainer: {
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        padding: '25px 25px 65px 25px',
        alignContent: "baseline"
    },
    moduleSection: {
        paddingTop: 25
    },
    moduleTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#222',
        display: 'inline-flex',
        marginRight: 15
    },
    formGroup: {
        padding: '10px 20px'
    },
    formLabelSection: {
        width: '19%',
        paddingBottom: 10,
        '& .MuiSwitch-sizeSmall': {
            marginRight: 4
        }
    },
    formLabel: {
        '& .MuiTypography-body1': {
            fontSize: 13,
            color: '#222'
        }
    },
    textBox: {
        width: '80px !important',
        '& .MuiInput-underline:before': {
            height: '25px !important '
        },
        '& input': {
            height: '25px !important '
        }
    },
    divider: {
        width: '100%',
        marginTop: 5,
        backgroundColor: theme.palette.border.default
    },
    subModuleSection: {
        backgroundColor: theme.palette.background.container,
        border: `1px solid ${theme.palette.background.container}`,
        padding: '15px 10px 15px 25px',
        '&:hover': {
            border: '1px solid rgba(26,54,126,0.125)'
        }
    },
    subDivider: {
        width: '100%',
        marginTop: 5,
        backgroundColor: theme.palette.border.dark
    },
    focusbg: {
        '& .Mui-focused': {
            background: "#ededed !important"
        },
        '&:hover': {
            background: "#ededed !important"
        }
    },
    inlineBlock: {
        display: "inline-block"
    },
    marginRight10: {
        marginRight: 10
    },
    align: {
        textAlign: 'center'
    },
    sidePanelHeader: {
        height: 50,
        padding: '0 15px',
        borderBottom: `1px solid ${theme.palette.grey.extraLight}`,
        marginBottom: 12
    },
    rightPanelActiveContainer: {
        maxWidth: '100%',
        flexBasis: '100%'
    },
    rightPanelContainer: {
        maxWidth: `calc(100% - 300px)`,
        flexBasis: `calc(100% - 300px)`
    },
    marginRight15: {
        marginRight: 15
    },
    paddingTop20: {
        paddingTop: 20
    },
    zIndex1: {
        zIndex: 1
    },
    noDataContainer: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    noDataImg: {
        minWidth: 200
    },
    cursor: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.secondary.main
        }
    },
    tooltip: {
        background: theme.palette.background.paper,
        border: `1px solid rgba(26,54,126,0.125)`,
        boxShadow: '0 4px 25px 0 rgba(0,0,0,.1)',
        padding: 5,
        fontSize: 12,
        '& span': {
            marginLeft: 5
        }
    },
    dqSearchBox: {
        transitionDuration: '500ms',
        transition: 'width .55s ease',
        animation: 'slide-left180 0.4s ease',
        width: '180px'
    },
    searchTextbox: {
        width: '180px !important',
        // marginTop: 8,
        '& .MuiInput-underline:before': {
            height: '30px !important'
        },
        '& input': {
            height: '30px !important',
            fontSize: 13
        }
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    cursorAuto: {
        cursor: 'auto'
    },
    iconButtonSmall: {
        width: 35,
        height: 35,
        padding: 0
    },
    boldText: {
        fontWeight: 500
    },
    noResultFound: {
        height: "50%",
        width: "auto",
        position: "absolute",
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        '& .MuiTypography-root': {
            color: 'gray',
            overflowY: 'hidden'
        }
    },
    moduleContent: {
        height: "calc(100vh - 270px)",
        overflowY: 'auto'
    },
    licenseContainer: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: 20
    },
    addIconButton: {
        padding: 5,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: `${theme.palette.grey.light} !important`
        }
    },
    listContainer: {
        height: '100%',
        position: 'relative',
        padding: '20px',
        backgroundColor: theme.palette.background.paper
    },
    mainContainer: {
        height: 'calc(100vh - 95px)',
        overflowY: 'auto'
    },
    mainInnerContainer: {
        height: 'calc(100vh - 215px)',
        overflowY: 'auto'
    },
    logList: {
        height: '100%',
        position: 'relative',
        padding: '0px 10px',
        backgroundColor: theme.palette.background.paper
    },
    pageContainer: {
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        padding: '25px',
        position: 'relative',
        height: 'calc(100vh - 130px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        alignContent: "baseline"
    },
    deleteSection: {
        position: 'fixed',
        right: 20,
        top: 40
    },
    select: {
        marginRight: 25,
        width: 100,
        '& .MuiFormControl-root': {
            width: 120
        },
        '& img': {
            width: 13,
            verticalAlign: 'middle',
            marginTop: 9,
            display: 'none'
        }
    },
    filterselect: {
        '& .MuiInputBase-input': {
            padding: '1px 0 7px',
            paddingRight: 20
        },
        '& .MuiFormLabel-root': {
            fontSize: '13px',
            color: 'rgba(0, 0, 0)'
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.grey.light}`
        }
    },
    errorTxt: {
        color: '#f44336'
    },
    tableHeader: {
        fontWeight: 500,
        fontSize: 14,
        fontFamily: theme.palette.titleFont
    },
    tableCell: {
        padding: '7px 16px',
        backgroundColor: theme.palette.background.paper
    },
    tabContainer: {
        backgroundColor: theme.palette.background.paper,
        marginTop: 20,
        boxShadow: "0px 0px 4px #CFE0E766",
        padding: 30,
        height: 'calc(100vh - 160px)',
        overflowY: 'auto',
        alignContent: "baseline"
    },
    alignIconCenter: {
        position: 'relative',
        top: -2,
        left: 2
    },
    licLogContainer: {
        minWidth: 500,
        minHeight: 'calc(100vh - 80px)',
        maxHeight: 'calc(100vh - 80px)',
        '& li' : {
            borderBottom: `1px solid ${theme.palette.border.default}`,
            padding: '12px 10px'
        }
    },
    licLogUsername: {
        backgroundColor: theme.palette.border.default,
        padding: '3px 5px',
        borderRadius: 3,
        fontWeight: 'bold'
    },
    logTimeTxt: {
        color: theme.palette.grey.default,
        fontStyle: 'italic',
        paddingTop: 5
    },
    noLogs: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    licenceTable: {
        marginTop: 20,
        '& .MuiTableCell-body' : {
            maxWidth: 300
        }
    },
    noHeight: {
        height: 'auto'
    }
});

export default Styles;