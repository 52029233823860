import React, { useState } from 'react';
import { Grid, Typography, Button, withStyles, CircularProgress, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import { authenticateLogin } from '../../actions/accountActions';
import TextBox from '../../components/TextBox/TextBox.jsx';
import Slider from '../../components/Slider/Slider.jsx';
import AccountStyles from './AccountStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import Logo from '../../assets/images/dq_logo.svg';

import { appConstants } from '../../constants/appConstants';
import { returnImage } from '../../helpers/appHelpers';


const Login = (props) => {

    const { classes, theme } = props;
    const [user, setUser] = useState({ "username": "", "password": "" });
    const [isLoading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();

    const handleChange = (property, value) => {
        user[property] = value;
        setUser({ ...user });
    };

    const handleSubmit = () => {
        setLoading(true);
        dispatch(authenticateLogin(user)).then(() => {
            setLoading(false);
        });
    };


    return (
        <Grid container className={classes.container}>
            <Grid item xs={8} className={classes.sliderContainer}>
                <Slider />
            </Grid>
            <Grid item xs={4} className={classes.container}>
                <Grid container direction="column" justifyContent="space-between" className={classes.section} wrap="nowrap">
                    <Grid item align="center">
                        <img src={theme.palette && theme.palette.logo !== "" ? returnImage(appConstants.API_BASE_URL, theme.palette.logo) : Logo} alt="Logo" className={classes.logo} />
                    </Grid>
                    <Grid item>
                        <ValidatorForm name="loginform" onSubmit={() => handleSubmit()}>
                            <Typography variant="h3" className={classes.accountTitleLogin}>
                                {'Login'}
                            </Typography>
                            <TextBox
                                placeholder={"Email"}
                                onChange={(event) => handleChange(event.target.name, event.target.value)}
                                name="username"
                                validators={['required', 'isEmail']}
                                errorMessages={['Email ID is required', 'Enter valid Email ID']}
                                id="username"
                                value={user.username ? user.username : ""}
                                margin="normal"
                                fullWidth
                                className={classNames(classes.inputOutline, classes.validationTextBox)}
                            />
                            <TextBox
                                placeholder={"Password"}
                                type={showPassword ? 'text' : 'password'}
                                onChange={(event) => handleChange(event.target.name, event.target.value)}
                                name="password"
                                validators={['required']}
                                errorMessages={['Password is required']}
                                id="password"
                                value={user.password ? user.password : ""}
                                fullWidth
                                className={classNames(classes.inputOutline, classes.validationTextBox)}
                                InputProps={
                                    {
                                        endAdornment:
                                            <InputAdornment position="end" >
                                                <IconButton className={classes.passwordAdornment} onClick={() => setShowPassword(!showPassword)}>
                                                    {showPassword ? <Visibility /> : <VisibilityOffOutlinedIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                    }
                                }
                            />
                            <Grid container direction="row" justifyContent="space-between" className={classes.btnContainer}>
                                <Grid item>
                                    <Button variant="contained"
                                        type="submit"
                                        size="large"
                                        color="primary"
                                        className={classes.loginbtn}
                                        disabled={isLoading}>

                                        {
                                            isLoading ?
                                                <CircularProgress className={classes.progress}
                                                    color="primary"
                                                    size={24}
                                                    thickness={4} /> : `Login`
                                        }
                                    </Button>
                                </Grid>
                                <Grid item align="right">
                                    <Button disabled={isLoading}
                                        className={classNames(classes.block, classes.nopadding, classes.inlineButton, classes.bgNone, classes.Linkhover)}
                                        onClick={() => props.history.push('/forgotpassword')}>
                                        {'Forgot Password'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                    <Grid item align="center">
                        <Typography variant="body2" className={classes.copyrightText}>
                            {theme.palette.loginCopyrightText}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

Login.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...AccountStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Login);