/*
 * import welcomeFolders from '../../assets/images/welcomeFolders.svg';
 * import welcomeDatabase from '../../assets/images/welcomeDatabase.svg';
 * import welcomeClean from '../../assets/images/welcomeClean.svg';
 * import welcomeAnalytics from '../../assets/images/welcomeAnalytics.svg';
 */

import DataCatalog from '../../assets/images/DataCatalog.svg';
import DataQuality from '../../assets/images/DataQuality.svg';
import DataGovernance from '../../assets/images/DataGovernance.svg';
import MasterData from '../../assets/images/MasterData.svg';
import DataIntegration from '../../assets/images/DataIntegration.svg';
import DataCuration from '../../assets/images/DataCuration.svg';


const localization = {
	login: 'Login',
	forgot: 'Forgot Password',
	sso: 'Signin SSO',
	email: 'Email',
	password: 'Password',
	submit: 'Submit',
	loginTxt: 'Already have an account?',
	description: 'Description',
	datasetTabHeaders: ['Dataset', 'Total Attributes', 'Total Records', 'Updated Time', 'Load Type', 'DQ Score', 'Impact Score', 'Actions'],
	datasetTabAttributeHeaders: ['Attribute', 'Business Name', 'Description', 'Data Type', 'DQ Score', 'Impact Score'],
	singup: 'Signup',
	errors: {

	},
	welcomeTiles: [
		{
			title: 'Data Integration ',
			description: 'Connect to unlimited amount of data sources in all shapes and form and any location. ',
			icon: DataIntegration
		}, {
			title: 'Data Catalog',
			description: 'Connect to unlimited Leverage ML-augmented data catalog as a simplified metadata management to automate the process of discovering, inventorying, profiling and tagging. ',
			icon: DataCatalog
		}, {
			title: 'Data Quality',
			description: 'Ability to score by discovering patterns, insights, fraud, missing values and correlations across attributes using all connected datasets and data sources within fraction of minutes',
			icon: DataQuality
		}, {
			title: 'Data Curation',
			description: 'ML based smart curation modules to automatically curate the data at three levels – Basic, Reference and Advanced along with Reinforcement Learning.',
			icon: DataCuration
		}, {
			title: 'Master Data Management',
			description: 'Easy entity definition and creation of Master data or  golden key for customers, products, devices, and other business entities.',
			icon: MasterData
		}, {
			title: 'Data Governance',
			description: 'Connect to unlimited Leverage ML-augmented data catalog as a simplified metadata management to automate the process of discovering, inventorying, profiling and tagging. ',
			icon: DataGovernance
		}
	],
	properties: {
		property: {
			businessName: 'Shortest possible clear definition of a field that clearly defines the business context. ',
			description: 'Detailed information about the field that shall help other users of the platform understand its purpose, use, business context. ',
			sensitivity: 'Level that defines the degree of sensitivity related to indicate whether an asset contains personally identifiable information. Level 3 is Restricted, Level 2 is Private, Level 1 is Public.',
			nullblank: 'Metadata definition for field level profiling to decide whether it can be Nullable or Blank or Unique',
			master: 'ON if it’s a master data attribute as defined in a MDM model. Details of the related MDM model and its relevant attribute should be defined if selected ON. ',
			primary: 'The primary key is a dataset attribute , or combination of columns, to uniquely identify dataset records',
			report: 'Set to Dimension / Measure depending on the type of field. Measure is a field that is a dependent variable; that is, its value is a function of one or more dimensions while Dimension is a field that can be considered an independent variable',
			tag: 'A tag is a piece of metadata that is used to label a data asset to help group or categorize together. You can use the same tag for all the assets that belong to the same category of a business, for example, Finance.',
			fieldType: 'Automatically identifies data structures within the data and classified to the semantic or business context of a particular field.',
			dataType: 'Structural type definition from a techincal view.'
		},
		value: {
			pattern: 'Expressions to create more flexible definitions for things like view filters, goals, segments, audiences, content groups, and channel groupings so as to use in profiling data quality.',
			length: 'Please specify the Minimun and Maximum Length for Data Quality Profiling if not identified automatically by the platform. ',
			range: 'Please specify the minimum and maximum range for Data Quality Profiling if not identified automatically by the platform. ',
			enum: 'Please specify the valid values for  Data Quality Profiling if not identified automatically by the platform. '
		},
		rule: {
			rule: 'Validation rules to assess the data quality of a field and they allow you to verify whether assets meet certain criteria. The rules editor allows you to define groups of rules all under one or many.'
		},
		curate: {
			basic: 'Simple rules that may involve formatting, normalization for data preparation',
			reference: 'Validate against a reference dataset to check valid/invalid and further curate the data to known valid values where possible.',
			lookup: 'Define the field that needs to be validated against. ',
			resultant: 'Define the output field value if the match happens against the Lookup attribute value.',
			advance: 'Advanced Algorithm application for data preparation. This uses a variety of statistical models, Deep Learning and ML models depending upon the data and field type.'
		},
		learning: {
			learning: 'A preview of simple known values, patterns, behaviors that shall be learnt overtime by user interaction with various data assets. This shall be used interally by the platform to understand more about the unqiue data culture that shall be specific to individual organization. Not all learnings shall be shown here as some are inbuilt within the internal assets of the model.',
			accept: 'User accepted valid values, patterns, behaviors, or asset defintions. ',
			decline: 'User rejected or negated valid values, patterns, behaviors, or asset defintions. '
		}
	}
};

export default localization;