import palette from './palette';

const overrides = {
    MuiCssBaseline: {
        "@global": {
            body: {
                height: '100%',
                fontFamily: palette.textFont,
                fontSize: 14,
                backgroundColor: `${palette.background.default} !important`
            },
            html: {
                height: '100%'
            },
            "div, tbody": {
                // scrollbarColor: `#9EAEB4 ${palette.background.default}`,
                scrollbarWidth: 'thin',
                "&::-webkit-scrollbar": {
                    height: '5px',
                    backgroundColor: palette.background.default,
                    width: '5px',
                    zIndex: 9999
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: '#9EAEB4',
                    borderRadius: '4px',
                    zIndex: 9999
                }
            },
            ul: {
                scrollbarColor: `#9EAEB4 ${palette.background.default}`,
                scrollbarWidth: 'thin',
                "&::-webkit-scrollbar": {
                    height: '8px',
                    backgroundColor: palette.background.default,
                    width: '8px',
                    zIndex: 9999
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: '#9EAEB4',
                    borderRadius: '4px',
                    zIndex: 9999
                }
            }
        }
    },
    MuiButton: {
        root: {
            textTransform: 'capitalize',
            fontSize: '14px',
            fontFamily: palette.titleFont,
            letterSpacing: 0.32
        },
        contained: {
            border: '0px',
            borderRadius: '0px',
            boxShadow: 'none',
            fontSize: '14px !important',
            fontFamily: palette.titleFont,
            textTransform: 'inherit',
            margin: '0px',
            fontWeight: 500,
            minWidth: '90px',
            backgroundColor: palette.background.default
        },
        containedSizeLarge: {
            padding: '5px 26px'
        }
    },
    MuiFormLabel: {
        root: {
            fontSize: '14px'
        }
    },
    MuiInput: {
        underline: {
            "&:before": {
                borderBottom: `1px solid ${palette.grey.extraLight}`
            },
            "&:after": {
                borderBottom: `2px solid ${palette.primary.dary}`
            },
            '&:hover:not(.Mui-disabled):before': {
                borderBottom: `1px solid ${palette.grey.dark}`
            },
            '& .Mui-disabled:before': {
                borderBottomStyle: 'solid'
            }
        }
    },
    MuiInputBase: {
        root: {
            fontSize: '13px',
            letterSpacing: 0.22,
            '&:hover:not(.Mui-disabled)::before': {
                borderBottom: `1px solid ${palette.grey.dark}`
            },
            '&:after': {
                borderBottom: `1px solid ${palette.primary.dary}`
            }
        }
    },
    MuiInputLabel: {
        shrink: {
            transform: 'translate(0, 1.5px) scale(0.9)'
        }
    },
    MuiTextField: {
        root: {
            '& label.Mui-focused': {
                color: `${palette.primary.dary} !important`
            }
        }
    },
    MuiDialogContent: {
        root: {
            padding: 0
            // paddingBottom: 20
        }
    },
    MuiDialogTitle: {
        root: {
            // padding: '20px 20px 5px 20px'
            paddingBottom: 5,
            padding: 0
        }
    },
    MuiDialog: {
        paper: {
            padding: 20
        },
        root: {
            '& .MuiDialogActions-root': {
                padding: 0
            }
        }
    },

    MuiPaper: {
        root: {
            '&::-webkit-scrollbar': {
                width: "6px !important"
            },
            '& .MuiList-padding': {
                paddingTop: 0,
                paddingBottom: 0
            }
        },
        elevation4: {
            boxShadow: 'none',
            borderBottom: `1px solid ${palette.border.default}`
        },
        elevation1: {
            boxShadow: 'none'
        },
        rounded: {
            borderRadius: '0px'
        },
        elevation3: {
            boxShadow: '0 4px 25px 0 rgba(0,0,0,.1) !important',
            border: `1px solid ${palette.border.default} !important`
        }
    },
    MuiAppBar: {
        root: {
            width: 50
        }
    },
    MuiToolbar: {
        regular: {
            height: 'calc(100vh - 1px)'
        },
        gutters: {
            padding: '10px !important'
        }
    },
    MuiFormHelperText: {
        root: {
            fontSize: 13,
            fontFamily: palette.textFont
        }
    },
    MuiAvatar: {
        root: {
            width: 26,
            height: 26,
            fontSize: 11,
            color: '#fff !important',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }
    },
    MuiTabs: {
        root: {
            minHeight: 36
        }
    },
    MuiTab: {
        root: {
            fontSize: 14,
            textTransform: 'capitalize',
            fontFamily: palette.textFont,
            minHeight: 36,
            color: palette.grey.darkTxt
        },
        textColorSecondary: {
            color: palette.grey.darkTxt
        }
    },
    MuiBox: {
        root: {
            padding: 0,
            height: '100%'
        }
    },
    MuiListItemAvatar: {
        root: {
            minWidth: 'auto'
        }
    },
    MuiListItem: {
        gutters: {
            paddingLeft: 15,
            paddingRight: 15
        }
    },
    MuiTable: {
        root: {
            paddingBottom: 2
        }
    },
    MuiTableCell: {
        root: {
            textAlign: 'left',
            padding: '2px 16px',
            minWidth: 60,
            maxWidth: 200,
            borderBottom: `1px solid ${palette.grey.extraLight}`
        },
        head: {
            fontFamily: palette.textFont,
            fontSize: 16,
            fontWeight: 600,
            color: palette.grey.darkTxt,
            padding: '0px 16px 10px 16px'
        },
        body: {
            fontFamily: palette.textFont,
            fontSize: 14,
            color: palette.grey.dark
        },
        stickyHeader: {
            padding: '10px 16px !important'
        }
    },
    MuiChip: {
        root: {
            height: 24,
            fontSize: 12,
            backgroundColor: palette.border.default,
            border: `1px solid ${palette.grey.extraLight}`,
            marginRight: 5,
            marginBottom: 5
        },
        deleteIcon: {
            width: 12,
            height: 12
        },
        avatar: {
            marginLeft: '0px !important'
        }
    },

    MuiLinearProgress: {
        root: {
            height: 14
        },
        colorPrimary: {
            backgroundColor: '#F6F8F9'
        }
    },
    MuiAccordionSummary: {
        root: {
            padding: 0
        },
        rounded: {
            borderRadius: '0px !important'
        }
    },
    MuiMenuItem: {
        root: {
            minHeight: '10px'
        }
    },
    MuiBackdrop: {
        root: {
            backgroundColor: palette.transparent
        }
    },
    MuiSwitch: {
        root: {
            height: 35,
            width: 56,
            marginLeft: -12
        },
        thumb: {
            height: 16,
            width: 16
        },
        switchBase: {
            left: 2
        },
        colorPrimary: {
            '&.Mui-checked': {
                left: 0
            }
        }
    },
    MuiSlider: {
        root: {
            marginLeft: 6
        },
        rail: {
            height: 4,
            backgroundColor: '#edf2f4'
        },
        track: {
            height: 4
        },
        thumb: {
            marginTop: -4,
            '&:hover': {
                boxShadow: '0px 0px 0px 6px rgba(243, 128, 128, 0.16) !important'
            },
            '&.MuiSlider-active': {
                boxShadow: '0px 0px 0px 8px rgba(243, 128, 128, 0.16) !important'
            }
        },
        mark: {
            width: 1,
            height: 10,
            top: '38%',
            backgroundColor: '#CFE0E7'
        }
    },
    MuiCardContent: {
        root: {
            padding: 0
        }
    },
    MuiCheckbox: {
        root: {
            color: 'rgb(0 0 0 / 32%)'
        }
    },
    MuiSvgIcon: {
        root: {
            width: '20px',
            height: '20px',
            "&.iconactive": {
                fill: palette.secondary.main
            },
            "&.icon": {
                fill: palette.border.normal
            }
        }
    }
};

export default overrides;