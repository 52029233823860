const createPackageStyles = (theme) => ({
    fieldTitleTxt: {
        fontSize: 13
    },
    licenseLog: {
        paddingRight: 20,
        marginTop: 20
    }
});

export default createPackageStyles;