import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Grid, withStyles } from '@material-ui/core';

import Header from '../components/Common/Header.jsx';
import Styles from './Styles.jsx';
import { logout } from '../actions/accountActions';


const PrivateLayout = (props) => {
	const { classes, history } = props;
	return (
		<Fragment>
			<Grid container direction="row">
				<Grid item>
					<Header logout={props.logout} history={history} />
				</Grid>
				<Grid item className={`${"pr-0"} ${classnames(classes.rightComponent, classes.zIndex1)}`}>
					<Grid container className={classes.bodyContent}>
						
						<Grid item className={classnames(classes.rightPanelActiveContainer, classes.bodyContent)}>
							{props.children}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Fragment>
	);
};


PrivateLayout.propTypes = {
	classes: PropTypes.object,
	location: PropTypes.object,
	children: PropTypes.node,
	logout: PropTypes.func,
	history: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		...state
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		logout
	}, dispatch);
};

export default
	connect(mapStateToProps,
		mapDispatchToProps)(withStyles(Styles)(PrivateLayout));