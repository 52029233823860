import React, { useEffect, useCallback, useState } from 'react';
import { Grid, Button, withStyles, MenuItem, FormHelperText } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Filter from '../../components/Filter/Filter'
import TextBox from '../../components/TextBox/TextBox.jsx';
import Loader from '../../components/Loaders/Loader.jsx';
import Typography from '@material-ui/core/Typography';
import { getActiveClients } from '../../actions/settingActions';
import { generateLicense } from '../../actions/licenseActions';
import { getAllPackage } from '../../actions/packageActions';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb.jsx';
import CreatePackageStyles from '../../components/CreatePackage/CreatePackageStyles.jsx';

const AddLicense = (props) => {
    const dispatch = useDispatch();
    const { classes, history } = props;
    const clientList = useSelector(({ setting }) => setting.clinets);
    const [license, setLicense] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [packages, setPackages] = useState([]);
    const [clients, setClients] = useState([...clientList]);
    const [error, setError] = useState(false);
    const [breadcrumb] = useState([{ name: "License", path: "/license" }, { name: "Create License", path: "" }])

    const onChange = (property, value) => {
        const regex = /^[0-9]*$/;
        if (property === "license_count" && !regex.test(value)) {
            return false;
        }
        if (property === "package_id" || property === "client_1") {
            setError(false);
        }
        license[property] = value;
        setLicense({ ...license });
    };

    const getPackages = useCallback(() => {
        dispatch(getAllPackage()).then((response) => {
            if (response) {
                response = response.filter((data) => data.is_active);
                setPackages([...response]);
            }
        })
    }, [dispatch]);

    const getClients = useCallback(() => {
        dispatch(getActiveClients()).then((response) => {
            if (response) {
                setClients([...response]);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getPackages();
        if (clientList.length === 0) {
            getClients();
        }
    }, [clientList.length, getClients, getPackages]);

    const onCreateLicense = () => {
        if (license.package_id !== "" && (license.client_1 && license.client_1 !=="")) {
            setLoading(true)
            dispatch(generateLicense(license)).then(() => {
                setLoading(false)
                history.push('/license')
            });
        } else {
            setError(true);
        }
    };

    const onClear = () => {
        setLicense({});
    };


    return (
        <Grid className={classes.container}>
            <Breadcrumb history={history} breadcrumb={breadcrumb} />
            <Filter name="Create License" />
            <Grid className={classNames(classes.marginTop20, classes.licenseContainer)}>
                <ValidatorForm className={classes.container} form="Add License" onSubmit={() => onCreateLicense()}>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>Package</Typography>
                            <TextBox
                                name="package_id"
                                value={license.package_id ? license.package_id : ""}
                                fullWidth
                                validators={['required']}
                                errorMessages={['Package is required']}
                                select
                                SelectProps={
                                    {
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center"
                                            },
                                            getContentAnchorEl: null
                                        }
                                    }
                                }
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                            >
                                {
                                    packages.map((packgeInfo, index) =>
                                        <MenuItem key={index} value={packgeInfo.id}>
                                            {packgeInfo.name}
                                        </MenuItem>
                                    )
                                }
                            </TextBox>
                            {error && license.package_id === "" && <FormHelperText className={classes.errorTxt}>Package is required</FormHelperText>}
                        </Grid>
                        <Grid item xs={4}>
                            <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>Level 1 Client</Typography>
                            <TextBox
                                name="client_1"
                                value={license.client_1 ? license.client_1 : ''}
                                select
                                fullWidth
                                SelectProps={
                                    {
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center"
                                            },
                                            getContentAnchorEl: null
                                        }
                                    }
                                }
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                            >
                                {
                                    clients.map((client, index) =>
                                        <MenuItem key={index} value={client.id}>
                                            {client.name}
                                        </MenuItem>
                                    )
                                }
                            </TextBox>
                            {error && (license.client_1 === "" || !license.client_1) && <FormHelperText className={classes.errorTxt}>Client is required</FormHelperText>}
                        </Grid>
                        <Grid item xs={4}>
                            <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>Level 2 Client</Typography>
                            <TextBox
                                name="client_2"
                                value={license.client_2 ? license.client_2 : ''}
                                select
                                fullWidth
                                SelectProps={
                                    {
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center"
                                            },
                                            getContentAnchorEl: null
                                        }
                                    }
                                }
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                            >
                                {
                                    clients.map((client, index) =>
                                        <MenuItem key={index} value={client.id}>
                                            {client.name}
                                        </MenuItem>
                                    )
                                }
                            </TextBox>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>License Count</Typography>
                            <TextBox
                                placeholder="No Of License Count"
                                name="license_count"
                                value={license.license_count ? license.license_count : ""}
                                validators={['required', 'minNumber:1', 'maxNumber:1000']}
                                errorMessages={['License Count is required', 'Count Minimum 1 is required', 'Count Below 1000 will accepted']}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.marginTop20}>
                            <Button disabled={isLoading} variant="contained" color="primary" type="submit" className={classes.submitBtn}>
                                Save
                                {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                            </Button>

                            <Button className={classes.cancelButton}
                                style={{ marginLeft: 20 }}
                                disabled={isLoading}
                                onClick={() => onClear()}>
                                {'Clear'}
                            </Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </Grid>
    )

};

export default withStyles((theme) => ({
    ...CreatePackageStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(AddLicense);