export const setCookie = (cookieName, value) => {
	const now = new Date();
	now.setTime(now.getTime() + 8 * 3600 * 1000);
	document.cookie = `${cookieName}=${value}; expires=${now.toUTCString()}; path=/`;
};

export const getCookie = (cookieName) => {
	const cookie = `${cookieName}=`;
	const decodeCookie = decodeURIComponent(document.cookie);
	const cookieData = decodeCookie.split(';');
	for (let i = 0; i < cookieData.length; i++) {
		const c = cookieData[i].trim();
		if (c.indexOf(cookie) === 0) {
			return c.substring(cookie.length, c.length);
		}
	}
	return "";
};

export const removeCookie = () => {
	document.cookie.split(";").forEach((c) => { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
};

export const returnImage = (baseUrl, value) => {
	return value;
};


const descendingComparator = (a, b, orderBy) => {
	if (typeof (a) !== 'object' && typeof (b) !== 'object') {
		if (b < a) {
			return -1;
		}
		if (b > a) {
			return 1;
		}
	} else {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
	}
	return 0;
};

const getTableSortComparator = (order, orderBy) => {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
};

export const sortTable = (array, order, orderBy) => {
	const sortedArray = array.map((el, index) => [el, index]);
	if (!orderBy) {
		return array;
	}
	const comparator = getTableSortComparator(order, orderBy);
	sortedArray.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return sortedArray.map((el) => el[0]);
};


export const getRandomColor = (text) => {
	const colors = ["#FF685C", "#E8DAB2", "#C0D6DF", "#77BA99", "#F68E5F", "#F7C59F", "#B5D99C", "#A8E6DF", "#798E56", "#F6867F", "#464C8B", "#EA8CC0", "#FFE08E", "#C1AA5D", "#BAC7B1", "#75A2C4", "#6A7890", "#C897A2", "#D1C6B1", "#EFEFD0", "#B5D99C", "#586BA4", "#F5DD90", "#D7C0D0"];
	text = text ? text : '';
	const charCodes = text
		.split('')
		.map((char) => char.charCodeAt(0))
		.join('');
	const codes = parseInt(charCodes, 10);
	const colorCode = colors[codes % colors.length];
	return colorCode;
};

export const getInitials = (name) => {
	name = name ? name : '';
	let user = '';
	if (name.includes('@')) {
		user = name.substring(0, 2).toUpperCase();
	} else {
		user = name.match(/\b(\w)/g);
		if (user) {
			user = user[0] + user[user.length - 1];
			user = user.toUpperCase();
		}
	}
	return user;
};
