import React, { useState, useEffect, useCallback } from 'react';
import { Grid, withStyles, Button, Typography, TextField, FormLabel, FormHelperText } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import classnames from 'classnames';
import ClientStyles from './ClientStyles.jsx';
import { createClient, getClient, updateClient } from '../../actions/settingActions';
import { useDispatch, useSelector } from 'react-redux';
import { Country, State, City } from 'country-state-city';
import Loader from '../Loaders/Loader.jsx';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';

const ClientDetail = (props) => {
    const { classes, clientId, onChangeSetting } = props;
    const [client, setClient] = useState({});
    const [defaultClient, setDefaultClient] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [isPageLoading, setPageLoading] = useState(false);
    const [error, setError] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const dispatch = useDispatch();
    const clientList = useSelector(({ setting }) => setting.clientNames);
    // eslint-disable-next-line no-useless-escape
    const websiteRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;


    const getClientById = useCallback(() => {
        setPageLoading(true);
        dispatch(getClient(clientId)).then((response) => {
            if (response) {
                setClient({ ...response });
                setDefaultClient({ ...response });
            }
            setPageLoading(false);
        });
    }, [clientId, dispatch]);


    useEffect(() => {
        if (clientId) {
            getClientById();
        }
    }, [clientId, getClientById]);



    const onChange = (name, value) => {
        const regex = /^[0-9+-]*$/;
        if ((name === "phone_number" || name === "zip_code") && !regex.test(value)) {
            return false;
        }
        client[name] = value;
        setClient({ ...client });
    };

    const onSubmit = () => {
        setFormSubmit(true);
        if (!error && client.country !== "" && client.state !== "" && client.city !== "" && websiteRegex.test(client.website)) {
            setLoading(true);
            dispatch(clientId ? updateClient(clientId, { ...client }) : createClient({ ...client })).then((response) => {
                setLoading(false);
                if (response) {
                    onChangeSetting("back", "");
                }
            });
        }
    };

    const onClear = () => {
        setClient({});
    };

    const onReset = () => {
        setClient({ ...defaultClient });
    };

    const validateDuplicateName = (value) => {
        if (defaultClient.name === client.name) {
            return false;
        }
        const duplicate = clientList.filter((data) => data.toLowerCase() === value.toLowerCase());
        setError(duplicate.length !== 0);
    };

    const getStates = () => {
        let states = [];
        if (client.country) {
            const countryCode = Country.getAllCountries().find((data) => data.name === client.country);
            if (countryCode) {
                states = State.getStatesOfCountry(countryCode.isoCode);
            }
        }
        return states ? states : [];
    };

    const getCities = () => {
        let cities = [];
        if (client.state && client.country) {
            const countryCode = Country.getAllCountries().find((data) => data.name === client.country);
            if (countryCode) {
                const states = State.getStatesOfCountry(countryCode.isoCode);
                const state = states.find((data) => data.name === client.state);
                if (state) {
                    cities = City.getCitiesOfState(countryCode.isoCode, state.isoCode);
                }
            }
        }
        return cities ? cities : [];
    };



    return (
        <ValidatorForm className={classes.container} form="Client Details" onSubmit={() => onSubmit()}>
            <Grid container className={classes.profileContainer}>
                <Grid container direction="column">
                    <Typography component="h4" variant="h4" className={classes.connectTitle}>
                        {clientId ? "Edit Client" : "New Client"}
                    </Typography>
                </Grid>
                <Grid container className={classes.formContainer}>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <TextBox
                                        label="Client Name"
                                        name="name"
                                        value={client.name ? client.name : ''}
                                        onBlur={(event) => validateDuplicateName(event.target.value)}
                                        validators={['required', 'minStringLength:4', 'maxStringLength:250']}
                                        errorMessages={['Client Name is required', 'Client Name Shoud be More than 4 Character', 'Maximum Character count 250']}
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        fullWidth
                                        error={error}
                                        helperText={error ? "Duplicate Name Not Allowed" : ""}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <TextBox
                                        label="Email Address"
                                        name="email"
                                        type="email"
                                        value={client.email ? client.email : ''}
                                        validators={['required', 'isEmail']}
                                        errorMessages={['Email is required', 'Enter valid Email Address']}
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <TextBox
                                        label="Phone Number"
                                        name="phone_number"
                                        value={client.phone_number ? client.phone_number : ''}
                                        validators={['required', 'maxStringLength:10', 'minStringLength:10']}
                                        errorMessages={['Phone No is required', 'Enter Valid Phone No', 'Enter Valid Phone No']}
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <TextBox
                                        label="Address"
                                        name="address"
                                        value={client.address ? client.address : ''}
                                        validators={['required']}
                                        errorMessages={['Address is required']}
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <FormLabel>Country</FormLabel>
                                    <AutoCompleteInput
                                        label="Country"
                                        name="country"
                                        popperWidth={300}
                                        options={[...Country.getAllCountries()]}
                                        value={client.country ? client.country : ""}
                                        getOptionLabel={
                                            (option) => {
                                                if (option && option.name) {
                                                    return option.name;
                                                }
                                                return option;
                                            }
                                        }
                                        getOptionSelected={(option, value) => option.name === value}
                                        renderInput={
                                            (params) => <TextField {...params}
                                                value={client.country ? client.country : ""}
                                                // className={classNames('country', classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                                placeholder="Country"
                                                validators={['required']}
                                                errorMessages={['Address is required']} />
                                        }
                                        onChange={(_, selectedValue) => onChange("country", selectedValue.name)}
                                        openOnFocus
                                        blurOnSelect
                                        disableClearable
                                        fullWidth
                                        forcePopupIcon={false}
                                        noOptionsText={""}
                                    />
                                </Grid>
                                {formSubmit && client.country === "" && <FormHelperText className={classes.errorTxt}>Country is required</FormHelperText>}
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <FormLabel>State</FormLabel>
                                    <AutoCompleteInput
                                        label="State"
                                        name="state"
                                        popperWidth={300}
                                        options={[...getStates()]}
                                        value={client.state ? client.state : ""}
                                        getOptionLabel={
                                            (option) => {
                                                if (option && option.name) {
                                                    return option.name;
                                                }
                                                return option;
                                            }
                                        }
                                        getOptionSelected={(option, value) => option.name === value}
                                        renderInput={
                                            (params) => <TextField {...params}
                                                // className={classNames('country', classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                                placeholder="State" />
                                        }
                                        onChange={(_, selectedValue) => onChange("state", selectedValue.name)}
                                        openOnFocus
                                        blurOnSelect
                                        disableClearable
                                        fullWidth
                                        forcePopupIcon={false}
                                        noOptionsText={""}
                                    />
                                    {formSubmit && client.state === "" && <FormHelperText className={classes.errorTxt}>State is required</FormHelperText>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <FormLabel>City</FormLabel>
                                    <AutoCompleteInput
                                        label="City"
                                        name="city"
                                        popperWidth={300}
                                        options={[...getCities()]}
                                        value={client.city ? client.city : ""}
                                        getOptionLabel={
                                            (option) => {
                                                if (option && option.name) {
                                                    return option.name;
                                                }
                                                return option;
                                            }
                                        }
                                        getOptionSelected={(option, value) => option.name === value}
                                        renderInput={
                                            (params) => <TextField {...params}
                                                // className={classNames('country', classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                                placeholder="City" />
                                        }
                                        onChange={(_, selectedValue) => onChange("city", selectedValue.name)}
                                        openOnFocus
                                        blurOnSelect
                                        disableClearable
                                        fullWidth
                                        forcePopupIcon={false}
                                        noOptionsText={""}
                                    />
                                    {formSubmit && client.city === "" && <FormHelperText className={classes.errorTxt}>City is required</FormHelperText>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <TextBox
                                        label="ZIP/Pin code"
                                        name="zip_code"
                                        value={client.zip_code ? client.zip_code : ''}
                                        validators={['required', 'minStringLength:6', 'maxStringLength:6']}
                                        errorMessages={['ZIP/Pin code is required', 'Enter Valid ZIP/Pin code', 'Enter Valid ZIP/Pin code']}
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <TextBox
                                        label="Primary Contact"
                                        name="primary_contact"
                                        value={client.primary_contact ? client.primary_contact : ''}
                                        validators={['required']}
                                        errorMessages={['Primary Contact is required']}
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <TextBox
                                        label="Website"
                                        name="website"
                                        value={client.website ? client.website : ''}
                                        validators={['required']}
                                        errorMessages={['Website is required']}
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                    {client.website && client.website !== "" && !websiteRegex.test(client.website) && <FormHelperText className={classes.errorTxt}>Enter valid Url</FormHelperText>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container>
                                <Grid item xs={11}>
                                    <TextBox
                                        label="Notes"
                                        name="notes"
                                        value={client.notes ? client.notes : ''}
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        fullWidth
                                        multiline
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button disabled={isLoading} variant="contained" color="primary" type="submit" className={classnames(classes.actionButtons, classes.submitBtn)}>
                                Save
                                {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                            </Button>
                            <Button onClick={() => clientId ? onReset() : onClear()} className={classnames(classes.cancelButton, classes.actionButtons, classes.marginLeft10)}>
                                {clientId ? "Reset" : "Clear"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    isPageLoading &&
                    <Loader />
                }
            </Grid>
        </ValidatorForm>
    );
};

ClientDetail.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    clientId: PropTypes.number,
    onChangeSetting: PropTypes.func
};

export default withStyles((theme) => ({
    ...ClientStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ClientDetail);