import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Grid, withStyles, Popover, Button, Typography, MenuItem, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ValidatorForm } from 'react-material-ui-form-validator';

import DownloadCSV from "../DownloadCSV/DownloadCSV.jsx";
import Loader from "../Loaders/Loader.jsx";
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx'
import GenerateLicenseStyles from "./GenerateLicenseStyles.jsx";
import { generateLicense } from '../../actions/licenseActions';
import { getActiveClients } from '../../actions/settingActions';

const GenerateLicense = (props) => {
    const { classes, open, anchorEl, onClose, packageId } = props;
    const dispatch = useDispatch();
    const clientList = useSelector(({ setting }) => setting.clinets);
    const [clients, setClients] = useState([...clientList]);
    const [liceseDetails, setLicenseDetails] = useState([]);
    const [license, setLicense] = useState({ license_count: 0 });
    const [isGenerate, setGenerated] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(false);


    const getClients = useCallback(() => {
        dispatch(getActiveClients()).then((response) => {
            if (response) {
                setClients([...response]);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        if (clientList.length === 0) {
            getClients();
        }
    }, [clientList.length, getClients]);

    const onChange = (name, value) => {
        const regex = /^[0-9]*$/;
        if (name === "license_count" && !regex.test(value)) {
            return false;
        }
        license[name] = value;
        if (name === "client_1" || name === "client_2") {
            setError(false);
        }
        setLicense({ ...license });
    };

    const generate = () => {
        if (license.client_1 !== "" && license.client_2 !== "") {
            setLoading(true);
            const requestParams = {
                "package_id": packageId,
                ...license
            };
            dispatch(generateLicense(requestParams)).then((response) => {
                setLoading(false);
                if (response) {
                    setGenerated(true);
                    setLicenseDetails([...response]);
                }
            });
        } else {
            setError(true);
        }
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={
                {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
            transformOrigin={
                {
                    vertical: 'bottom',
                    horizontal: 'center'
                }
            }
            classes={
                {
                    paper: classes.modelContainer
                }
            }>
            <Grid container direction="column" justifyContent="flex-start" wrap="nowrap">
                <Grid container justifyContent="space-between">
                    <Typography variant="h6">
                        {'Generate License'}
                    </Typography>
                    {
                        isGenerate &&
                        <DownloadCSV packageId={packageId} liceseDetails={liceseDetails} />
                    }
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ValidatorForm className={classes.marginTop10} onSubmit={() => generate()}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextBox
                                    label="License Count"
                                    name="license_count"
                                    value={license.license_count ? license.license_count : ""}
                                    validators={['required', 'minNumber:1', 'maxNumber:1000']}
                                    errorMessages={['License Count is required', 'Count Minimum 1 is required', 'Count Below 1000 will accepted']}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextBox
                                    label="Level 1 Client"
                                    name="client_1"
                                    value={license.client_1 ? license.client_1 : ''}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    select
                                    fullWidth
                                    SelectProps={
                                        {
                                            MenuProps: {
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "center"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "center"
                                                },
                                                getContentAnchorEl: null
                                            }
                                        }
                                    }
                                >
                                    {
                                        clients.map((client, index) =>
                                            <MenuItem key={index} value={client.id}>
                                                {client.name}
                                            </MenuItem>
                                        )
                                    }
                                </TextBox>
                                {error && <FormHelperText className={classes.errorTxt}>Client is required</FormHelperText>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextBox
                                    label="Level 2 Client"
                                    name="client_2"
                                    value={license.client_2 ? license.client_2 : ''}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    select
                                    fullWidth
                                    SelectProps={
                                        {
                                            MenuProps: {
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "center"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "center"
                                                },
                                                getContentAnchorEl: null
                                            }
                                        }
                                    }
                                >
                                    {
                                        clients.map((client, index) =>
                                            <MenuItem key={index} value={client.id}>
                                                {client.name}
                                            </MenuItem>
                                        )
                                    }
                                </TextBox>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.marginTop10}>
                            <Grid container direction="row" justifyContent="flex-end">
                                <Button disabled={isGenerate || isLoading} variant="contained" color="primary" type="submit" className={classNames(classes.actionButtons, classes.submitBtn, classes.marginRight10)}>
                                    {'Generate License'}
                                    {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                </Button>
                                <Button onClick={() => onClose()} className={classNames(classes.cancelButton, classes.actionButtos)}>
                                    {'Cancel'}
                                </Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </Grid>
            </Grid>
        </Popover>
    )
};

GenerateLicense.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    anchorEl: PropTypes.element,
    onClose: PropTypes.func,
    packageId: PropTypes.number
};

export default withStyles((theme) => ({
    ...GenerateLicenseStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(GenerateLicense);