import React, { useState } from 'react';
import { Grid, withStyles, Tabs, Tab, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import SettingStyles from './SettingStyles.jsx';

import Profile from '../../components/Profile/Profile.jsx';
import Clients from '../../components/Clients/Clients.jsx';
import { appConstants } from '../../constants/appConstants.jsx';
import TabPanel from '../../components/TabPanel/TabPanel.jsx';
import Filter from '../../components/Filter/Filter.jsx';
import ClientDetail from '../../components/ClientDetail/ClientDetail.jsx';
import Theme from '../../components/Theme/Theme.jsx';

const Settings = (props) => {
    const { classes } = props;
    const [tabIndex, setTabIndex] = useState(0);
    const [breadCrumb, setBreadCrumb] = useState([{ name: "Client", path: 'client' }]);
    const [clientId, setClientId] = useState(0);
    const [isClient, setClient] = useState(false);
    const [headers, setHeaders] = useState([...appConstants.settingHeaders]);


    const onChangeSetting = (type, data) => {
        if (type === "edit" || type === "add") {
            setClient(true);
            breadCrumb.push({ name: data ? data.name : 'Create Client', path: '' });
            if (type === "edit") {
                setClientId(data.id);
            }
            setHeaders([...appConstants.clientHeaders]);
            setTabIndex(0);
        } else if (type === "back") {
            setClientId(0);
            setClient(false);
            breadCrumb.pop();
            setTabIndex(1);
            setHeaders([...appConstants.settingHeaders]);
        }
        setBreadCrumb([...breadCrumb]);
    }

    return (
        <Grid>
            <Grid className={classes.marginTop15}>
                {
                    breadCrumb.length > 1 && breadCrumb.map((crumb, index) =>
                        <Typography variant="body2" key={`crumb_${index}`} component="span" className={classes.link} onClick={() => onChangeSetting(crumb.path !== "" ? "back" : "", crumb.path)}>
                            {crumb.name}
                            {(index !== breadCrumb.length - 1) ? ' | ' : null}
                        </Typography>
                    )
                }
            </Grid>
            <Filter
                name="Settings" />
            <Grid className={classes.settingsTabContainer}>
                <Tabs
                    value={tabIndex}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={(event, index) => setTabIndex(index)}
                    aria-label="setting tabs"
                    variant="scrollable"
                    scrollButtons="on"
                >
                    {
                        headers.map((name, index) =>
                            <Tab key={index} label={name} />
                        )
                    }
                </Tabs>
            </Grid>
            <Grid className={classNames(classes.container, classes.relative)}>
                {
                    !isClient ?
                        <Grid>
                            {
                                tabIndex === 0 &&
                                <TabPanel value={tabIndex} index={0}>
                                    <Profile />
                                </TabPanel>
                            }
                            {
                                tabIndex === 1 &&
                                <TabPanel value={tabIndex} index={1}>
                                    <Clients onChangeSetting={(type, data) => onChangeSetting(type, data)} />
                                </TabPanel>
                            }
                        </Grid> :
                        <Grid>
                            {
                                tabIndex === 0 &&
                                <TabPanel value={tabIndex} index={0}>
                                    <ClientDetail clientId={clientId} onChangeSetting={(type, data) => onChangeSetting(type, data)} />
                                </TabPanel>
                            }
                            {
                                tabIndex === 1 &&
                                <TabPanel value={tabIndex} index={1}>
                                    <Theme clientId={clientId} />
                                </TabPanel>
                            }
                        </Grid>
                }

            </Grid>
        </Grid>
    )

};

Settings.propTypes = {
    classes: PropTypes.object
};

export default withStyles((theme) => ({
    ...SettingStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Settings);