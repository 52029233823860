import { appConstants } from '../constants/appConstants';
import { getCookie, removeCookie } from './appHelpers';
import { history } from '../config/history';

const apiBaseUrl = appConstants.API_BASE_URL;

/**
 * GET request
 * @param {*} endpoint
 */
export const getRequest = async (endpoint) => {
	return makeRequest(`${apiBaseUrl}/${endpoint}/`, 'GET');
};

/**
 * PUT requests
 * @param {* The put endpoint} endpoint
 * @param {* The request body data} body
 * @param {* <true> if the put method contains any images, <false> otherwise.} isFormData
 */
export const putRequest = async (endpoint, body, isFormData) => {
	return makeRequest(`${apiBaseUrl}/${endpoint}/`, 'PUT', body, isFormData);
};

/**
 * POST request
 * @param {* The post endpoint} endpoint
 * @param {* The request body data} body
 * @param {* <true> if the post method contains any images, <false> otherwise.} isFormData
 */
export const postRequest = async (endpoint, body, isFormData) => {
	return makeRequest(`${apiBaseUrl}/${endpoint}/`, 'POST', body, isFormData);
};

/**
 * DELETE request
 * @param {* The delete endpoint} endpoint
 */
export const deleteRequest = async (endpoint) => {
	return makeRequest(`${apiBaseUrl}/${endpoint}/`, 'DELETE');
};

const makeRequest = (endpoint, verb, body, isFormData = false) => {
	if (!isFormData) {
		body = body ? JSON.stringify(body) : null;
	}
	const requestOptions = {
		method: verb,
		headers: getHeaders(isFormData),
		body
	};
	return fetch(endpoint, requestOptions)
		.then(handleResponse)
		.then((response) => {
			return response;
		}).catch((error) => {
			throw error;
		});
};

/**
 * Handler for the response recieved from fetch request
 * @param {* The response recieved from fetch request} response
 */
const handleResponse = (response) => {
	if (response.status !== 200) {
		if (response.status === 401) {
			removeCookie();
			history.push({
				pathname: '/'
			});
		}
		return Promise.reject(response.statusText);
	}
	return response.text().then((text) => {
		const responseData = text && JSON.parse(`${text}`);
		return responseData;
	});
};

/**
 * Prepares headers for the request
 * @param {* <true> if the request contains any images, <false> otherwise.} isFormData
 */
const getHeaders = (isFormData) => {
	const token = getCookie('token');
	const headers = new Headers();
	if (token) { headers.set('Authorization', `JWT ${token}`); }
	if (!isFormData) { headers.set('Content-Type', 'application/json'); }

	return headers;
};

/**
 * Prepares the query string based on the given input prams
 * @param {* The params used to prepare query string} params
 */
export const makeQueryString = (params) => {
	let queries = Object.keys(params).map((key) => (params[key] && params[key].length > 0 ? `${key} + '=' + ${params[key]}` : ''));
	queries = queries.filter((query) => query.length > 0);
	return '?' + queries.join('&');
};