
// import SliderBg from '../../assets/images/slider.png';
import SliderBg from '../../assets/images/login_bg.png';

const SliderStyles = (theme) => ({
    root: {
        height: '100%!important'
    },
    pageContainer: {
        background: "#f6f8f9"
    },
    container: {
        height: '100vh',
        width: '100%',
        backgroundImage: `url(${SliderBg})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        marginLeft: "0px",
        '& .owl-nav.disabled': {
            display: 'none !important'
        }
    },
    cardContent: {
        width: '235px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '6px',
        border: `1px solid #EFF4F6`,
        boxShadow: '0px 20px 50px #0000001A',
        padding: '10px 6px 10px 10px',
        position: 'absolute',
        marginTop: 30,
        transition: 'all ease .3s',
        textDecoration: 'none'
    },
    card: {
        position: 'relative',
        '& img': {
            // width: 11,
            height: 11,
            width: "100%"
        }
    },
    title: {
        fontWeight: 500,
        marginBottom: 10,
        color: theme.palette.common.black
    },
    description: {
        fontSize: 12,
        color: theme.palette.grey.dark
    },
    cardContent1: {
        left: '-90px'
    },
    cardContent2: {
        left: "-40px",
        width: 280
    },
    cardContent3: {
        top: "-100px",
        marginTop: "0px",
        left: "70px",
        width: 280
    },
    cardContent4: {
        width: 280,
        left: "-150px"
    },
    slide1: {
        marginTop: "28%",
        marginLeft: "10%"

    },
    slide2: {
        marginTop: "43%",
        marginLeft: "20%"
    },
    slide3: {
        marginLeft: "21%",
        marginTop: "14%"
    },
    slide4: {
        marginTop: "29%",
        marginLeft: "21%"
    },
    titleContainer: {
        left: 30,
        top: 120,
        position: 'absolute',
        '& h5': {
            color: theme.palette.grey.main
        }
    }

});

export default SliderStyles;