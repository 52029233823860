import React, { useEffect, useCallback, useState } from 'react';
import { Grid, withStyles, Button, Typography, FormControlLabel, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getLicenseConfig, updateLicense } from '../../actions/licenseActions';
import Filter from '../../components/Filter/Filter.jsx';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Styles from '../../layouts/Styles.jsx';
import LicensePackage from '../../components/LicensePackage/LicensePackage';
import Loader from '../../components/Loaders/Loader';
import LicenceLog from '../../components/LicenceLog/LicenceLog.jsx';
import EditLicenseStyle from "./EditLicenseStyle";
import TextBox from '../../components/TextBox/TextBox.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';


const EditLicense = (props) => {

    const { match, history, classes } = props;
    const licenseId = match.params && match.params.license ? match.params.license : 0;
    const [packageInfo, setPackageInfo] = useState({ module_config: {} });
    const [defaultPackageInfo, setDefaultPackageInfo] = useState({ module_config: {} });
    const [isLoading, setLoading] = useState(false);
    const [isPageLoading, setPageLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [breadcrumb] = useState([{ name: "License", path: "/license" }, { name: "Edit License", path: "" }]);
    const dispatch = useDispatch();
    const connections = useSelector(({ license }) => license.connections);
    const [auditTrack, setAuditTrack] = useState([]);
    const [connectionConfigError, setConnectionConfigError] = useState(false);
    const [inboundConfigError, setInboundConfigError] = useState(false);
    const [outboundConfigError, setoutboundConfigError] = useState(false);
    const [changedPropeties, setChangedProperties] = useState([]);
    const [inputConnectionError, setInputConnectionError] = useState(false);


    const getConfig = useCallback(() => {
        dispatch(getLicenseConfig(licenseId)).then((response) => {
            setPageLoading(false);
            if (response) {
                setDefaultPackageInfo({ ...JSON.parse(JSON.stringify(response)) });
                setPackageInfo({ ...JSON.parse(JSON.stringify(response)) });
            }
        });
    }, [dispatch, licenseId]);

    useEffect(() => {
        getConfig();
    }, [getConfig]);

    const onChangeConnection = (name, value) => {
        const index = packageInfo.module_config[name].indexOf(value);
        if (index !== -1) {
            packageInfo.module_config[name].splice(index, 1);
        } else {
            packageInfo.module_config[name].push(value);
        }
        auditTrack.push({ "property": name, "log_type": "properties", "changed_value": "" });
        setAuditTrack([...auditTrack]);
        setPackageInfo({ ...packageInfo });
        const trackIndex = changedPropeties.indexOf(name);
        if (trackIndex === -1) {
            changedPropeties.push(name);
            setChangedProperties([...changedPropeties]);
        }
    };

    const onChangeProperty = (property, value, subModule) => {
        if (subModule) {
            const moduleConfig = packageInfo.module_config ? packageInfo.module_config : {};
            moduleConfig[property] = value;
            if (property === "curate" && value) {
                moduleConfig.profile = value;
            }
            packageInfo.module_config = { ...moduleConfig };
            auditTrack.push({ "property": property, "log_type": "properties", "changed_value": value ? "turn on" : "turn off" });
        } else {
            const regex = /^[0-9]*$/;
            if ((property === "no_of_connectors") && !regex.test(value)) {
                return false;
            }
            packageInfo[property] = value
        }
        setAuditTrack([...auditTrack])
        setPackageInfo({ ...packageInfo });
        const trackIndex = changedPropeties.indexOf(property);
        if (trackIndex === -1) {
            changedPropeties.push(property);
            setChangedProperties([...changedPropeties]);
        }
    };

    const onTrackConnection = (property, value) => {
        auditTrack.push({ "property": property, "log_type": "no_of_connectors", "changed_value": value });
        setAuditTrack([...auditTrack])
    };

    const editLicense = () => {
        if (packageInfo.no_of_connectors === "" || packageInfo.no_of_connectors === 0 || packageInfo.no_of_connectors > connections.length) {
            return false;
        }

        if (!packageInfo.is_select_connector){
            if (parseInt(packageInfo.no_of_connectors) < parseInt(packageInfo.allowed_no_of_connectors)) {
                return false;
            }
        }

        if (parseInt(packageInfo.no_of_connectors) !== packageInfo?.module_config?.input_connectors.length) {
            return false;
        }

        if (!packageInfo.module_config.pull && !packageInfo.module_config.scan) {
            setConnectionConfigError(true);
            return false;
        }

        if (connectionConfigError) {
            setConnectionConfigError(false);
        }

        if (packageInfo.is_select_connector && (!packageInfo.module_config?.input_connectors || packageInfo?.module_config?.input_connectors.length === 0 || !(packageInfo?.module_config?.input_connectors.length >= packageInfo.no_of_connectors && packageInfo?.module_config?.input_connectors.length <= packageInfo.no_of_connectors))) {
            setInputConnectionError(true);
            return false;
        } else {
            setInputConnectionError(false);
        }
        if (packageInfo.module_config.inbound_connectors && packageInfo.module_config.inbound_connectors.length === 0) {
            setInboundConfigError(true);
            return false;
        } else {
            setInboundConfigError(false);
        }

        if (packageInfo.module_config.outbound_connectors && packageInfo.module_config.outbound_connectors.length === 0) {
            setoutboundConfigError(true);
            return false;
        } else {
            setoutboundConfigError(false);
        }

        const requestParams = {
            "properties": packageInfo,
            "audit_log": auditTrack,
            "changed_properties": changedPropeties
        };
        setLoading(true);
        dispatch(updateLicense(licenseId, requestParams)).then(() => {
            setLoading(false);
            history.push('/license');
        });
        setAuditTrack([]);
    };


    const openAuditLog = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onCloseAuditLog = () => {
        setAnchorEl(null);
    };

    const onReset = () => {
        setPackageInfo({ ...JSON.parse(JSON.stringify(defaultPackageInfo)) });
        setAuditTrack([]);
    };

    return (

        <Grid>
            <Grid justify="space-between" container>
                <Breadcrumb history={history} breadcrumb={breadcrumb} />
                <Button variant="button" className={classes.licenseLog} onClick={(event) => openAuditLog(event)}>
                    License Log
                </Button>
            </Grid>
            <Filter name={"Edit License"} />
            <Grid className={classes.pageContainer}>
                <ValidatorForm name="createpackage" onSubmit={() => editLicense()} className={classes.container}>
                    <Grid container>
                        <Grid item xs={3}>
                            <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>No Of Connections</Typography>
                            <TextBox
                                placeholder="Connector Count"
                                name="no_of_connectors"
                                validators={['required', 'minNumber:1', `maxNumber:${connections.length}`]}
                                errorMessages={['Connectors is required', 'Connetors Limit Must Greater than 0', 'Connectors Limit Exceed']}
                                value={packageInfo.no_of_connectors ? packageInfo.no_of_connectors : ""}
                                onChange={(event) => onChangeProperty(event.target.name, event.target.value)}
                                onBlur={(event) => onTrackConnection(event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={1} />
                        {
                            !packageInfo.is_select_connector &&
                            <Grid item xs={3}>
                                <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>Allowed No Of Connections</Typography>
                                <TextBox
                                    placeholder="Allowed Connector Count"
                                    name="allowed_no_of_connectors"
                                    validators={['required', 'minNumber:1', `maxNumber:${packageInfo.no_of_connectors? packageInfo.no_of_connectors: 1}`]}
                                    errorMessages={['Connectors is required', 'Connetors Limit Must Greater than 0', 'Connectors Limit Exceed']}
                                    value={packageInfo.allowed_no_of_connectors ? packageInfo.allowed_no_of_connectors : ""}
                                    onChange={(event) => onChangeProperty(event.target.name, event.target.value)}
                                    onBlur={(event) => onTrackConnection(event.target.name, event.target.value)}
                                    disabled={packageInfo.is_select_connector}
                                    fullWidth
                                />
                            </Grid>
                        }
                        <Grid item xs={4}>
                            <Grid className={classes.formGroup}>
                                <FormControlLabel
                                    className={classnames(classes.formLabel)}
                                    control={<Switch size="small" color="secondary" name="is_select_connector" checked={Boolean(packageInfo.is_select_connector)} value={packageInfo.is_select_connector} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.is_select_connector)} />}
                                    label="Select Predefined connector for the user"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <LicensePackage
                        packageInfo={packageInfo}
                        onChangeConnection={(name, value) => onChangeConnection(name, value)}
                        onChangeProperty={(name, value, subModule) => onChangeProperty(name, value, subModule)}
                        connectionConfigError={connectionConfigError}
                        inboundConfigError={inboundConfigError}
                        outboundConfigError={outboundConfigError}
                        inputConnectionError={inputConnectionError}
                    />
                    <Grid item xs={12} className={classes.marginTop30}>
                        <Button disabled={isLoading} variant="contained" color="primary" type="submit" className={classes.submitBtn}>
                            Save
                            {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                        </Button>
                        <Button className={classes.cancelButton}
                            style={{ marginLeft: 20 }}
                            disabled={isLoading}
                            onClick={() => onReset()}>
                            {'Reset'}
                        </Button>
                    </Grid>
                </ValidatorForm>
            </Grid>
            {
                isPageLoading &&
                <Loader />
            }
            {
                anchorEl &&
                <LicenceLog
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    handleClose={() => onCloseAuditLog()}
                    licenseId={licenseId}
                />
            }
        </Grid>
    )
}



EditLicense.propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object
};

export default withStyles((theme) => ({
    ...EditLicenseStyle(theme),
    ...Styles(theme)
}), { withTheme: true })(EditLicense);