import React, { useState, useCallback, useEffect } from 'react';
import { Grid, withStyles, Table, TableHead, TableCell, TableBody, TableRow, Typography, TableSortLabel, IconButton, Switch } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { getAllClient, updateClient, deleteClient, updateClientList } from '../../actions/settingActions';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import Search from '../TextBox/Search.jsx';
import Styles from '../../layouts/Styles.jsx';
import { appConstants } from '../../constants/appConstants';
import Loader from '../Loaders/Loader.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import { sortTable } from '../../helpers/appHelpers';
import ClientSetting from './ClientPopover.jsx';
import AlertDialog from '../AlertDialog/AlertDialog';

const Clients = (props) => {
    const { classes, theme, onChangeSetting } = props;
    const [search, setSearch] = useState("");
    const [clients, setClients] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const [selectedClientId, setSelectedClietId] = useState(0);
    const dispatch = useDispatch();
    const [selectedClient, setSelectedClient] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const onChangeOrder = useCallback((property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [order, orderBy]);

    const getClients = useCallback(() => {
        dispatch(getAllClient()).then((response) => {
            if (response) {
                setClients([...response]);
            }
            setLoading(false);
        });
    }, [dispatch]);

    useEffect(() => {
        getClients();
    }, [getClients]);


    const onChange = (clientId, status) => {
        const index = clients.findIndex((client) => client.id === clientId);
        if (index !== -1) {
            clients[index].is_active = status;
            setClients([...clients]);
            const requestParams = {
                "is_active": status
            }
            dispatch(updateClient(clientId, requestParams));
        }
    }

    const onDeleteClient = (clientId) => {
        const index = clients.findIndex((client) => client.id === clientId);
        if (index !== -1) {
            clients.splice(index, 1);
            setClients([...clients]);
            dispatch(deleteClient(clientId));
            const clientsList = clients.map((client) => client.name);
            dispatch(updateClientList(clientsList));
        }
        setSelectedClient(null)
    }

    const openClientDialog = (event, clientId) => {
        setAnchorEl(event.currentTarget);
        setSelectedClietId(clientId);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const filterClients = search !== "" ? clients.filter((client) => client.name.toLowerCase().includes(search.toLowerCase())) : clients;

    return (
        <Grid className={classNames(classes.tabContainer, classes.noHeight)}>
            <Grid container justifyContent="space-between" className={classes.marginBottom12}>
                <Grid item>
                    <Typography className={classes.tabTitle} variant="h5" component="h5">
                        Client Lists
                    </Typography>
                </Grid>
                <Grid item className={classes.search} >
                    <Search className={classes.dqSearchBoxRefList} placeholder="Search Client" value={search} onChange={(value) => setSearch(value)} />
                    <TooltipComponent title={"Add Organization"} arrow>
                        <IconButton className={classes.alignIconCenter} onClick={() => onChangeSetting('add', '')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                    <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                        <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                        <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                    </g>
                                </g>
                            </svg>
                        </IconButton>
                    </TooltipComponent>
                </Grid>
            </Grid>
            <Grid className={classes.mainInnerContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                appConstants.clientTableHeaders.map((header, index) =>
                                    <TableCell key={index}>
                                        {
                                            (header.name !== 'Actions' && header.name === 'Status') ?
                                                <TableSortLabel
                                                    active={orderBy === header.value}
                                                    direction={orderBy === header.value ? order : 'asc'}
                                                    onClick={() => onChangeOrder(header.value)}
                                                >
                                                    <Typography variant="body1" className={classes.tableHeader}>
                                                        {header}
                                                    </Typography>
                                                </TableSortLabel> :
                                                <Typography variant="body1" className={classes.tableHeader}>
                                                    {header}
                                                </Typography>
                                        }
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            sortTable(filterClients, order, orderBy).map((client, index) =>
                                <TableRow key={index}>
                                    <TableCell className={classes.tableCell}>
                                        <Typography>
                                            {client.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Typography>
                                            {client.email}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Typography>
                                            {client.phone_number}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Typography>
                                            {client.no_of_licenses ? client.no_of_licenses : 0}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Switch
                                            checked={client.is_active}
                                            onChange={(event) => onChange(client.id, event.target.checked)}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Grid className={classes.flex}>
                                            <ToolTipComponent title="Edit" placement="bottom" arrow>
                                                <IconButton className={classNames(classes.nopadding, classes.marginRight5)} onClick={() => onChangeSetting('edit', client)}>
                                                    <svg
                                                        version="1.1"
                                                        id="Layer_1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="21.349"
                                                        height="23.349"
                                                        x="0px"
                                                        y="0px"
                                                        viewBox="0 0 32 32"
                                                    >
                                                        <g id="edit" transform="translate(0 0)">
                                                            <path
                                                                fill={theme.palette.primary.main}
                                                                stroke={theme.palette.primary.main}
                                                                strokeWidth="0.3"
                                                                strokeMiterlimit="10"
                                                                d="M27.2,8L24,4.8c-0.8-0.8-2.2-0.8-3,0L6.5,19.4c-0.1,0.1-0.1,0.1-0.1,0.2l-2.1,7.5c-0.1,0.3,0.1,0.6,0.4,0.7
                        c0.1,0,0.2,0,0.3,0l7.5-2.1c0.1,0,0.2-0.1,0.2-0.1l14.6-14.4C28,10.2,28,8.9,27.2,8L27.2,8z M18.6,8.7l2,2L10.2,21l-0.7-1.5
                        c-0.1-0.2-0.3-0.3-0.5-0.3H8.2L18.6,8.7z M5.5,26.5L6.2,24L8,25.8L5.5,26.5z M11.7,24.7l-2.6,0.7l-2.6-2.6l0.7-2.6h1.4l0.9,1.8
                        c0.1,0.1,0.1,0.2,0.2,0.2l1.8,0.9L11.7,24.7L11.7,24.7z M12.8,23.8V23c0-0.2-0.1-0.4-0.3-0.5L11,21.7l10.3-10.3l2,2L12.8,23.8z
                        M26.4,10.3l-2.4,2.3l-4.7-4.7l2.3-2.4c0.4-0.4,1.1-0.4,1.5,0l3.2,3.2C26.8,9.2,26.8,9.9,26.4,10.3L26.4,10.3z"
                                                            />
                                                        </g>
                                                    </svg>
                                                </IconButton>
                                            </ToolTipComponent>
                                            {
                                                client.no_of_licenses === 0 &&
                                                <ToolTipComponent title="Delete" placement="bottom" arrow>
                                                    <IconButton className={classNames(classes.nopadding, classes.marginRight10)} onClick={() => setSelectedClient(client)}>
                                                        <svg version="1.1"
                                                            id="Layer_1"
                                                            width="18"
                                                            height="18"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            x="0px"
                                                            y="0px"
                                                            viewBox="0 0 32 32">
                                                            <g id="Delete">
                                                                <path fill={theme.palette.primary.main}
                                                                    stroke={theme.palette.primary.main}
                                                                    strokeWidth="0.92"
                                                                    strokeMiterlimit="10"
                                                                    d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
		L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
		L22.3,25.8z" />
                                                                <path fill={theme.palette.primary.main}
                                                                    stroke={theme.palette.primary.main}
                                                                    strokeWidth="0.92"
                                                                    strokeMiterlimit="10"
                                                                    d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
		c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
		c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                            </g>
                                                        </svg>
                                                    </IconButton>
                                                </ToolTipComponent>
                                            }
                                            <ToolTipComponent title="License" placement="bottom" arrow>
                                                <IconButton className={classNames(classes.nopadding, classes.marginRight10)} onClick={(event) => openClientDialog(event, client.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.823" height="16.249" viewBox="0 0 15.823 16.249">
                                                        <path fill={theme.palette.primary.main} d="M22.26,9.905,20.766,8.737a5.88,5.88,0,0,0,.035-.613,5.7,5.7,0,0,0-.035-.613l1.495-1.169a.732.732,0,0,0,.177-.933L20.884,2.722a.72.72,0,0,0-.9-.323l-1.762.708a6.042,6.042,0,0,0-1.058-.614L16.9.623A.719.719,0,0,0,16.18,0H13.065a.717.717,0,0,0-.716.617L12.08,2.494a6.229,6.229,0,0,0-1.056.614L9.257,2.4a.734.734,0,0,0-.892.318L6.81,5.406a.728.728,0,0,0,.176.938L8.48,7.512a5.357,5.357,0,0,0,0,1.226L6.985,9.907a.732.732,0,0,0-.176.933l1.553,2.687a.719.719,0,0,0,.9.323l1.762-.708a6.1,6.1,0,0,0,1.058.614l.268,1.869a.718.718,0,0,0,.718.624H16.18a.718.718,0,0,0,.717-.617l.269-1.875a6.258,6.258,0,0,0,1.056-.615l1.767.71a.728.728,0,0,0,.265.05.715.715,0,0,0,.626-.367l1.56-2.7A.731.731,0,0,0,22.26,9.905Zm-7.637.928a2.708,2.708,0,1,1,2.708-2.708A2.711,2.711,0,0,1,14.622,10.833Z" transform="translate(-6.71)" />
                                                    </svg>
                                                </IconButton>
                                            </ToolTipComponent>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
                {
                    anchorEl &&
                    <ClientSetting
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        handleClose={() => handleClose()}
                        clientId={selectedClientId}
                    />
                }
                {
                    Boolean(selectedClient) &&
                    <AlertDialog title="Delete Client"
                        message={`Are you sure you want to delete the selected client ${selectedClient.name ? selectedClient.name : ''}?`}
                        okButtonText="OK"
                        cancelButtonText="Cancel"
                        show={Boolean(selectedClient)}
                        onClickOK={() => onDeleteClient(selectedClient.id)}
                        onClickCancel={() => setSelectedClient(null)} />
                }

            </Grid>
            {
                isLoading && <Loader />
            }
        </Grid>
    )
}

Clients.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    onChangeSetting: PropTypes.func
};

export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(Clients);