import React, { useState } from 'react';
import { Grid, IconButton, withStyles, Typography, MenuItem, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TooltipComponent from '../../components/Tooltip/Tooltip.jsx';
import AddIcon from '@material-ui/icons/Add';
import Search from '../TextBox/Search.jsx';
import Styles from '../../layouts/Styles.jsx';
import FilterStyles from './FilterStyles.jsx';
import TextBox from '../TextBox/TextBox.jsx';

const Filter = (props) => {
    const { classes, history, name, title, url, showAdd, showSearch, theme, showFilter, filterOptions, filters, resetFilter, onChangeFilter } = props;
    const [enableFilter, setFilter] = useState(false);

    const DropDownIcon = (iconProps) => {
        return (
            <svg {...iconProps} className={classes.DropDownIcon} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" top="" width="12px" height="18px" viewBox="0 0 451.847 451.847">
                <g>
                    <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" fill={theme.palette.grey.dark} />
                </g>
            </svg>
        );
    };

    return (
        <Grid container xs={12} justifyContent="space-between" className={classes.marginTop20}>
            <Typography variant="h2" component="h2">
                {name}
            </Typography>
            <Grid item>
                <Grid container alignItems="center">
                    {
                        showSearch &&
                        <Grid className={classes.marginRight25}>
                            <Search
                                value={filters.search ? filters.search : ""}
                                placeholder="Search Here"
                                className={classes.dqSearchBox}
                                onChange={(value) => onChangeFilter("search", value)}
                            />
                        </Grid>
                    }
                    {
                        showFilter &&
                        <Grid>
                            {
                                enableFilter &&
                                <Grid className={classNames(classes.filterHideShowContainer)}>
                                    <Grid container alignItems="center" justifyContent="space-between" className={classNames(classes.datasourceFilterSection, "datasourceFilterSection")}>
                                        <TooltipComponent title="Close" placement="bottom" arrow>
                                            <IconButton onClick={() => setFilter(!enableFilter)} className={classes.marginRight12}>
                                                <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                    <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                                </svg>
                                            </IconButton>
                                        </TooltipComponent>
                                        <Grid item className={classNames(classes.select, classes.filterselect)}>
                                            <FormLabel>
                                                Filter
                                            </FormLabel>
                                            <TextBox
                                                onChange={(event) => onChangeFilter("filter", event.target.value)}
                                                value={filters.filter ? filters.filter : ""}
                                                select
                                                SelectProps={
                                                    {
                                                        MenuProps: {
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "center"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "center"
                                                            },
                                                            getContentAnchorEl: null
                                                        },
                                                        IconComponent: (iconProps) => DropDownIcon(iconProps)
                                                    }
                                                }
                                            >
                                                {
                                                    filterOptions.map((filterOption, index) => (
                                                        <MenuItem key={`filterBy_${index}`} value={filterOption.value} className={classes.menuItem}>
                                                            {filterOption.name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </TextBox>
                                        </Grid>
                                        <Grid item>
                                            <TooltipComponent title="Clear Filter" placement="bottom" arrow>
                                                <IconButton onClick={() => resetFilter()}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22.795" viewBox="0 0 20 22.795">
                                                        <g id="filter_10_" data-name="filter (10)" transform="translate(-31.388)">
                                                            <path id="Path_1437" data-name="Path 1437" d="M46.071,0a5.326,5.326,0,0,0-5.1,3.8h-8.59a1,1,0,0,0-.814,1.564l5.776,7.882a1.046,1.046,0,0,1,.185.6V21.75a1.044,1.044,0,0,0,1.668.835l2.564-1.937a1.527,1.527,0,0,0,.612-1.22V13.84a1.047,1.047,0,0,1,.185-.6l2.07-2.825A5.31,5.31,0,1,0,46.071,0ZM41.477,12.462a2.375,2.375,0,0,0-.438,1.377c0,6.076.033,5.658-.075,5.737l-2.1,1.586V13.84a2.376,2.376,0,0,0-.428-1.364l-5.38-7.341h7.7A5.307,5.307,0,0,0,43.369,9.88Zm4.594-3.18a3.973,3.973,0,1,1,3.982-3.973A3.982,3.982,0,0,1,46.071,9.282Z" transform="translate(0)" fill="#f38080" />
                                                            <path id="Path_1438" data-name="Path 1438" d="M321.45,76.089a.668.668,0,0,0-.944,0l-.793.791-.793-.791a.668.668,0,0,0-.944.945l.791.79-.791.79a.668.668,0,0,0,.944.945l.793-.791.793.791a.668.668,0,0,0,.944-.945l-.791-.79.791-.79A.668.668,0,0,0,321.45,76.089Z" transform="translate(-273.642 -72.515)" fill="#f38080" />
                                                        </g>
                                                    </svg>
                                                </IconButton>
                                            </TooltipComponent>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item>
                                <TooltipComponent title="Filters" placement="bottom" arrow>
                                    <IconButton onClick={() => setFilter(!enableFilter)} className={classNames(classes.marginRight7, classes.filterImg)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.923" viewBox="0 0 20 16.923">
                                            <g transform="translate(-3 -5)">
                                                <path id="Path_1391" data-name="Path 1391" d="M22.231,6.538H11.462A1.54,1.54,0,0,0,9.923,5H8.385A1.54,1.54,0,0,0,6.846,6.538H3.769a.769.769,0,1,0,0,1.538H6.846A1.54,1.54,0,0,0,8.385,9.615H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,8.077V6.538H9.923V7.3s0,0,0,0,0,0,0,0v.766Z" fill={theme.palette.primary.main} />
                                                <path id="Path_1392" data-name="Path 1392" d="M22.231,14.538H17.615A1.54,1.54,0,0,0,16.077,13H14.538A1.54,1.54,0,0,0,13,14.538H3.769a.769.769,0,0,0,0,1.538H13a1.54,1.54,0,0,0,1.538,1.538h1.538a1.54,1.54,0,0,0,1.538-1.538h4.615a.769.769,0,1,0,0-1.538Zm-7.692,1.538V14.538h1.538V15.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -1.846)" fill={theme.palette.primary.main} />
                                                <path id="Path_1393" data-name="Path 1393" d="M22.231,22.538H11.462A1.54,1.54,0,0,0,9.923,21H8.385a1.54,1.54,0,0,0-1.538,1.538H3.769a.769.769,0,0,0,0,1.538H6.846a1.54,1.54,0,0,0,1.538,1.538H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,24.077V22.538H9.923V23.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -3.692)" fill={theme.palette.primary.main} />
                                            </g>
                                        </svg>
                                    </IconButton>
                                </TooltipComponent>
                            </Grid>
                        </Grid>
                    }
                    {
                        showAdd &&
                        <TooltipComponent title={title} placement="bottom" arrow>
                            <IconButton className={classes.addIconButton}
                                classes={
                                    {
                                        root: classes.addIconButtonRoot
                                    }
                                }
                                onClick={() => history.push(`/${url}`)}
                            >
                                <AddIcon className={classes.addIcon} />
                            </IconButton>
                        </TooltipComponent>
                    }
                </Grid>

            </Grid>
        </Grid>
    )

};

Filter.propTypes = {
    classes: PropTypes.object,
    showSearch: PropTypes.bool,
    history: PropTypes.object,
    name: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    showAdd: PropTypes.bool,
    resetFilter: PropTypes.func,
    filters: PropTypes.array,
    onChangeFilter: PropTypes.func,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...FilterStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Filter);