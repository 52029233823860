import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import PrivateLayout from '../layouts/PrivateLayout.jsx';
import { getCookie } from '../helpers/appHelpers';

const PrivateRoute = ({ component: Component, ...rest }) => {
	const loggeInStatus = getCookie('token');
	return (
		<Route {...rest}
			render={
				(props) => (
					loggeInStatus !== "" ?
						(
							<PrivateLayout {...props}>
								<Component {...props} />
							</PrivateLayout>
						)
						: <Redirect to={{ pathname: '/', state: { from: props.location } }} />
				)
			} />
	);
};

PrivateRoute.propTypes = {
	component: PropTypes.elementType,
	location: PropTypes.object
};

export default PrivateRoute;