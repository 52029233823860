import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button, withStyles, CircularProgress } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import * as accountActions from '../../actions/accountActions';
import TextBox from '../../components/TextBox/TextBox.jsx';
import Slider from '../../components/Slider/Slider.jsx';
import AccountStyles from './AccountStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import Localization from '../../constants/localization';
import Logo from '../../assets/images/logo.png';

class ForgotPassword extends Component {
	/**
	 * Constructor
	 * @description Class Constructor
	 * @param {object} props
	 */
	constructor(props) {
		super(props);
		this.state = {
			username: ''
		};
	}

	/**
	 * HandleChange
	 * @description Update input value to state variable
	 * @param {string} name Input name
	 * @param {object} event Input field event
	 */
	handleChange = (name) => (event) => {
		this.setState({ [name]: event.target.value.trim() });
	}

	/**
	 * HandleSubmit
	 * @description Submit Login form
	 */
	handleSubmit() {
		this.props.forgotPassword(this.state);
	}

	render() {
		const { classes, isLoading, theme } = this.props;
		return (
			<Grid container className={classes.container}>
				<Grid item xs={8}>
					<Slider />
				</Grid>
				<Grid item xs={4} className={classes.container}>
					<Grid container direction="column" justifyContent="space-between" className={classes.section}>
						<Grid item align="center">
							<img src={Logo} alt="Logo" className={classes.logo} />
						</Grid>
						<Grid item>
							<Typography variant="h3" className={classes.accountTitle}>
								{'Forgot Password'}
							</Typography>
							<Typography variant="body2" className={classes.accountlabel}>
								{'Enter your email id to reset password'}
							</Typography>
							<ValidatorForm name="loginform" onSubmit={() => this.handleSubmit()}>
								<TextBox
									placeholder={Localization.email}
									onChange={this.handleChange('username')}
									name="username"
									validators={['required', 'isEmail']}
									errorMessages={['Email ID is required', 'Enter valid Email ID']}
									id="username"
									value={this.state.username}
									margin="normal"
									fullWidth
									className={classNames(classes.inputOutline, classes.validationTextBox)}
								/>

								<Grid container direction="row" alignItems="center" justifyContent="space-between" className={classes.btnContainer}>
									<Grid item>
										<Button variant="contained"
											type="submit"
											size="large"
											color="primary"
											className={classes.loginbtn}
											disabled={isLoading}>
											{
												isLoading ?
													<CircularProgress className={classes.progress}
														color="primary"
														size={24}
														thickness={4} /> : `${Localization.submit}`
											}
										</Button>
									</Grid>
									<Grid item align="right">
										<Button disabled={isLoading}
											className={classNames(classes.block, classes.nopadding, classes.inlineButton, classes.bgNone, classes.Linkhover)}
											onClick={() => this.props.history.push('/')}>
											{"Back to login"}
										</Button>
									</Grid>
								</Grid>
							</ValidatorForm>
						</Grid>
						<Grid item align="center">
							<Typography variant="body2" className={classes.copyrightText}>
								{theme.palette.loginCopyrightText}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

ForgotPassword.propTypes = {
	classes: PropTypes.object,
	theme: PropTypes.object,
	isLoading: PropTypes.bool,
	history: PropTypes.object,
	forgotPassword: PropTypes.func
};

/**
 * Map State To Props
 * @description Get Reducer Props value to Props property
 * @param {object} account
 */
const mapStateToProps = ({ account }) => {
	return {
		...account
	};
};

/**
 * Dispatch Props
 * @description dispatch Props actions
 * @param {object} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ ...accountActions }, dispatch);

};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
	...AccountStyles(theme),
	...Styles(theme)
}), { withTheme: true })(ForgotPassword));