import React, { useEffect, useCallback, useState } from 'react';
import { Grid, withStyles, Table, TableHead, TableCell, TableBody, TableRow, Typography, TableSortLabel, Switch, IconButton } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { getAllLicense, deactiveLicense, deactiveEmailProcess } from '../../../src/actions/licenseActions.js';
import Filter from '../../components/Filter/Filter.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { appConstants } from '../../constants/appConstants.jsx';
import { sortTable } from '../../helpers/appHelpers';
import Loader from '../../components/Loaders/Loader.jsx';
import NoResultFound from '../../components/NoResultFound/NoResultFound.jsx';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import moment from 'moment-timezone';
import ToolTipComponent from '../../components/Tooltip/Tooltip.jsx';

const License = (props) => {
    const dispatch = useDispatch();
    const { classes, history, theme } = props;
    const [licenses, setlicenses] = useState([]);
    const [orderBy, setOrderBy] = useState('package_name');
    const [order, setOrder] = useState('asc');
    const [filters, setFilters] = useState({ search: "", filter: "" });
    const [filterLicenses, setFilterLicenses] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const onChangeOrder = useCallback((property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [order, orderBy]);

    const getAllLicenseDetails = useCallback(() => {
        dispatch(getAllLicense()).then((response) => {
            if (response) {
                response = response.map((data) => {
                    return {
                        ...data,
                        license_activate_date: data.license_activate_date ? data.license_activate_date : "",
                        license_expires: data.license_expires ? data.license_expires : "",
                        mac_address: data.mac_address ? data.mac_address : "",
                        ip_address: data.ip_address ? data.ip_address : ""
                    };
                });
                setlicenses([...response]);
                setFilterLicenses([...response]);
            }
            setLoading(false);
        });
    }, [dispatch]);

    useEffect(() => {
        getAllLicenseDetails();
    }, [getAllLicenseDetails])

    const changeActivestatus = (licenseId, licenseStatus) => {
        const index = licenses.findIndex((data) => data.id === licenseId);
        licenses[index].is_active = licenseStatus;
        let status = "Active";
        if (licenses[index].status === "Inactive" || licenses[index].status === "Active") {
            status = "Deactive";
        } else if (licenses[index].status === "Deactive") {
            status = "Inactive";
        }
        licenses[index].status = status;
        licenses[index].is_loading = true;
        setlicenses([...licenses]);
        dispatch(deactiveLicense(licenseId, licenseStatus ? 1 : 0)).then(() => {
            licenses[index].is_loading = false;
            setlicenses([...licenses]);
        });
        dispatch(deactiveEmailProcess(licenseId, licenseStatus ? 1 : 0));
    };

    const onChangeFilter = useCallback((name, value) => {
        filters[name] = value;
        setFilters({ ...filters });
        const search = name === "search" ? value : filters.search;
        const filter = name === "filter" ? value : filters.filter;
        const licenseList = licenses.filter((license) => {
            let searchFilter = true;
            let filterLicense = true;
            if (filters.search.length) {
                searchFilter = (license.license_type && license.license_type.toLowerCase().includes(search.toLowerCase())) || (license.license_key && license.license_key.toLowerCase().includes(search.toLowerCase())) || (license.package_name && license.package_name.toLowerCase().includes(search.toLowerCase())) || (license.status && license.status.toLowerCase().includes(search.toLowerCase())) || (license.client_name && license.client_name.toLowerCase().includes(search.toLowerCase()));
            }
            if (filters.filter.length) {
                filterLicense = license.is_active === (filter === "active");
            }
            return searchFilter && filterLicense;
        });
        setFilterLicenses([...licenseList]);
    }, [filters, licenses]);

    const resetFilter = () => {
        setFilters({ search: "", filter: "" });
        setFilterLicenses([...licenses]);
    };

    const copyToClipboard = (text) => {
        const textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    }

    return (
        <Grid className={classes.container}>
            <ValidatorForm className={classes.container}>
                <Filter
                    history={history}
                    title="Add License"
                    name="License"
                    showAdd
                    url="createlicense"
                    showFilter
                    filterOptions={appConstants.licenseFilters}
                    showSearch
                    filters={filters}
                    onChangeFilter={(name, value) => onChangeFilter(name, value)}
                    resetFilter={() => resetFilter()} />
                <Grid className={classNames(classes.licenceTable, classes.listContainer, classes.mainContainer)}>
                    {
                        filterLicenses.length > 0 &&
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {
                                        appConstants.licenseHeaders.map((header, index) =>
                                            <TableCell key={index}>
                                                {
                                                    header.name !== 'Actions' ?
                                                        <TableSortLabel
                                                            active={orderBy === header.value}
                                                            direction={orderBy === header.value ? order : 'asc'}
                                                            onClick={() => onChangeOrder(header.value)}
                                                        >
                                                            <Typography variant="body1" className={classes.tableHeader}>
                                                                {header.name}
                                                            </Typography>
                                                        </TableSortLabel>
                                                        :
                                                        <Typography variant="body1" className={classes.tableHeader}>
                                                            {header.name}
                                                        </Typography>
                                                }
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortTable(filterLicenses, order, orderBy).map((license, index) =>
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Typography>{license.package_name ? license.package_name : "NA"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Grid className={classes.flex}>
                                                <Typography>{license.license_key ? license.license_key : "NA"}</Typography>
                                                <IconButton fontSize="small" color="primary" className={classNames(classes.nopadding, classes.marginLeft10)}>
                                                    <FileCopyIcon onClick={() => copyToClipboard(license.license_key)} />
                                                </IconButton>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{license.license_type ? license.license_type : ""}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{license.client_name ? license.client_name : ""}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{license.sub_client_name ? license.sub_client_name : ""}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{license.status ? license.status : "Inactive"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{license.license_activate_date ? moment(license.license_activate_date).format('YYYY-MM-DD HH:mm:ss') : "NA"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{license.mac_address ? license.mac_address : "NA"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{license.ip_address ? license.ip_address : "NA"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{license.license_expires ? moment(license.license_expires).format('YYYY-MM-DD HH:mm:ss') : "NA"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Grid className={classes.flex}>
                                                <Switch
                                                    checked={license.is_active}
                                                    onChange={(event) => changeActivestatus(license.id, event.target.checked)}
                                                    disabled={license.is_expired || license.is_loading}
                                                />
                                                <ToolTipComponent title="Edit" placement="bottom" arrow>
                                                    <IconButton className={classNames(classes.nopadding, classes.marginLeft10)} onClick={() => history.push(`/editlicense/${license.id}`)}>
                                                        <svg
                                                            version="1.1"
                                                            id="Layer_1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="21.349"
                                                            height="23.349"
                                                            x="0px"
                                                            y="0px"
                                                            viewBox="0 0 32 32"
                                                        >
                                                            <g id="edit" transform="translate(0 0)">
                                                                <path
                                                                    fill={theme.palette.primary.main}
                                                                    stroke={theme.palette.primary.main}
                                                                    strokeWidth="0.3"
                                                                    strokeMiterlimit="10"
                                                                    d="M27.2,8L24,4.8c-0.8-0.8-2.2-0.8-3,0L6.5,19.4c-0.1,0.1-0.1,0.1-0.1,0.2l-2.1,7.5c-0.1,0.3,0.1,0.6,0.4,0.7
                        c0.1,0,0.2,0,0.3,0l7.5-2.1c0.1,0,0.2-0.1,0.2-0.1l14.6-14.4C28,10.2,28,8.9,27.2,8L27.2,8z M18.6,8.7l2,2L10.2,21l-0.7-1.5
                        c-0.1-0.2-0.3-0.3-0.5-0.3H8.2L18.6,8.7z M5.5,26.5L6.2,24L8,25.8L5.5,26.5z M11.7,24.7l-2.6,0.7l-2.6-2.6l0.7-2.6h1.4l0.9,1.8
                        c0.1,0.1,0.1,0.2,0.2,0.2l1.8,0.9L11.7,24.7L11.7,24.7z M12.8,23.8V23c0-0.2-0.1-0.4-0.3-0.5L11,21.7l10.3-10.3l2,2L12.8,23.8z
                        M26.4,10.3l-2.4,2.3l-4.7-4.7l2.3-2.4c0.4-0.4,1.1-0.4,1.5,0l3.2,3.2C26.8,9.2,26.8,9.9,26.4,10.3L26.4,10.3z"
                                                                />
                                                            </g>
                                                        </svg>
                                                    </IconButton>
                                                </ToolTipComponent>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    }
                    {
                        !isLoading && filterLicenses.length === 0 &&
                        <NoResultFound />
                    }
                    {isLoading && <Loader />}
                </Grid>
            </ValidatorForm>
        </Grid>
    )

};

export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(License);