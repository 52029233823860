import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, withStyles, Button, Typography, IconButton } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import ProfileStyles from './ProfileStyles.jsx';
import ProfileImage from '../../assets/images/avatar.svg';
import { getProfile, updateProfile, updateUserProfile } from '../../actions/settingActions.js'
import Loader from '../Loaders/Loader.jsx';
import { appConstants } from '../../constants/appConstants';
import { returnImage } from '../../helpers/appHelpers';

const Profile = (props) => {
    const { classes, theme } = props;
    const [profile, setProfile] = useState({});
    const [userProfile, setUserProfile] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [isProfileLoading, setProfileLoading] = useState(false);
    const [isChanged, setChanged] = useState(false);
    const dispatch = useDispatch();

    const getUserProfile = useCallback(() => {
        dispatch(getProfile()).then((response) => {
            if (response) {
                setProfile({ ...response });
                setUserProfile({ ...response });
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getUserProfile();
    }, [getUserProfile]);

    const onSubmit = () => {
        setLoading(true);
        setChanged(false);
        dispatch(updateProfile(profile.id, profile)).then(() => {
            setLoading(false);
            setUserProfile({ ...profile });
        });
    };

    const onClear = () => {
        setProfile({ ...userProfile });
    };

    const onChange = (name, value) => {
        const regex = /^[0-9]*$/;
        if (name === "mobile" && !regex.test(value)) {
            return false;
        }
        profile[name] = value;
        setProfile({ ...profile });
        setChanged(true);
    };

    const avatarUpload = (files) => {
        setProfileLoading(true);
        const file = new FormData();
        file.append('file', files[0]);
        dispatch(updateUserProfile(file)).then((response) => {
            setProfileLoading(false);
            if (response) {
                profile.avatar = response;
                setUserProfile({ ...profile });
                setProfile({ ...profile });
            }
        });
    };


    const removeProfileImage = () => {
        profile.avatar = null;
        setProfile({ ...profile });
        dispatch(updateProfile(profile, profile.id));
    };

    return (
        <ValidatorForm form="My profile" onSubmit={() => onSubmit()}>
            <Grid container className={classes.profileContainer}>
                <Grid item xs={3}>
                    <Grid className={classes.profileImage} style={{ backgroundSize: (profile && profile.avatar) ? "cover" : "contained", backgroundImage: `url(${(profile && profile.avatar) ? returnImage(appConstants.API_BASE_URL, profile.avatar) : ProfileImage})` }}>
                        {isProfileLoading && <Loader />}
                        {
                            profile.avatar &&
                            <IconButton className="remove-profile" onClick={() => removeProfileImage()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="19.408" height="22" viewBox="0 0 19.408 22">
                                    <g transform="translate(-76.035 -52)">
                                        <path d="M128.865,166H114.641a.549.549,0,0,0-.536.61l1.459,12.768a2.789,2.789,0,0,0,2.77,2.474h7.082a2.777,2.777,0,0,0,2.775-2.525L129.4,166.6a.554.554,0,0,0-.138-.421A.546.546,0,0,0,128.865,166Zm-1.748,13.219a1.708,1.708,0,0,1-1.7,1.555h-7.082a1.715,1.715,0,0,1-1.7-1.523l-1.39-12.173h13.028Z" transform="translate(-36.014 -107.853)" fill={theme.palette.primary.main} />
                                        <path d="M94.9,54.7H89.19v-1.03A1.682,1.682,0,0,0,87.51,52H83.968a1.682,1.682,0,0,0-1.68,1.667V54.7H76.574a.539.539,0,0,0,0,1.078H94.9a.539.539,0,0,0,0-1.078Zm-6.792,0H83.366v-1.03a.6.6,0,0,1,.6-.588H87.51a.6.6,0,0,1,.6.588Z" fill={theme.palette.primary.main} />
                                    </g>
                                </svg>
                            </IconButton>
                        }
                        <Dropzone accept="image/*" onDrop={(acceptedFiles, rejectedFiles) => avatarUpload(acceptedFiles)}>
                            {
                                ({ getRootProps, getInputProps }) => (
                                    <Typography variant="body2" className={!profile.avatar ? classes.profileUploadTxt : 'image'} {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        Upload Image
                                    </Typography>
                                )
                            }
                        </Dropzone>
                    </Grid>
                </Grid>
                <Grid item xs={9}>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <TextBox
                                        label="First Name"
                                        name="first_name"
                                        value={profile.first_name ? profile.first_name : ''}
                                        validators={['required']}
                                        errorMessages={['First Name is required']}
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <TextBox
                                        label="Last Name"
                                        name="last_name"
                                        value={profile.last_name ? profile.last_name : ''}
                                        validators={['required']}
                                        errorMessages={['Last Name is required']}
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <TextBox
                                        label="Email"
                                        name="email"
                                        type="email"
                                        value={profile.email ? profile.email : ''}
                                        validators={['required', 'isEmail']}
                                        errorMessages={['Email is required', 'Enter valid Email Address']}
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <TextBox
                                        label="Phone"
                                        name="mobile"
                                        value={profile.mobile ? profile.mobile : ''}
                                        validators={['required', 'maxStringLength:10', 'minStringLength:10']}
                                        errorMessages={['Phone No is required', 'Enter Valid Phone No', 'Enter Valid Phone No']}
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            isChanged &&
                            <Grid item xs={12}>
                                <Button disabled={isLoading} variant="contained" color="primary" type="submit" className={classnames(classes.actionButtons, classes.submitBtn)}>
                                    Save
                                    {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                </Button>
                                <Button disabled={isLoading} onClick={() => onClear()} className={classnames(classes.cancelButton, classes.actionButtons)}>
                                    Cancel
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </ValidatorForm >
    );
};

Profile.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...ProfileStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Profile);