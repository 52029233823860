import React, { useState, useEffect, useCallback } from "react";
import { Grid, withStyles, MenuItem, Typography, Switch, Button, IconButton, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { ValidatorForm } from 'react-material-ui-form-validator';
import classnames from 'classnames';
import { createPackageDetail, getPackageById, updatePackage, deletePackage } from '../../actions/packageActions';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from "../../layouts/Styles.jsx";
import CreatePackageStyles from "./CreatePackageStyles.jsx";
import { appConstants } from "../../constants/appConstants";
import Loader from '../Loaders/Loader.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import LicensePackage from '../LicensePackage/LicensePackage.jsx';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';


const CreatePackage = (props) => {
    const { classes, packageId, history, theme } = props;
    const [defaultConfig] = useState({ is_active: true, module_config: { inbound_connectors: [], input_connectors: [], outbound_connectors: [] }, license_type: appConstants.licenseTypes[0], license_mode: appConstants.licenseModes[0], is_select_connector: false });
    const [error, setError] = useState(false);
    const [defaultPackageConfig, setDefaultPackageConfig] = useState({ ...defaultConfig });
    const [packageInfo, setPackageInfo] = useState({ ...defaultConfig });
    const [isLoading, setLoading] = useState(false);
    const [connectionConfigError, setConnectionConfigError] = useState(false);
    const [inboundConfigError, setInboundConfigError] = useState(false);
    const [outboundConfigError, setoutboundConfigError] = useState(false);
    const [inputConnectionError, setInputConnectionError] = useState(false);
    const [isShowDeleteDialog, setShowDeleteDialog] = useState(false);
    const connections = useSelector(({ license }) => license.connections);
    const packages = useSelector(({ packageInfo }) => packageInfo.packages);
    const dispatch = useDispatch();


    const getPackage = useCallback(() => {
        dispatch(getPackageById(packageId)).then((response) => {
            if (response) {
                setDefaultPackageConfig({ ...response });
                setPackageInfo({ ...response });
            }
        });
    }, [dispatch, packageId]);


    useEffect(() => {
        if (packageId) {
            getPackage();
        }
    }, [getPackage, packageId]);

    const validateDuplicateName = (value) => {
        const duplicate = packages.filter((data) => data.name.toLowerCase() === value.toLowerCase());
        setError(duplicate.length !== 0);
    };

    const onChangeProperty = (property, value, subModule) => {
        if (subModule) {
            const moduleConfig = packageInfo.module_config ? packageInfo.module_config : {};
            moduleConfig[property] = value;
            if (property === "curate" && value) {
                moduleConfig.profile = value;
            }
            packageInfo.module_config = { ...moduleConfig };
            if (value && (property === "scan" || property === "pull") && connectionConfigError) {
                setConnectionConfigError(false);
            }
        } else {
            const regex = /^[0-9]*$/;
            if ((property === "no_of_connectors" || property === "no_of_organizations" || property === "time_period") && !regex.test(value)) {
                return false;
            }
            if (property === "name") {
                value = value.trim() !== "" ? value : value.trim();
            }
            packageInfo[property] = value;
            if (property === "is_active") {
                onUpdatePackage(value)
            }
        }
        setPackageInfo({ ...packageInfo });
    };

    const onChangeConnection = (name, value) => {
        if (!packageInfo.module_config[name]) {
            packageInfo.module_config[name] = [];
        }
        const index = packageInfo.module_config[name].indexOf(value);
        if (index !== -1) {
            packageInfo.module_config[name].splice(index, 1);
        } else {
            packageInfo.module_config[name].push(value);
        }
        if (name === "input_connectors" && packageInfo.module_config[name].length !== 0 && inputConnectionError && packageInfo.module_config[name].length === packageInfo.no_of_connectors && inputConnectionError) {
            setInputConnectionError(false);
        }
        if (name === "inbound_connectors" && packageInfo.module_config[name].length !== 0 && inboundConfigError) {
            setInboundConfigError(false);
        }
        if (name === "outbound_connectors" && packageInfo.module_config[name].length !== 0 && outboundConfigError) {
            setoutboundConfigError(false);
        }
        setPackageInfo({ ...packageInfo });
    };

    const onReset = () => {
        setPackageInfo({ ...defaultPackageConfig });
    };

    const onClear = () => {
        setPackageInfo({ ...defaultConfig });
    };

    const onUpdatePackage = (isActive) => {
        const requestParams = {
            'is_active': isActive
        };
        dispatch(updatePackage(packageId, requestParams));
    };

    const onSavePackage = () => {
        debugger;
        if (packageInfo.no_of_connectors === "" || packageInfo.no_of_connectors === 0 || packageInfo.no_of_connectors > connections.length) {
            return false;
        }

        if (!packageInfo.is_select_connector){
            if (parseInt(packageInfo.no_of_connectors) < parseInt(packageInfo.allowed_no_of_connectors)) {
                return false;
            }
        }

        if (parseInt(packageInfo.no_of_connectors) !== packageInfo?.module_config?.input_connectors.length) {
            return false;
        }

        if (packageInfo.is_select_connector && (!packageInfo.module_config?.input_connectors || packageInfo?.module_config?.input_connectors.length === 0 || !(packageInfo?.module_config?.input_connectors.length >= packageInfo.no_of_connectors && packageInfo?.module_config?.input_connectors.length <= packageInfo.no_of_connectors))) {
            setInputConnectionError(true);
            return false;
        }

        if (inputConnectionError) {
            setInputConnectionError(false);
        }

        if (!packageInfo.module_config.pull && !packageInfo.module_config.scan) {
            setConnectionConfigError(true);
            return false;
        }

        if (connectionConfigError) {
            setConnectionConfigError(false);
        }

        if (packageInfo.module_config.inbound_connectors && packageInfo.module_config.inbound_connectors.length === 0) {
            setInboundConfigError(true);
            return false;
        } else {
            setInboundConfigError(false);
        }

        if (packageInfo.module_config.outbound_connectors && packageInfo.module_config.outbound_connectors.length === 0) {
            setoutboundConfigError(true);
            return false;
        } else {
            setoutboundConfigError(false);
        }
        if (error) {
            return false;
        }
        setLoading(true);
        dispatch(packageId ? updatePackage(packageId, packageInfo) : createPackageDetail(packageInfo)).then((response) => {
            if (response) {
                history.push('/package');
            }
            setLoading(false);
        });
    };

    const onDelete = () => {
        const requestParams = { 'packages': [packageInfo.id] };
        dispatch(deletePackage(requestParams)).then(() => {
            history.push('/package');
        });
    };


    return (
        <Grid container>
            <Grid className={classes.deleteSection}>
                <Switch size="small"
                    color="secondary"
                    name="is_active"
                    checked={Boolean(packageInfo.is_active)}
                    value={packageInfo.is_active}
                    onChange={(event) => onChangeProperty(event.target.name, !packageInfo.is_active)} />
                {
                    packageId &&
                    <ToolTipComponent title="Delete" placement="bottom" arrow>
                        <IconButton className={classnames(classes.nopadding, classes.marginLeft10)} onClick={() => setShowDeleteDialog(true)}>
                            <svg version="1.1"
                                id="Layer_1"
                                width="18"
                                height="18"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                viewBox="0 0 32 32">
                                <g id="Delete">
                                    <path fill={theme.palette.primary.main}
                                        stroke={theme.palette.primary.main}
                                        strokeWidth="0.92"
                                        strokeMiterlimit="10"
                                        d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
		L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
		L22.3,25.8z" />
                                    <path fill={theme.palette.primary.main}
                                        stroke={theme.palette.primary.main}
                                        strokeWidth="0.92"
                                        strokeMiterlimit="10"
                                        d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
		c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
		c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                </g>
                            </svg>
                        </IconButton>
                    </ToolTipComponent>
                }
            </Grid>
            <ValidatorForm name="createpackage" onSubmit={() => onSavePackage()} className={classes.container}>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>Package Name</Typography>
                        <TextBox
                            name="name"
                            value={packageInfo.name ? packageInfo.name : ""}
                            onChange={(event) => onChangeProperty(event.target.name, event.target.value)}
                            onBlur={(event) => validateDuplicateName(event.target.value)}
                            fullWidth
                            validators={['required', 'minStringLength:4', 'maxStringLength:250', 'matchRegexp:^[a-zA-Z0-9 ]*$']}
                            errorMessages={['Package Name is required', 'Package Name Shoud be More than 4 Character', 'Maximum Character count 250', 'Special Character will not allowed']}
                            error={error}
                            helperText={error ? "Duplicate Name Not Allowed" : ""}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>License Mode</Typography>
                        <TextBox
                            name="license_mode"
                            value={packageInfo.license_mode ? packageInfo.license_mode : appConstants.licenseModes[0]}
                            fullWidth
                            validators={['required']}
                            errorMessages={['License Mode is required']}
                            select
                            SelectProps={
                                {
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }
                                }
                            }
                            onChange={(event) => onChangeProperty(event.target.name, event.target.value)}
                        >
                            {
                                appConstants.licenseModes.map((type, index) =>
                                    <MenuItem key={index} value={type}>
                                        {type}
                                    </MenuItem>
                                )
                            }
                        </TextBox>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>License Type</Typography>
                        <TextBox
                            name="license_type"
                            value={packageInfo.license_type ? packageInfo.license_type : appConstants.licenseTypes[0]}
                            fullWidth
                            validators={['required']}
                            errorMessages={['License Type is required']}
                            select
                            SelectProps={
                                {
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }
                                }
                            }
                            onChange={(event) => onChangeProperty(event.target.name, event.target.value)}
                        >
                            {
                                appConstants.licenseTypes.map((type, index) =>
                                    <MenuItem key={index} value={type}>
                                        {type}
                                    </MenuItem>
                                )
                            }
                        </TextBox>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>Description</Typography>
                        <TextBox
                            name="description"
                            value={packageInfo.description ? packageInfo.description : ""}
                            onChange={(event) => onChangeProperty(event.target.name, event.target.value)}
                            fullWidth
                            multiline
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="h5" variant="h5">License Configuration</Typography>
                        <Grid container className={classes.marginTop20} spacing={3}>
                            <Grid item xs={3}>
                                <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>No Of Connections</Typography>
                                <TextBox
                                    placeholder="Connector Count"
                                    name="no_of_connectors"
                                    validators={['required', 'minNumber:1', `maxNumber:${connections.length}`]}
                                    errorMessages={['Connectors is required', 'Connetors Limit Must Greater than 0', 'Connectors Limit Exceed']}
                                    value={packageInfo.no_of_connectors ? packageInfo.no_of_connectors : ""}
                                    onChange={(event) => onChangeProperty(event.target.name, event.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            {
                                !packageInfo.is_select_connector &&
                                <Grid item xs={3}>
                                    <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>Allowed No Of Connections</Typography>
                                    <TextBox
                                        placeholder="Allowed Connector Count"
                                        name="allowed_no_of_connectors"
                                        validators={['required', 'minNumber:1', `maxNumber:${packageInfo.no_of_connectors? packageInfo.no_of_connectors: 1}`]}
                                        errorMessages={['Connectors is required', 'Connetors Limit Must Greater than 0', 'Connectors Limit Exceed']}
                                        value={packageInfo.allowed_no_of_connectors ? packageInfo.allowed_no_of_connectors : ""}
                                        onChange={(event) => onChangeProperty(event.target.name, event.target.value)}
                                        disabled={packageInfo.is_select_connector}
                                        fullWidth
                                    />
                                </Grid>
                            }
                            <Grid item xs={3}>
                                <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>Duration Of License Validity</Typography>
                                <TextBox
                                    placeholder="Duration in days "
                                    name="time_period"
                                    validators={['required', 'minNumber:1', 'maxNumber: 365']}
                                    errorMessages={['License Validity is required', 'License Validity Must Greater than 0', 'License Validity Must Less than 365']}
                                    value={packageInfo.time_period ? packageInfo.time_period : ""}
                                    onChange={(event) => onChangeProperty(event.target.name, event.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            {
                                packageInfo.license_type === appConstants.licenseTypes[1] &&
                                <Grid item xs={3}>
                                    <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>No Of Organizations</Typography>
                                    <TextBox
                                        placeholder="Organization Count"
                                        name="no_of_organizations"
                                        validators={['required', 'minNumber:1', 'maxNumber:1000']}
                                        errorMessages={['Organization is required', 'Organization Limit Must Greater than 0', 'Organizations Limit Exceed']}
                                        value={packageInfo.no_of_organizations ? packageInfo.no_of_organizations : ""}
                                        onChange={(event) => onChangeProperty(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            }
                             <Grid item xs={3}>
                                <Grid className={classes.formGroup}>
                                    <FormControlLabel
                                        className={classnames(classes.formLabel)}
                                        control={<Switch size="small" color="secondary" name="is_select_connector" checked={Boolean(packageInfo.is_select_connector)} value={packageInfo.is_select_connector} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.is_select_connector)} />}
                                        label="Select Predefined connector for the user"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <LicensePackage
                            packageInfo={packageInfo}
                            connectionConfigError={connectionConfigError}
                            onChangeProperty={(property, value, config) => onChangeProperty(property, value, config)}
                            inboundConfigError={inboundConfigError}
                            outboundConfigError={outboundConfigError}
                            inputConnectionError={inputConnectionError}
                            onChangeConnection={(property, value) => onChangeConnection(property, value)}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.marginTop30}>
                        <Button disabled={isLoading} variant="contained" color="primary" type="submit" className={classes.submitBtn}>
                            Save
                            {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                        </Button>
                        <Button className={classes.cancelButton}
                            style={{ marginLeft: 20 }}
                            disabled={isLoading}
                            onClick={() => packageId ? onReset() : onClear()}>
                            {packageId ? 'Reset' : 'Clear'}
                        </Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
            {
                isShowDeleteDialog &&
                <AlertDialog title="Delete License Package"
                    message={`Are you sure you want to delete the selected license package ${packageInfo ? packageInfo.name : ''}?`}
                    okButtonText="OK"
                    cancelButtonText="Cancel"
                    show={Boolean(isShowDeleteDialog)}
                    onClickOK={() => onDelete()}
                    onClickCancel={() => setShowDeleteDialog(false)} />
            }
        </Grid>
    )

};


CreatePackage.propTypes = {
    classes: PropTypes.object,
    packageId: PropTypes.string,
    history: PropTypes.object,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...CreatePackageStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(CreatePackage);