/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Grid, withStyles, Popover, MenuItem, Typography, Divider } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import Styles from '../../layouts/Styles.jsx';
import CommonStyles from './CommonStyles.jsx';

import Logo from '../../assets/images/headerLogo.svg';
import { appConstants } from '../../constants/appConstants';
import { returnImage } from '../../helpers/appHelpers';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import { navigatePage } from '../../actions/accountActions';

const Header = (props) => {
	const { classes, logout, theme, history } = props;
	const [open, enablePopover] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const dispatch = useDispatch();
	const navigateReducePath = useSelector(({ account }) => account.navigationPath);
	const navigationPath = navigateReducePath !== "" ? navigateReducePath : sessionStorage.getItem('navigationPath');
	let selectedPage = navigationPath ? navigationPath : "license";

	const navigate = (path, title) => {
		if (path !== "") {
			sessionStorage.setItem('navigationPath', title);
			dispatch(navigatePage(title));
			history.push(`/${path}`);
		}
	};

	const openPopOver = (event) => {
		setAnchorEl(event.target);
		enablePopover(true);
	};
	
	const handleToggle = () => {
		enablePopover(!open);
	};

	return (
		<AppBar position="static" color="inherit" className={classes.LeftMenu}>
			<Toolbar>
				<Grid container className={classes.container} alignContent="space-between" justifyContent="space-evenly">

					<Grid item>
						<Grid item className={classes.HeaderMenuicon}>
							<TooltipComponent title="DQLabs" placement="right" arrow>
								<a>
									<img className={classes.logo} src={theme && theme.palette.logo !== "" ? returnImage(appConstants.API_BASE_URL, theme.palette.logo) : Logo} alt="logo" />
								</a>
							</TooltipComponent>
						</Grid>
						<Grid item className={classes.Menuicon}>
							<TooltipComponent title="License" placement="right" arrow>
								<a onClick={() => navigate('license', 'license')} className={selectedPage === 'license' ? 'active' : ''}>
									<svg xmlns="http://www.w3.org/2000/svg" className={classes.MenuiconImg} width="22.6" height="21.9" viewBox="0 0 22.6 21.9" fill="#afb2b3">
										<g transform="translate(-0.2 -1)">
											<rect id="Rectangle_1221" data-name="Rectangle 1221" width="11.6" height="2" transform="translate(5.2 11)" />
											<rect id="Rectangle_1223" data-name="Rectangle 1223" width="5.6" height="2" transform="translate(11.2 1.2)" />
											<rect id="Rectangle_1225" data-name="Rectangle 1225" width="2.6" height="2.4" transform="translate(20.2 1)" />
											<rect id="Rectangle_1226" data-name="Rectangle 1226" width="2.6" height="2.4" transform="translate(20.2 10.7)" />
											<rect id="Rectangle_1228" data-name="Rectangle 1228" width="2.6" height="2.4" transform="translate(0.2 10.7)" />
											<rect id="Rectangle_1227" data-name="Rectangle 1227" width="2.6" height="2.4" transform="translate(20.2 20.5)" />
											<rect id="Rectangle_1222" data-name="Rectangle 1222" width="2" height="21.6" transform="translate(10.8 1.2)" />
											<rect id="Rectangle_1224" data-name="Rectangle 1224" width="5.6" height="2" transform="translate(11.2 20.8)" />
										</g>
									</svg>
								</a>
							</TooltipComponent>
						</Grid>
						<Grid item className={classes.Menuicon}>
							<TooltipComponent title="Package" placement="right" arrow>
								<a onClick={() => navigate('package', 'package')} className={selectedPage === 'package' ? 'active' : ''}>
									<svg version="1.1" viewBox="0 0 32 32" className={classes.MenuiconImg} fill="#afb2b3">
										<path
											d="M17.1,12.8l0.3,0.9l0.5-0.8c0.2-0.4,0.5-0.7,0.9-1c1-0.9,2.4-1,3.1-1.1v1.9h2H26v0h2v0v-2V8.3V6.6V6.3h-6.1v0.3
    v1.7v0.2l0,0c-1.3,0-3.1,0.3-4.6,1.6c-0.3,0.3-0.6,0.5-0.8,0.8l-0.2,0.3l0.2,0.3C16.7,11.9,16.9,12.3,17.1,12.8z M23.9,8.3H26v2.4
    h-2.1V8.3z M15,19.2l-0.3-0.9l-0.5,0.8c-0.2,0.4-0.5,0.7-0.9,1c-1,0.9-2.4,1-3.2,1.1v-1.5v-0.3H4v0.3v1.7v2.4v2h2h2.1v0h2v0v-2v-0.2
    l0,0c1.1,0,3-0.3,4.6-1.6c0.3-0.3,0.6-0.5,0.8-0.8l0.2-0.3l-0.2-0.3C15.4,20.1,15.1,19.7,15,19.2z M8.1,23.7H6v-2.4h2.1V23.7z
     M21.9,19.3v0.3v1.5c-0.7,0-0.9-0.1-0.9-0.1c-0.2,0-0.5-0.1-0.7-0.2c-0.7-0.2-1.3-0.6-1.8-1.1c-0.5-0.5-0.9-1.2-1.1-1.9
    c-0.2-0.6-0.2-1.3-0.2-1.9c0-0.4,0-0.8-0.1-1.2c-0.2-1.9-1-3.5-2.4-4.7c-0.9-0.7-2-1.2-3.2-1.5c-0.4-0.1-0.9-0.1-1.4-0.1l0,0V8.3
    V6.6V6.3H4v0.3v1.7v2.4v2h2h2.1v0h2v0v-1.8c1.2,0,2.2,0.3,2.9,0.8c1.2,0.9,1.9,2.2,1.9,4.2c0,2.3,0.5,3.8,1.5,5.1
    c1.2,1.5,3.1,2.4,5.2,2.5c0.1,0,0.2,0,0.3,0v0.3v2h2H26v0h2v0v-2v-2.4v-1.7v-0.3H21.9z M8.1,10.6H6V8.3h2.1V10.6z M26,23.7h-2.1
    v-2.4H26V23.7z" />
									</svg>
								</a>
							</TooltipComponent>
						</Grid>
						<Grid item className={classes.MenuiconHelp}>
							<TooltipComponent title="User" placement="right" arrow>
								<a onClick={(event) => openPopOver(event)} className={selectedPage === 'settings' ? classes.MenuIconActive : ''}>
									<svg xmlns="http://www.w3.org/2000/svg" className={classes.MenuiconUserImg} width="30.022" height="30.022" viewBox="0 0 30.022 30.022">
										<g id="Group_2555" data-name="Group 2555" transform="translate(-642 -1635.287)">
											<g id="Bg" transform="translate(642 1635.287)" fill="none">
												<path d="M15.011,0A15.011,15.011,0,0,1,30.022,15.011h0A15.011,15.011,0,0,1,0,15.011H0A15.011,15.011,0,0,1,15.011,0Z" stroke="none" />
												<path id="fillPath" d="M 15.01095008850098 1.999996185302734 C 11.53560066223145 1.999996185302734 8.268260955810547 3.353366851806641 5.810819625854492 5.810815811157227 C 3.353370666503906 8.268257141113281 2 11.53559684753418 2 15.01126670837402 C 2 18.48661613464355 3.353370666503906 21.75395584106445 5.810819625854492 24.21139717102051 C 8.268260955810547 26.66884613037109 11.53560066223145 28.022216796875 15.01095008850098 28.022216796875 C 18.48630142211914 28.022216796875 21.75363922119141 26.66884613037109 24.21108055114746 24.21139717102051 C 26.66852951049805 21.75395584106445 28.02190017700195 18.48661613464355 28.02190017700195 15.01094627380371 C 28.02190017700195 11.53559684753418 26.66852951049805 8.268257141113281 24.21108055114746 5.810815811157227 C 21.75363922119141 3.353366851806641 18.48630142211914 1.999996185302734 15.01095008850098 1.999996185302734 M 15.01095008850098 -3.814697265625e-06 C 23.30126953125 -3.814697265625e-06 30.02190017700195 6.720626831054688 30.02190017700195 15.01094627380371 L 30.02190017700195 15.01126670837402 C 30.02190017700195 23.30158615112305 23.30126953125 30.022216796875 15.01095008850098 30.022216796875 C 6.720630645751953 30.022216796875 0 23.30158615112305 0 15.01126670837402 L 0 15.01094627380371 C 0 6.720626831054688 6.720630645751953 -3.814697265625e-06 15.01095008850098 -3.814697265625e-06 Z" stroke="none" fill="#afb2b3" />
											</g>
											<ellipse id="Oval" cx="3.184" cy="3.141" rx="3.184" ry="3.141" transform="translate(653.821 1643.52)" fill="#afb2b3" />
											<path id="Shape" d="M5.772,0A5.733,5.733,0,0,0,0,5.694H11.543A5.733,5.733,0,0,0,5.772,0Z" transform="translate(651.234 1651.373)" fill="#afb2b3" />
										</g>
									</svg>
								</a>
							</TooltipComponent>
						</Grid>
					</Grid>
				</Grid>
			</Toolbar>
			<Popover
				open={open}
				onClose={handleToggle}
				anchorEl={anchorEl}
				anchorOrigin={
					{
						vertical: 'center',
						horizontal: 'right'
					}
				}
				transformOrigin={
					{
						vertical: 'bottom',
						horizontal: 'left'
					}
				}>
				<MenuItem className={classes.logout}
					onClick={
						() => {
							navigate('settings', 'settings');
							enablePopover(false);
						}
					}>
					<Typography className={classes.logoutTxt}>
						{'Settings'}
					</Typography>
				</MenuItem>
				{/* <MenuItem className={classes.logout}
					onClick={
						() => {
							navigate('aboutus', 'settings');
							enablePopover(false);
						}
					}>
					<Typography className={classes.logoutTxt}>
						{'About Us'}
					</Typography>
				</MenuItem> */}
				<Divider />
				<MenuItem className={classes.logout} onClick={() => logout()}>
					<Typography className={classes.logoutTxt}>
						{'Logout'}
					</Typography>
					<div className={classes.horzspace} />
					<div>
						<svg xmlns="http://www.w3.org/2000/svg" width="13.241" height="16" viewBox="0 0 13.241 16">
							<g id="shutdown1" transform="translate(0 0)">
								<path id="Path_218" data-name="Path 218" d="M6.621,108.218A6.622,6.622,0,0,1,4.865,95.211a.552.552,0,0,1,.292,1.064A5.517,5.517,0,1,0,8.2,96.31a.552.552,0,1,1,.316-1.057,6.622,6.622,0,0,1-1.9,12.965Zm0,0" transform="translate(0 -92.217)" fill="#f44436" />
								<path id="Path_219" data-name="Path 219" d="M194.758,7.173a.552.552,0,0,1-.552-.552V.552a.552.552,0,1,1,1.1,0V6.621A.551.551,0,0,1,194.758,7.173Zm0,0" transform="translate(-188.138)" fill="#f44436" />
							</g>
						</svg>
					</div>
				</MenuItem>
			</Popover>
		</AppBar >
	);
};

Header.propTypes = {
	classes: PropTypes.object,
	logout: PropTypes.func,
	history: PropTypes.object,
	theme: PropTypes.object
};

export default withStyles((theme) => ({
	...Styles(theme),
	...CommonStyles(theme)
}), { withTheme: true })(Header);