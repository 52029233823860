import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, withStyles, FormHelperText, Typography, FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import classnames from 'classnames';

import Styles from "../../layouts/Styles.jsx";
import createPackageStyles from '../CreatePackage/CreatePackageStyles';


const LicensePackage = (props) => {
    const { classes, packageInfo, connectionConfigError, onChangeProperty, onChangeConnection, inboundConfigError, outboundConfigError } = props;
    const inputConnections = useSelector(({ license }) => license.inputConnection);
    const outputConnections = useSelector(({ license }) => license.outputConnection);
    const connections = useSelector(({ license }) => license.connections);
    return (
        <Grid>
            {
                <Grid item xs={12} className={classnames(classes.subModuleSection, classes.marginTop20)}>
                    <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>Connectors</Typography>
                    {(packageInfo?.module_config?.input_connectors?.length === 0) && <FormHelperText className={classes.errorTxt}>Please Select atleast one connector</FormHelperText>}
                    {(packageInfo?.module_config?.input_connectors?.length !== 0 && packageInfo?.module_config?.input_connectors?.length < packageInfo.no_of_connectors) && <FormHelperText className={classes.errorTxt}>Select remaining {packageInfo.no_of_connectors - packageInfo?.module_config?.input_connectors?.length}</FormHelperText>}
                    {(packageInfo?.module_config?.input_connectors?.length !== 0 && packageInfo?.module_config?.input_connectors?.length > packageInfo.no_of_connectors) && <FormHelperText className={classes.errorTxt}>Maximum Connection Limit Reached</FormHelperText>}
                    <Grid className={classes.formGroup}>
                        <FormGroup row>
                            {
                                connections.map((type, index) =>
                                    <FormControlLabel
                                        key={index}
                                        className={classnames(classes.formLabelSection, classes.formLabel)}
                                        control={<Switch size="small" color="secondary" onChange={() => onChangeConnection("input_connectors", type.id)} checked={packageInfo.module_config.input_connectors ? packageInfo.module_config.input_connectors.indexOf(type.id) !== -1 : false} />}
                                        label={type.name}
                                    />
                                )
                            }
                        </FormGroup>
                    </Grid>
                </Grid>
            }
            <Grid item xs={12} className={classnames(classes.subModuleSection, classes.marginTop20)}>
                <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>Connection Configuration</Typography>
                {connectionConfigError && <FormHelperText className={classes.errorTxt}>Please Select atleast one mode</FormHelperText>}
                <Grid className={classes.formGroup}>
                    <FormGroup row>
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="pull" checked={Boolean(packageInfo.module_config.pull)} value={packageInfo.module_config.pull} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.pull, "module_config")} />}
                            label="Pull"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="scan" checked={Boolean(packageInfo.module_config.scan)} value={packageInfo.module_config.scan} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.scan, "module_config")} />}
                            label="Scan"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="query" checked={Boolean(packageInfo.module_config.query)} value={packageInfo.module_config.query} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.query, "module_config")} />}
                            label="Query"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="metadata" checked={Boolean(packageInfo.module_config.metadata)} value={packageInfo.module_config.metadata} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.metadata, "module_config")} />}
                            label="Metadata"
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classnames(classes.subModuleSection, classes.marginTop20)}>
                <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>Inbound Connectors</Typography>
                {inboundConfigError && <FormHelperText className={classes.errorTxt}>Please Select atleast one mode</FormHelperText>}
                <Grid className={classes.formGroup}>
                    <FormGroup row>
                        {
                            inputConnections.map((type, index) =>
                                <FormControlLabel
                                    key={index}
                                    className={classnames(classes.formLabelSection, classes.formLabel)}
                                    control={<Switch size="small" color="secondary" onChange={() => onChangeConnection("inbound_connectors", type.id)} checked={packageInfo.module_config.inbound_connectors ? packageInfo.module_config.inbound_connectors.indexOf(type.id) !== -1 : false} />}
                                    label={type.name === 'File' ? 'Disk' : type.name}
                                />
                            )
                        }
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classnames(classes.subModuleSection, classes.marginTop20)}>
                <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>Outbound Connectors</Typography>
                {outboundConfigError && <FormHelperText className={classes.errorTxt}>Please Select atleast one mode</FormHelperText>}
                <Grid className={classes.formGroup}>
                    <FormGroup row>
                        {
                            outputConnections.map((type, index) =>
                                <FormControlLabel
                                    key={index}
                                    className={classnames(classes.formLabelSection, classes.formLabel)}
                                    control={<Switch size="small" color="secondary" onChange={() => onChangeConnection("outbound_connectors", type.id)} checked={packageInfo.module_config.outbound_connectors ? packageInfo.module_config.outbound_connectors.indexOf(type.id) !== -1 : false} />}
                                    label={type.name}
                                />
                            )
                        }
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classnames(classes.subModuleSection, classes.marginTop20)}>
                <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>Other Configuration</Typography>
                <Grid className={classes.formGroup}>
                    <FormGroup row>
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch disabled={Boolean(packageInfo.module_config.curate)} size="small" color="secondary" name="profile" checked={Boolean(packageInfo.module_config.profile)} value={packageInfo.module_config.profile} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.profile, "module_config")} />}
                            label="Profile"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="curate" checked={Boolean(packageInfo.module_config.curate)} value={packageInfo.module_config.curate} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.curate, "module_config")} />}
                            label="Curate"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="drift" checked={Boolean(packageInfo.module_config.drift)} value={packageInfo.module_config.drift} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.drift, "module_config")} />}
                            label="Drift"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="semantics" checked={Boolean(packageInfo.module_config.semantics)} value={packageInfo.module_config.semantics} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.semantics, "module_config")} />}
                            label="Semantics"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="custom_dashboard" checked={Boolean(packageInfo.module_config.custom_dashboard)} value={packageInfo.module_config.custom_dashboard} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.custom_dashboard, "module_config")} />}
                            label="Custom Dashboard"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="lineage" checked={Boolean(packageInfo.module_config.lineage)} value={packageInfo.module_config.lineage} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.lineage, "module_config")} />}
                            label="Lineage"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="theme" checked={Boolean(packageInfo.module_config.theme)} value={packageInfo.module_config.theme} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.theme, "module_config")} />}
                            label="Theme Support"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="collaboration" checked={Boolean(packageInfo.module_config.collaboration)} value={packageInfo.module_config.collaboration} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.collaboration, "module_config")} />}
                            label="Collaboration"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="conversation" checked={Boolean(packageInfo.module_config.conversation)} value={packageInfo.module_config.conversation} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.conversation, "module_config")} />}
                            label="Conversation"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="action_items" checked={Boolean(packageInfo.module_config.action_items)} value={packageInfo.module_config.action_items} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.action_items, "module_config")} />}
                            label="Action Items"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="domain" checked={Boolean(packageInfo.module_config.domain)} value={packageInfo.module_config.domain} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.domain, "module_config")} />}
                            label="Domain"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="search" checked={Boolean(packageInfo.module_config.search)} value={packageInfo.module_config.search} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.search, "module_config")} />}
                            label="Search"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="api" checked={Boolean(packageInfo.module_config.api)} value={packageInfo.module_config.api} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.api, "module_config")} />}
                            label="Api"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="monitoring" checked={Boolean(packageInfo.module_config.monitoring)} value={packageInfo.module_config.monitoring} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.monitoring, "module_config")} />}
                            label="Monitoring"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="custom_fields" checked={Boolean(packageInfo.module_config.custom_fields)} value={packageInfo.module_config.custom_fields} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.custom_fields, "module_config")} />}
                            label="Custom Fields"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="import_export_metadata" checked={Boolean(packageInfo.module_config.import_export_metadata)} value={packageInfo.module_config.import_export_metadata} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.import_export_metadata, "module_config")} />}
                            label="Import/Export Metadata"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="enrichment" checked={Boolean(packageInfo.module_config.enrichment)} value={packageInfo.module_config.enrichment} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.enrichment, "module_config")} />}
                            label="Enrichment"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="semanticssearch" checked={Boolean(packageInfo.module_config.semanticssearch)} value={packageInfo.module_config.semanticssearch} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.semanticssearch, "semanticssearch")} />}
                            label="Semantic Search"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="export_metrics" checked={Boolean(packageInfo.module_config.export_metrics)} value={packageInfo.module_config.export_metrics} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.export_metrics, "export_metrics")} />}
                            label="Export Metrics"
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classnames(classes.subModuleSection, classes.marginTop20)}>
                <Typography component="h6" variant="h6" className={classes.fieldTitleTxt}>Notification</Typography>
                <Grid className={classes.formGroup}>
                    <FormGroup row>
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="slack" checked={Boolean(packageInfo.module_config.slack)} value={packageInfo.module_config.slack} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.slack, "module_config")} />}
                            label="Slack"
                        />
                        <FormControlLabel
                            className={classnames(classes.formLabelSection, classes.formLabel)}
                            control={<Switch size="small" color="secondary" name="teams" checked={Boolean(packageInfo.module_config.teams)} value={packageInfo.module_config.teams} onChange={(event) => onChangeProperty(event.target.name, !packageInfo.module_config.teams, "module_config")} />}
                            label="Teams"
                        />
                    </FormGroup>
                </Grid>
            </Grid>

        </Grid>
    )

};

LicensePackage.propTypes = {
    classes: PropTypes.object,
    packageId: PropTypes.string,
    history: PropTypes.object,
    theme: PropTypes.object,
    inputConnectionError: PropTypes.object
};

export default withStyles((theme) => ({
    ...createPackageStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(LicensePackage);