import { combineReducers } from 'redux';

// Import Reducers
import { accountReducer } from './accountReducer';
import { alertReducer } from './alertReducer';
import { licenseReducer } from './licenseReducer';
import { packageReducer } from './packageReducer';
import { settingReducer } from './settingReducer';

export default combineReducers({
    account: accountReducer,
    alert: alertReducer,
    license: licenseReducer,
    packageInfo: packageReducer,
    setting: settingReducer
});