import React from 'react';
import { Popover } from '@material-ui/core';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';

const ColorPicker = (props) => {
    const { open, anchorElement, onClose, color, changeColor } = props;
    return (
        <Popover
            open={open}
            anchorEl={anchorElement}
            onClose={() => onClose()}
            anchorOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }
            transformOrigin={
                {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            }>
            <SketchPicker color={color} onChangeComplete={changeColor} />
        </Popover>
    );
};

ColorPicker.propTypes = {
    open: PropTypes.bool,
    anchorElement: PropTypes.element,
    onClose: PropTypes.func,
    color: PropTypes.string,
    changeColor: PropTypes.func
};

export default ColorPicker;