import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Styles from '../../layouts/Styles.jsx';
import { withStyles, Popover, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core';
import ClientListStyles from './ClientListStyles.jsx';
import { getClientLicense } from '../../actions/settingActions.js';
import Loader from '../Loaders/Loader.jsx';


const ClientSetting = (props) => {
    const { open, anchorEl, handleClose, classes, clientId } = props;
    const [totalCount, setTotalCount] = useState(0);
    const [packages, setPackages] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const dispatch = useDispatch();

    const getLicense = useCallback(() => {
        dispatch(getClientLicense(clientId)).then((response) => {
            if (response) {
                const totalLicenseCount = response.reduce(function (a, b) {
                    return a + b.count;
                }, 0);
                setTotalCount(totalLicenseCount);
                setPackages([...response]);
            }
            setLoading(false);
        });
    }, [clientId, dispatch]);

    useEffect(() => {
        getLicense();
    }, [getLicense]);

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }
            transformOrigin={
                {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            }>
            <Box className={classes.clientSetting}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>License Packages</TableCell>
                                <TableCell align="right">Number of licenses</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                packages.map((packageInfo, index) =>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" padding="20">
                                            {packageInfo.package_name}
                                        </TableCell>
                                        <TableCell align="right">
                                            {packageInfo.count}
                                        </TableCell>
                                    </TableRow>

                                )
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                <Box className={classes.totalLicence}>Total Licences : {totalCount}</Box>
                {isLoading && <Loader />}
            </Box>
        </Popover>
    )
}

export default withStyles((theme) => ({
    ...ClientListStyles(theme),
    ...Styles(theme)
}))(ClientSetting);