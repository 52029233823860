import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import { packageActions } from '../constants/actionTypes/packageActionTypes';
import * as service from '../services/apiService';

export const createPackageDetail = (requestParams) => {
    return (dispatch) => {
        dispatch(request(packageActions.CREATE_PACKAGE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.package.createPackage, requestParams).then((response) => {
            dispatch(success(packageActions.CREATE_PACKAGE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(packageActions.CREATE_PACKAGE_FAILURE, error));
        });
    };
};

export const updatePackage = (packageId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.package.updatePackage}/${packageId}`
    return (dispatch) => {
        dispatch(request(packageActions.UPDATE_PACKAGE_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(packageActions.UPDATE_PACKAGE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(packageActions.UPDATE_PACKAGE_FAILURE, error));
        });
    };
};

export const getAllPackage = () => {
    const endpoint = `${appConstants.END_POINTS.package.getAllPackage}`;
    return (dispatch) => {
        dispatch(request(packageActions.GET_ALL_PACKAGE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(packageActions.GET_ALL_PACKAGE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(packageActions.GET_ALL_PACKAGE_FAILURE, error));
        });
    };
};

export const getPackageById = (packageId) => {
    const endpoint = `${appConstants.END_POINTS.package.getPackageById}/${packageId}`;
    return (dispatch) => {
        dispatch(request(packageActions.GET_PACKAGE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(packageActions.GET_PACKAGE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(packageActions.GET_PACKAGE_FAILURE, error));
        });
    };
};

export const deletePackage = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.package.deletePackage}`
    return (dispatch) => {
        dispatch(request(packageActions.DELETE_PACKAGE_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(packageActions.DELETE_PACKAGE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(packageActions.DELETE_PACKAGE_FAILURE, error));
        });
    };
};

export const getPackageLicense = (packageId) => {
    const endpoint = `${appConstants.END_POINTS.package.getPackageLicense}/${packageId}`;
    return (dispatch) => {
        dispatch(request(packageActions.GET_PACKAGE_LICENSE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(packageActions.GET_PACKAGE_LICENSE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(packageActions.GET_PACKAGE_LICENSE_FAILURE, error));
        });
    };
};


export const getActivePackage = () => {
    const endpoint = `${appConstants.END_POINTS.package.getActivePackage}`;
    return (dispatch) => {
        dispatch(request(packageActions.GET_ACTIVE_PACKAGE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(packageActions.GET_ACTIVE_PACKAGE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(packageActions.GET_ACTIVE_PACKAGE_FAILURE, error));
        });
    };
};


export const updatePackageList = (packages) => {
    return {
        type: packageActions.GET_ALL_PACKAGE_SUCCESS,
        responseData: packages
    }
};