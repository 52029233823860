export const packageActions = {

    CREATE_PACKAGE_REQUEST: 'CREATE_PACKAGE_REQUEST',
    CREATE_PACKAGE_SUCCESS: 'CREATE_PACKAGE_SUCCESS',
    CREATE_PACKAGE_FAILURE: 'CREATE_PACKAGE_FAILURE',

    UPDATE_PACKAGE_REQUEST: 'UPDATE_PACKAGE_REQUEST',
    UPDATE_PACKAGE_SUCCESS: 'UPDATE_PACKAGE_SUCCESS',
    UPDATE_PACKAGE_FAILURE: 'UPDATE_PACKAGE_FAILURE',

    GET_PACKAGE_REQUEST: 'GET_PACKAGE_REQUEST',
    GET_PACKAGE_SUCCESS: 'GET_PACKAGE_SUCCESS',
    GET_PACKAGE_FAILURE: 'GET_PACKAGE_FAILURE',

    GET_ALL_PACKAGE_REQUEST: 'GET_ALL_PACKAGE_REQUEST',
    GET_ALL_PACKAGE_SUCCESS: 'GET_ALL_PACKAGE_SUCCESS',
    GET_ALL_PACKAGE_FAILURE: 'GET_ALL_PACKAGE_FAILURE',


    DELETE_PACKAGE_REQUEST: 'DELETE_PACKAGE_REQUEST',
    DELETE_PACKAGE_SUCCESS: 'DELETE_PACKAGE_SUCCESS',
    DELETE_PACKAGE_FAILURE: 'DELETE_PACKAGE_FAILURE',

    GET_PACKAGE_LICENSE_REQUEST: 'GET_PACKAGE_LICENSE_REQUEST',
    GET_PACKAGE_LICENSE_SUCCESS: 'GET_PACKAGE_LICENSE_SUCCESS',
    GET_PACKAGE_LICENSE_FAILURE: 'GET_PACKAGE_LICENSE_FAILURE',

    GET_ACTIVE_PACKAGE_REQUEST: 'GET_ACTIVE_PACKAGE_REQUEST',
    GET_ACTIVE_PACKAGE_SUCCESS: 'GET_ACTIVE_PACKAGE_SUCCESS',
    GET_ACTIVE_PACKAGE_FAILURE: 'GET_ACTIVE_PACKAGE_FAILURE'
};