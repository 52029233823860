const palette = {
	primary: {
		dark: '#3abeff',
		main: '#F38080',
		light: '#e60000',
		contrastText: "#fff"
	},
	secondary: {
		dark: '#3abeff',
		main: '#3abeff',
		light: '#1d2c2f',
		contrastText: "#fff"
	},
	tertiary: '#F38080',
	grey: {
		default: '#646666',
		exteremeLight: '#f2f6f7',
		extraLight: '#e1e5e6',
		light: '#c8cbcc',
		main: '#afb2b3',
		dark: '#969899',
		darkGrey: '#6F777C',
		darkTxt: '#1D2C2F'
	},
	background: {
		default: '#f4f6f9',
		container: '#f6f8f9',
		paper: '#fff',
		light: '#e8f7ff'
	},
	icon: '#3abeff',
	border: {
		default: '#f2f6f7',
		light: '#c9cbce',
		dark: '#ddd',
		focus: "#1976d2",
		normal: "#E1E5E6"
	},
	chartColors: {
		score25: '#FF5C5C',
		score50: '#FDAC42',
		score100: '#54DF9A',
		scoreLow: '#ff829d',
		scoreMedium: '#ffd778',
		scoreHight: '#6fcdcd',
		impactValid: '#75C978',
		valid: '#B4D9E8',
		inValid: '#F9A099',
		line: '#a1e0ff',
		soundex: '#ffd778',
		presense: '#73e5ed',
		absense: '#f1f3f3',
		scatterBar: '#ffd778',
		scatterValid: '#6fcdcd',
		scatterInvalid: '#f59999',
		trending: '#FE7191',
		axis: '#CFE0E7'
	},
	lengthChart: {
		valid: '#daecf4',
		inValid: '#fcd0cc',
		line: '#a1e0ff'
	},
	transparent: 'transparent',
	countColors: {
		low: '#A8E6DF',
		high: '#EA8CC0'
	},
	datatypeColors: {
		text: '#5EB5EF',
		integer: '#6FCDCD',
		date: '#FFD778'
	},
	MetricsTiles: {
		tileOne: '#FF829D',
		tileTwo: '#6FCDCD',
		tileThree: '#FFD778',
		tileFour: '#5EB5EF'
	},
	titleFont: 'Poppins',
	FontBold: 'Poppins',
	FontMedium: 'Poppins',
	FontRegular: 'Poppins',
	FontLight: 'Poppins',
	textFont: 'Lato',
	logo: '',
	banner: '',
	loginCopyrightText: '©2020 DQLabs, Inc. All rights reserved.',
	footerCopyrightText: '©2020 DQLabs, Inc. All rights reserved.',
	groupChart: ["#C9CBCF", "#93E5BC", "#5CE1FF"],
	heatmapColors: ['#ededed', "#FF8C8C", "#86E8B7", "#FDAC42", "#FF5C5C"],
	barColors: { high: '#54DF9A', medium: '#FDAC42', low: '#FF5C5C' },
	domainAsset: ["#ffaf97", "#b2dbf7", "#f295b6"]
};

export default palette;